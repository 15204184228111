import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

const enhancer = compose(
  withAcls,
  withHooks(props => {
    const {
      USAGE_LIMITS_PAGE_LINK,
      GOVERNANCE_ENABLE_POLICY_MANAGEMENT_SYSTEM
    } = featureFlagConstants;
    const { application, realms, license } = useSelector(state => state);
    const { treatments } = useFeatureFlag([
      USAGE_LIMITS_PAGE_LINK,
      GOVERNANCE_ENABLE_POLICY_MANAGEMENT_SYSTEM
    ]);

    const dispatch = useDispatch();
    const state = {
      realm: application.realm.realm,
      togaiToken: application.togaiToken,
      realmData: realms.getRealm,
      license: license.license,
      showLimitScreen: treatments[USAGE_LIMITS_PAGE_LINK].treatment === 'on',
      showEnablePolicyManagementSystem:
        treatments[GOVERNANCE_ENABLE_POLICY_MANAGEMENT_SYSTEM].treatment === 'on',
      hasCustomerIdentityProvider: application.hasCustomerIdentityProvider
    };
    const actions = {
      onPressBack: isTargetBlank => {
        const path = `/${state.realm}/operation/build/pipelines`;

        if (isTargetBlank) return window.open(path, '_blank');

        return dispatch.router.navigate({ to: path });
      }
    };

    return {
      ...props,
      ...state,
      ...actions
    };
  })
);

export default enhancer;
