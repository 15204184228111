import Immutable from 'seamless-immutable';

// eslint-disable-next-line import/no-cycle
import { realms, environments, retention, application } from '~/api';
import {
  ACTIVATE_POLLING_PERMISSONS,
  POLLING_PERMISSIONS_INTERVAL
} from '~/common/helpers/environment';
import getEnvironmentsToPathName from '~/common/helpers/getEnvironmentsToPathName';
import getRealmToPathName from '~/common/helpers/getRealmToPathName';
import getSubdomain from '~/common/helpers/getSubdomain';
import normalize from '~/common/helpers/normalize';

const initialState = Immutable({
  loading: false,
  realm: getRealmToPathName(window.location.pathname),
  activeRealm: getRealmToPathName(window.location.pathname).realm,
  environment: getEnvironmentsToPathName(window.location.pathname),
  togaiToken: null,
  realms: {
    allNames: [],
    byName: []
  },
  explanation: null,
  permissions: [],
  loadingPermissions: false,
  retention: {
    error: null,
    result: {
      scope: null,
      type: {
        hot: {
          stats: null,
          messages: null,
          logs: null
        },
        cold: {
          stats: null,
          messages: null,
          logs: null
        }
      }
    },
    loading: false
  },
  environments: {
    allNames: [],
    byName: {}
  },
  realmsError: null,
  loadingRealm: false,
  loadingEnvironment: false,
  environmentLoading: false,
  isOpenSuportAccess: false,
  disabledSupportAccess: {
    status: false,
  },
  isOpenFallBackChat: false,
  environmentError: null,
  helpTooltip: {
    opened: false,
    position: 'bottom',
    align: 'right',
    content: ''
  },
  subdomain: getSubdomain()
});

const applicationModel = {
  name: 'application',
  state: initialState,
  reducers: {
    setRealm(state, realm) {
      return state.merge({ realm });
    },
    setSubdomain(state, subdomain) {
      return state.merge({ subdomain });
    },
    setExplanation(state, explanation) {
      return state.merge({ explanation });
    },
    setTogaiToken(state, togaiToken) {
      return state.merge({ togaiToken });
    },
    setPermissions(state, permissions) {
      return state.merge({ permissions });
    },
    setPlatformParameter(state, { parameter, value, updatedAt }) {
      if(typeof value === 'object' && value !== null) return state.merge({ [parameter]: {...value, updatedAt} });
      return state.merge({ [parameter]: value });
    },
    handleIsOpenSuportAccess(state) {
      return state.merge({ isOpenSuportAccess: !state.isOpenSuportAccess });
    },
    handleIsOpenFallBackChat(state) {
      return state.merge({ isOpenFallBackChat: !state.isOpenFallBackChat });
    },
    changeRealm(state, payload) {
      return state.merge({ activeRealm: payload.realm });
    },
    setLoading(state, payload) {
      return state.merge({
        loading: payload
      });
    },
    setLoadingPermissions(state, payload) {
      return state.merge({
        loadingPermissions: payload
      });
    },
    setEnvironments(state, environment) {
      return state.merge({ environment });
    },
    setApplication(state, payload) {
      return state.merge({
        ...payload
      });
    },
    setRetention(state, { path, value }) {
      return state.merge({
        retention: {
          [path]: value
        }
      });
    },
    environments(state, payload) {
      return state.merge({ environments: payload });
    },
    environmentLoading(state, environmentLoading) {
      return state.merge({
        environmentLoading
      });
    },
    environmentError(state, environmentError) {
      return state.merge({
        environmentError
      });
    },
    realmsError(state, realmsError) {
      return state.merge({
        realmsError
      });
    },
    initialization(state) {
      return state;
    },
    setHelpTooltip(state, helpTooltip) {
      return state.merge({ helpTooltip });
    }
  },
  effects: dispatch => ({
    async findRealms() {
      try {
        const requestListRealms = await realms.find();
        dispatch.application.setApplication({
          realms: normalize({
            array: requestListRealms.data.realms,
            alias: 'name'
          })
        });
        return requestListRealms;
      } catch (e) {
        return e;
      }
    },
    async findEnvironments(realm) {
      try {
        const { data } = await environments.find(realm);
        dispatch.application.environments(
          normalize({ array: data.environments, alias: 'name' })
        );
        return data.environments;
      } catch (e) {
        dispatch.application.environmentError(e);
        return e;
      }
    },
    async getPlatformParameter(name, state) {
      try {
        const { data } = await application.getPlatformParameter({
          realm: state?.application?.realm?.realm,
          name
        });

        if (data?.platformParameters?.parameter) {
          dispatch.application.setPlatformParameter(data?.platformParameters);
        } else {
          dispatch.application.setPlatformParameter({
            parameter: name,
            value: null
          });
        }
      } catch (e) {
        dispatch.application.setPlatformParameter({
          parameter: name,
          value: null
        });
      }
    },
    async getTogaiToken(email) {
      try {
        const { data } = await application.getTogaiToken(email);
        dispatch.application.setTogaiToken(data?.getTogaiToken?.token);
      } catch (e) {
        dispatch.application.setTogaiToken(null);
      }
    },
    async findRetention(payload, state) {
      try {
        const { realm } = state.application.realm;
        const { data } = await retention.find(realm);
        dispatch.application.setRetention({
          path: 'result',
          value: data.retention ? data.retention : initialState.retention.result
        });
      } catch (e) {
        dispatch.application.setRetention({ path: 'error', value: e.message });
      }
    },
    async getPermissions(payload, state) {
      try {
        const { realm } = state.application.realm;
        const { data } = await application.getPermissions({
          realm,
          environmentCapable: payload?.environmentCapable
        });
        dispatch.application.setPermissions(data.permissions);
      } catch (e) {
        dispatch.application.setPermissions([]);
      }
    }
  }),
  logics: [
    {
      type: 'application/findRealms',
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.setLoading(true);
        done();
      }
    },
    {
      type: 'application/getPermissions',
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.setLoadingPermissions(true);
        done();
      }
    },
    {
      type: 'application/setPermissions',
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.setLoadingPermissions(false);
        done();
      }
    },
    {
      type: ['application/setApplication'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.setLoading(false);
        done();
      }
    },
    {
      type: 'application/findEnvironments',
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.environmentLoading(true);
        done();
      }
    },
    {
      type: ['application/environments'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.application.environmentLoading(false);
        done();
      }
    },
    {
      type: ['application/initialization'],
      latest: true,
      process(context, dispatch, done) {
        const { realm } = context.getState().application;
        const { userData } = context.getState().authentication;

        dispatch.acceptanceTerm.getLatest();
        dispatch.acls.fetch();
        dispatch.application.getPlatformParameter(
          'hasCustomerIdentityProvider'
        );
        dispatch.application.getPlatformParameter(
          'forceIntegratedGroupsToUserIdp'
        );
        dispatch.application.getPlatformParameter(
          'hideDisplaySensitiveAccountFields'
        );
        dispatch.application.getPlatformParameter(
          'disabledSupportAccess'
        );
        dispatch.application.getPlatformParameter('hiddenPages');
        // eslint-disable-next-line eqeqeq
        if (ACTIVATE_POLLING_PERMISSONS == 'true') {
          setInterval(
            () => dispatch.acls.fetch(),
            POLLING_PERMISSIONS_INTERVAL
          );
        }

        dispatch.realms.getRealm();
        dispatch.license.getLicense();
        dispatch.profile.getUser();
        dispatch.realms.find();
        dispatch.application.findEnvironments(realm);
        dispatch.scope.fetch();
        dispatch.design.fetchReplicas();
        dispatch.intercom.create({ email: userData.email });
        done();
      }
    }
  ]
};

export default applicationModel;
