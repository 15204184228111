import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import withHooks from '~/common/hoc/withHooks';
import { StoreDispatcher } from '~/scenes/Governance/types/StoreDispatcher';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

export type Props = {
  buttonLoading: boolean,
  idpLoginUrl: string
}

const enhancer = withHooks(() => {
  const [buttonReady, setButtonReady] = useState<boolean>(false);
  const { redirectUrl, realm } = useSelector(({ authentication }: StoreRootState) => ({
    redirectUrl: authentication.realmParams?.redirectUrl,
    realm: authentication.error?.realm
  }));
  const dispatch = useDispatch<StoreDispatcher>();
  const navigate = useNavigate();

  useEffect(() => {
    if (realm) {
      if (!redirectUrl) dispatch.authentication.getNotLoggedPlatformParameter({ realm, param: 'hasCustomerIdentityProvider' });
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      setButtonReady(true);
    } else {
      setButtonReady(false);
    }
  }, [redirectUrl]);

  return {
    buttonLoading: !buttonReady,
    idpLoginUrl: redirectUrl
  } as Props;
});

export default enhancer;
