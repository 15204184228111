import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './Details.elements';

import Text from '~/components/Text';

type DetailsProps = {
  name: string;
  title: string;
  disabled?: boolean;
  result: string;
};

const Details: React.FC<DetailsProps> = ({
  name,
  result,
  disabled = false,
  title,
  ...props
}) => (
  <Elements.DetailsContainer title={title} {...props}>
    <Text as='span' size='sm' weight='regular'>
      {name}
    </Text>
    <Elements.DetailsDescription
      weight='bold'
      type='primary'
      size='lg'
      as='span'
      disabled={disabled}
    >
      {result}
    </Elements.DetailsDescription>
  </Elements.DetailsContainer>
);

export default Details;
