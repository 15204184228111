/* eslint-disable no-useless-catch */
import axios from 'axios';

import { API_KEY, URL } from '~/common/helpers/environment';

export type SignIn = {
  changePasswordId?: string;
};

export type NotLoggedPlatformParameter = {
  [key: string]: boolean | string;
};

const authentication = {
  async signIn(
    realm: string,
    email: string,
    password: string,
    recaptcha: string,
    version: string,
    code?: string
  ) {
    try {
      const response = await axios.post<SignIn>(
        `${URL}/${realm}/login/v3`,
        { email, password, code, 'g-recaptcha-response': recaptcha, version },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async signInV4(
    realm: string,
    email: string,
    password: string,
    recaptcha: string,
    version: string,
    code?: string,
    securityCode?: string
  ) {
    try {
      const response = await axios.post<SignIn>(
        `${URL}/${realm}/login/v4`,
        { email, password, code, 'g-recaptcha-response': recaptcha, version, securityCode },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async signInFirstAccess(
    realm: string,
    email: string,
    password: string,
    newPassword: string,
    newPasswordConfirm: string,
    token?: string
  ) {
    try {
      const response = await axios.post<null>(
        `${URL}/${realm}/first-access/v1`,
        {
          email,
          password,
          newPassword,
          newPasswordConfirm,
          changePasswordId: token
        },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async signOut(realm: string, token: string) {
    try {
      const response = await axios.post<null>(
        `${URL}/${realm}/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getNotLoggedPlatformParameter(realm: string, param: string) {
    try {
      const response = await axios.get<NotLoggedPlatformParameter>(
        `${URL}/auth/${realm}/params/${param}`,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async validateVerificationCode(realm: string, email: string, code: string) {
    try {
      const response = await axios.patch<null>(
        `${URL}/${realm}/unlock-account/code-validation`,
        {
          email,
          code
        },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async sendVerificationCode(realm: string, email: string) {
    try {
      const response = await axios.post<null>(
        `${URL}/${realm}/unlock-account`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: API_KEY
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default authentication;
