import i18n from '~/common/helpers/i18n';
import { useSelector } from 'react-redux';

import Operation from '../Others/scenes/App/Operation';

import asyncComponent from '~/components/AsyncComponent';

const Overview = asyncComponent(
  () => import('~/scenes/Monitor/scenes/Overview')
);

const ExecutionFinished = asyncComponent(
  () => import('~/scenes/Monitor/scenes/ExecutionFinished')
);

const ExecutionInflight = asyncComponent(
  () => import('~/scenes/Runtime/scenes/ExecutionInflight')
);

const PipelineLogs = asyncComponent(
  () => import('~/scenes/Monitor/scenes/PipelineLogs')
);

const AlertsConfigMonitor = asyncComponent(
  () => import('~/scenes/Monitor/scenes/AlertsConfigMonitor')
);

const Metrics = asyncComponent(() => import('~/scenes/Monitor/scenes/Metrics'));

export const MonitorRoutes = () => {
  const { application } = useSelector<any, any>(state => state);
  const { environment } = application;

  return [
    {
      path: '/:realm/operation/monitor',
      element: (
        <Operation
          showEnvironmentSelect
          links={[
            {
              'data-testid': 'operation-header-monitor-link-overview',
              routeName: 'monitor-overview',
              isNotBorder: true,
              path: `operation/monitor/${environment}/overview`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('noun.overview')}`
                }
              )
            },
            {
              roles: ['MESSAGE:READ'],
              'data-testid': 'operation-header-monitor-link-execution_finish',
              path: `operation/monitor/${environment}/execution-finish`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('noun.execution_finish_tab_title')}`
                }
              )
            },
            {
              roles: ['LOG:READ'],
              'data-testid': 'operation-header-monitor-link-pipeline_logs',
              routeName: 'monitor-pipeline-logs',
              path: `operation/monitor/${environment}/pipeline-logs`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('noun.logs_tab_title')}`
                }
              )
            },
            {
              roles: ['METRIC:READ'],
              'data-testid': 'operation-header-monitor-link-pipeline_metrics',
              routeName: 'monitor-pipeline-metrics',
              path: `operation/monitor/${environment}/pipeline-metrics`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('noun.pipeline_metrics')}`
                }
              )
            },
            {
              roles: ['ALERT:READ'],
              'data-testid': 'operation-header-monitor-link-alerts-config',
              routeName: 'monitor-alerts-config',
              path: `operation/monitor/alerts-config/${environment}`,
              name: i18n.t(
                'scenes.beta.labels.upper_and_capitalize.capitalized',
                {
                  item: `${i18n.t('noun.alerts_configuration')}`
                }
              )
            }
          ]}
        />
      ),
      children: [
        {
          path: 'alerts-config/:environment',
          name: 'monitor-alerts-config',
          title: i18n.t('label.alerts_configuration_tab'),
          element: <AlertsConfigMonitor />
        },
        {
          path: ':environment/overview',
          name: 'monitor-overview',
          title: i18n.t('label.monitor_overview_tab'),
          element: <Overview />
        },
        {
          path: ':environment/execution-finish',
          name: 'monitor-execution-finish',
          title: i18n.t('label.completed_exec_tab'),
          element: <ExecutionFinished />
        },
        {
          path: ':environment/execution-inflight',
          name: 'monitor-execution-inflight',
          title: i18n.t('label.running_exec_tab'),
          element: <ExecutionInflight />
        },
        {
          path: ':environment/pipeline-logs',
          name: 'monitor-pipeline-logs',
          title: i18n.t('label.pipe_logs_tab'),
          element: <PipelineLogs />
        },
        {
          path: ':environment/pipeline-metrics',
          name: 'monitor-pipeline-metrics',
          title: i18n.t('label.pipe_metrics_tab'),
          element: <Metrics />
        }
      ]
    }
  ];
};
