import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import withHooks from '~/common/hoc/withHooks';
import { Pipeline } from '~/entities/Pipeline';
import api from '~/api/pipelines';
import apiRuntime from '~/api/runtime';

import uniqBy from 'lodash/uniqBy';
import { DeploymentPlanItem } from '../types';
import apiConfiguration from '~/api/configuration';
import useSnackbar from '~/common/hooks/useSnackbar';
import { debounce, filter, maxBy } from 'lodash';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import apiPipeline from '~/api/pipeline';
import i18n from '~/common/helpers/i18n';
import apiPipelines from '~/api/pipelines';
import { ValueSelect, ActionMeta } from './types';
import { useNavigate } from 'react-router';
import { DeploymentPlanContext } from '../context/DeploymentPlanContext';

type ValidationPropTypes = {
  code: string;
  message: string;
  details: [
    {
      level: string;
      details: string[];
      code: string;
      message: string;
      objects: string[];
    }
  ];
};

const normalizePipelines = (pipelines: Pipeline[]) => {
  const data = uniqBy(pipelines, 'name').map(pipeline => ({
    //@ts-ignore
    id: pipeline._id,
    versionMajor: pipeline.versionMajor,
    label: `${pipeline.name} - V${pipeline.versionMajor}`,
    value: pipeline.name,
    canvaVersion: pipeline.canvasVersion
  }));

  return data;
};

const enhancer = withHooks(() => {
  const navigate = useNavigate();
  const { validatePipeline, getPlan } = useContext(DeploymentPlanContext);
  const { application } = useSelector((state: any) => state);
  const [pipelineValue, setPipeline] = useState<ValueSelect[]>([]);
  const [pipelineInvalid, setPipelineInvalid] = useState<ValueSelect[]>([]);
  const [deploymentPlanItems, setDeploymentPlanItems] = useState<
    DeploymentPlanItem[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const snackbar = useSnackbar();

  async function loadPipelines(
    search: any,
    { searchPage, page, previousSearch }: any
  ) {
    return new Promise((resolve, reject) =>
      debounce(async () => {
        try {
          const request = await api.fetch({
            realm: application?.activeRealm,
            //@ts-ignore
            search: {
              name: search,
              page: search
                ? previousSearch === search
                  ? ++searchPage
                  : 0
                : ++page
            }
          });

          const options = normalizePipelines(
            //@ts-ignore
            request?.data?.searchPipelines?.content
          );

          const hasMore = !request?.data?.searchPipelines?.last;

          resolve({
            options,
            hasMore,
            additional: {
              page: !search ? request?.data?.searchPipelines?.number : page,
              searchPage: search
                ? previousSearch == search
                  ? request?.data?.searchPipelines?.number
                  : 0
                : -1,
              previousSearch: search
            }
          });
        } catch (error) {
          reject();
        }
      }, 1000)()
    );
  }

  async function getConfigurations(pipelineVersionId: string, env: string) {
    const { data } = await apiConfiguration.find({
      pipelineVersionId,
      realm: application.activeRealm,
      environment: env
    });
    return data;
  }

  async function handleSelectPipe(
    values: ValueSelect[],
    actionMeta: ActionMeta,
    formik: any
  ) {
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      removeItem(actionMeta.removedValue.value);
      formik.setFieldValue(
        'deploymentPlanItems',
        deploymentPlanItems.filter(
          newE =>
            newE.pipelineMajorVersion.name !== actionMeta.removedValue.value
        )
      );
      return;
    }
    setPipeline(values);
    const value = actionMeta.option;
    if (value) {
      const envs = application?.environments?.allNames
        ?.asMutable({
          deep: true
        })
        .sort((a: string, b: string) => {
          if (a === 'test') {
            return -1;
          } else if (b === 'test') {
            return 1;
          }
          return a.localeCompare(b);
        });

      if (envs) {
        setLoading(true);

        const { project } = await apiPipelines.pipelineProjectName({
          id: value.id,
          realm: application?.activeRealm,
          environment: ''
        });
        const obj = {
          pipelineMajorVersion: {
            name: value?.value,
            majorVersion: value?.versionMajor
          },
          projectName: project?.name,
          canvaVersion: value.canvaVersion,
          pipelineId: value.id
        };
        let error = '';
        const environmentConfiguration: any = [];
        for (const env of envs) {
          const { configurations } = await getConfigurations(value?.id, env);

          const { data } = await apiRuntime.getVersion({
            realm: application?.activeRealm,
            environment: env,
            versionMajor: obj?.pipelineMajorVersion.majorVersion.toString(),
            name: value.value
          });

          const pipelineLastVersion = maxBy(
            filter(data?.pipelineVersions, item => !item.disabled),
            item => item.versionMinor
          );

          const pipelineValidation = await validatePipeline({
            pipelineId: pipelineLastVersion?.id,
            environment: env,
            realm: application?.activeRealm
          });

          const isError = pipelineValidation?.filter?.(
            (item: ValidationPropTypes) => {
              const errors = item?.details.filter(
                detail => detail?.level == 'ERROR'
              );
              return errors.length;
            }
          );

          if (isError.length === 0) {
            environmentConfiguration.push({
              environment: {
                name: env
              },
              pipeline: pipelineLastVersion,
              runtimeConfiguration: {
                namePipeline: value?.value,
                name: configurations[0]?.name,
                majorVersion: value?.versionMajor,
                consumers: 1,
                actualConsumers: 1,
                replicaCount: 1,
                configurations: configurations,
                size: configurations[0]?.name,
                description: configurations[0]?.description,
                maxSize: configurations[0]?.consumers,
                id: configurations[0]?.id,
                pipelineId: value?.id,
                minorVersionsId: {
                  value: pipelineLastVersion.id,
                  label: `${pipelineLastVersion.versionMajor}.${pipelineLastVersion.versionMinor}`
                },
                minorVersions: data?.pipelineVersions.map((item: any) => ({
                  value: item.id,
                  label: `${item.versionMajor}.${item.versionMinor}`,
                  versionMinor: item.versionMinor
                }))
              }
            });
          } else {
            error = isError.map((item: { details: { message: string }[] }) =>
              item.details?.map(detail => detail.message)
            );
          }
        }

        if (environmentConfiguration.length > 0) {
          const deployItems = [
            ...deploymentPlanItems,
            {
              ...obj,
              environmentConfiguration
            }
          ];

          formik.setFieldValue('deploymentPlanItems', deployItems);

          //@ts-ignore
          setDeploymentPlanItems(deployItems);
        } else {
          setPipelineInvalid([...pipelineInvalid, { ...value, error }]);
        }
      }
      setLoading(false);
    }
  }

  function removeItem(name: string) {
    const nerwArr = deploymentPlanItems.filter(
      item => item.pipelineMajorVersion.name !== name
    );
    const nerwArrPipeValue = pipelineValue.filter(item => item.value !== name);

    if (pipelineInvalid.length > 0) {
      const invalidsPipes = pipelineInvalid.filter(item => item.value !== name);
      setPipelineInvalid(invalidsPipes);
    }
    setPipeline(nerwArrPipeValue);
    setDeploymentPlanItems(nerwArr);
  }

  async function saveData(data: { name: string; description: string }) {
    try {
      setLoadingSave(true);
      await apiRuntime.deploymentPlanInsert({
        realm: application.activeRealm,
        deploymentPlan: data
      });

      snackbar.createSnack(
        i18n.t('label.save_deployment_plan_msg_success', {
          planName: data.name
        })
      );
      setLoadingSave(false);
      navigate(-1);
      await getPlan();
    } catch (error) {
      setLoadingSave(false);
      snackbar.createSnack(
        i18n.t('label.couldnt_save_deployment_plan_msg_alert', {
          planName: data.name
        })
      );
    }
  }

  const props = {
    pipelineValue,
    setPipeline,
    loadPipelines,
    handleSelectPipe,
    deploymentPlanItems,
    loading,
    removeItem,
    saveData,
    loadingSave,
    maxQuantityPipes: 5,
    pipelineInvalid
  };

  return props;
});

export default enhancer;
