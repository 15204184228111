import Immutable from 'seamless-immutable';

import { StoreDispatcher } from '../types/StoreDispatcher';

import { authentication as api } from '~/api';
import { RECAPTCHA_SITE_KEY, version } from '~/common/helpers/environment';
import WebAnalytics from '~/common/helpers/webAnalytics';

type State = {
	loading: boolean,
  error: string | null,
  errorCode: string | null,
  isSoftLocked: boolean,
  merge: (payload: unknown) => State
}

const initialState = Immutable({
  loading: false,
  error: null,
  errorCode: null,
  isSoftLocked: false
}) as State;

const login = {
  name: 'login',
  state: initialState,
  reducers: {
    success: (state: State, payload: { token: string, realm: string, email: string }) =>
      state.merge({ isSignedIn: true, userData: payload }),
    error: (state: State, error: string) => state.merge({ error }),
    errorCode: (state: State, errorCode: string) => state.merge({ errorCode }),
    softLocked: (state: State, value: boolean) => state.merge({ isSoftLocked: value }),
    loading: (state: State, value: boolean) => state.merge({ loading: value }),
    reset: () => initialState
  },

  effects: (dispatch: StoreDispatcher) => ({
    async signin(
      { realm, email, password, code, securityCode }: 
      { realm: string, email: string, password: string, code?: string, securityCode?: string }) {
      try {
        dispatch.login.loading(true);
        const recaptcha = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: 'auth'
        });
        const request = await api.signInV4(
          realm,
          email,
          password,
          recaptcha,
          version,
          code,
          securityCode
        );
        if (request.status === 203) {
          dispatch.authentication.firstaccess({
            token: request.headers.authorization || request.data.changePasswordId,
            realm,
            email
          });
        }
        if (request.status === 200) {
          dispatch.authentication.success({
            token: request.headers.authorization || request.data.changePasswordId,
            realm,
            email
          });
        }
        WebAnalytics.sendEvent('[Gov] (Login) Sign In Success', {
          realm,
          email,
        });
      } catch (e: any) {
        const errorCode = e?.response?.data?.data?.code;
        WebAnalytics.sendEvent('[Gov] (Login) Sign In Error', {
          realm,
          email,
          errorCode: errorCode || e?.message
        });
        dispatch.login.error(e?.message);
        if(errorCode === 'user_soft_locked') {
          dispatch.login.softLocked(true);
        } else {
          dispatch.login.softLocked(false);
          dispatch.login.errorCode(errorCode);
        }
      } finally {
        dispatch.login.loading(false);
      }
    },
  }),
  logics: []
};

export default login;
