import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import withHooks from '~/common/hoc/withHooks';
import apiRuntime from '~/api/runtime';

import { EnvironmentConfiguration } from '../types';
import useSnackbar from '~/common/hooks/useSnackbar';
import i18n from '~/common/helpers/i18n';
import { DeploymentPlanContext } from '../context/DeploymentPlanContext';
import { useNavigate, useParams } from 'react-router';

export interface TypeExecuteDeploymentPlan {
  deploymentPlanItems: EnvironmentConfiguration[];
  saveData: Function;
  loadingSave: boolean;
  changeMinorSize: Function;
}

const enhancer = withHooks(() => {
  const { application } = useSelector((state: any) => state);
  const [pipelineValue, setPipeline] = useState();
  const { planSelected, changeLoadingExecuteDeploy } = useContext(
    DeploymentPlanContext
  );
  const [deploymentPlanItems, setDeploymentPlanItems] = useState<
    EnvironmentConfiguration[]
  >(planSelected?.deploymentPlanItems);
  const [loadingSave, setLoadingSave] = useState(false);
  const snackbar = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const env = params.env;

  useEffect(() => {
    setDeploymentPlanItems(planSelected?.deploymentPlanItems);
  }, [planSelected, env]);

  function changeMinorSize(idPipe: string, newValue: any) {
    const deploymentPlanItem = deploymentPlanItems.map(item => {
      if (item.pipeline.id === idPipe) {
        return {
          ...item,
          pipeline: {
            ...item.pipeline,
            id: newValue.value
          },
          runtimeConfiguration: {
            ...item.runtimeConfiguration,
            minorVersionsId: newValue
          }
        };
      }
      return item;
    });
    setDeploymentPlanItems(deploymentPlanItem);
  }

  async function deployTest() {
    let pipelineDeploys = [];
    for (const item of deploymentPlanItems) {
      const config = item?.runtimeConfiguration;
      const obj = {
        pipelineId: item.pipeline.id,
        runtimeConfigurationId: config.id,
        actualConsumers: config.actualConsumers,
        replicaCount: config.replicaCount
      };
      pipelineDeploys.push(obj);
    }

    if (pipelineDeploys) {
      await apiRuntime.deploymentPlanExecute({
        realm: application?.activeRealm,
        deploymentPlanId: planSelected.id,
        pipelineDeploys: pipelineDeploys
      });

      snackbar.createSnack(
        i18n.t('label.deployment_plan_deployed_msg_success', {
          planName: planSelected.name
        })
      );
    }
  }

  async function promoteProd() {
    const obj = {
      realm: application.activeRealm,
      deploymentPlanId: planSelected.id,
      environment: 'prod',
      sourceEnvironment: 'test'
    };

    await apiRuntime.deploymentPlanPromote(obj);
    snackbar.createSnack(
      i18n.t('label.deployment_plan_deployed_msg_success', {
        planName: planSelected.name
      })
    );
  }
  async function saveData() {
    try {
      setLoadingSave(true);
      if (env === 'prod') {
        await promoteProd();
      } else {
        await deployTest();
      }

      setLoadingSave(false);
      navigate(-1);
    } catch (error) {
      setLoadingSave(false);

      snackbar.createSnack(
        i18n.t('label.couldnt_deploy_deployment_plan_msg_alert', {
          planName: planSelected.name
        })
      );
    }
  }
  const props = {
    pipelineValue,
    setPipeline,
    deploymentPlanItems,
    changeMinorSize,
    saveData,
    loadingSave
  };

  return props;
});

export default enhancer;
