import { Alert } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
// @ts-ignore
import * as yup from 'yup';

import Code from './components/Code';
import Credentials from './components/Credentials';
import HardLocked from './components/HardLocked';
import SecurityCode from './components/SecurityCode';
import * as Elements from './FormLogin.elements';
import { Props, LoginSchemaType } from './FormLogin.enhancer';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';


const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('scenes.user.messages.error.invalid_email')
    .required('scenes.login.messages.error.required_field'),
  realm: yup.string().required('scenes.login.messages.error.required_field'),
  code: yup.string(),
  securityCode: yup.string(),
  password: yup.string().required('scenes.login.messages.error.required_field')
});

const FormLogin = ({ signin, error, loading, subdomain, realmParams, errorCode, isSoftLocked, back }: Props) => {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: '',
      realm: subdomain || '',
      password: '',
      code: '',
      recaptcha: '',
      securityCode: ''
    },
    onSubmit: ({ realm, email, password, code, securityCode }) =>
      signin({
        realm,
        email,
        password,
        code,
        securityCode
      }),
  })

  function onErrors(touchedFields: FormikTouched<LoginSchemaType>, errorsFields: FormikErrors<LoginSchemaType>, field: string, genericsError = true, especificError?: string | string[]) {
    const errorTranslate: { [key: string]: string | null } = {
      'totp_token_invalid': 'label.expired_invalid_code_assist_error',
      'security_code_invalid': 'label.expired_invalid_code_assist_error',
      'security_code_expired': 'label.expired_invalid_code_assist_error',
      'invalid_credentials': 'scenes.login.messages.error.login',
      'default': (genericsError && !isSoftLocked) ? 'scenes.login.messages.error.login' : null
    };
    const keyErrorRequest = errorTranslate[errorCode || 'default'] || 'scenes.login.messages.error.login';

    const fieldError = getFieldError(touchedFields, errorsFields, field);
    const isError = fieldError || (error && genericsError) || (errorCode === especificError || especificError?.includes(errorCode || ''));
    return isError ? fieldError || keyErrorRequest : null;
  }

  return (
    <Elements.Container
      autoComplete='off'
      onSubmit={ev => {
        ev.preventDefault();
      }}
    >
      {iff(isSoftLocked, () => (
        <Alert variant='danger' title={i18n.t('label.soft_locked_text')} data-testid='governance-explanation-softlocked-user' />
      ))}
      {choose(
        when(errorCode === 'user_hard_locked', () => (
          <HardLocked back={back} />
        )),
        when(errorCode === 'totp_token_required' || errorCode === 'totp_token_invalid', () => (
          <Code
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            onErrors={(
              touchedFields: FormikTouched<LoginSchemaType>,
              errorsFields: FormikErrors<LoginSchemaType>,
              field: string,
              genericsError?: boolean,
              especificError?: string | string[]
            ) => onErrors(touchedFields, errorsFields, field, genericsError, especificError)
            }
            loading={loading}
            errors={errors}
            subdomain={subdomain}
            realmParams={realmParams}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        )),
        when(
          errorCode === 'user_recaptcha_is_invalid' ||
          errorCode === 'user_recaptcha_is_low_score' ||
          errorCode === 'security_code_invalid' ||
          errorCode === 'security_code_expired', () => (
            <SecurityCode
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              onErrors={(
                touchedFields: FormikTouched<LoginSchemaType>,
                errorsFields: FormikErrors<LoginSchemaType>,
                field: string,
                genericsError?: boolean,
                especificError?: string | string[]
              ) => onErrors(touchedFields, errorsFields, field, genericsError, especificError)
              }
              loading={loading}
              errors={errors}
              subdomain={subdomain}
              realmParams={realmParams}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          )),
        otherwise(() => (
          <Credentials
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            onErrors={(touchedFields: FormikTouched<LoginSchemaType>, errorsFields: FormikErrors<LoginSchemaType>, field: string) => onErrors(touchedFields, errorsFields, field)}
            loading={loading}
            errors={errors}
            subdomain={subdomain}
            realmParams={realmParams}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        ))
      )}
    </Elements.Container>
  );
};

export default FormLogin;
