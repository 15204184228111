import PropType from 'prop-types';
import React from 'react';

import Text from '../Text';

const EllipsisText = ({ text, length, tail, ...props }) => {
  const hasNumberOfCharacters = (msg, count) =>
    msg?.length <= count || count < 0;

  const splitEllipse = msg => {
    if (length - tail?.length <= 0) {
      return '';
    }

    return msg?.slice?.(0, length - tail?.length);
  };

  if (hasNumberOfCharacters(text, length)) {
    return <Text {...props}>{text}</Text>;
  }
  return <Text {...props}>{`${splitEllipse(text)}${tail}`}</Text>;
};

EllipsisText.propTypes = {
  text: PropType.string,
  length: PropType.number,
  tail: PropType.string
};

EllipsisText.defaultProps = {
  length: 10,
  tail: '...',
  text: ''
};

export default EllipsisText;
