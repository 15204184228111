/* eslint-disable */

import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const realms = {
  find: () => {
    return clientApollo.query({
      query: gql`
        {
          realms {
            id
            name
            companyName
          }
        }
      `
    });
  },
  getRealm: realm =>
    clientApollo.query({
      query: gql`
        query getRealm($realm: String!) {
          getRealm(realm: $realm) {
            id
            name
            description
            companyName
            licenseTotal
            legalRegion
            oldPermissionRevocationDate
            oldPermissionRevoked
          }
        }
      `,
      variables: {
        realm
      }
    })
};

export default realms;
