/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Card,
  Icon as DSIcon,
  Text,
  IconButton,
  Menu,
  Flex
} from '@digibee/beehive-ui';
import {
  faUpFromDottedLine,
  faArrowRotateLeft
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

import useEnhancer, {
  DEPLOY_STATUS,
  normalizeSizeDeploy
} from './CardRuntime.hook';
import { Details, DetailsProps } from './components/Details';
import { Infos, StatusBody } from './components/StatusBody';
import { StatusHeader } from './components/StatusHeader';
import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../utils/isAutoScalingDeploy';

import i18n from '~/common/helpers/i18n';
import Icon from '~/components/IconCommunity';
import Role from '~/components/Role';

const isAlert = (status: string) => {
  if (!status) return '';
  return [DEPLOY_STATUS.ERROR, DEPLOY_STATUS.DEGRADED].includes(status);
};

const handleClickWithoutBubbling =
  (cb = () => {}) =>
  (ev: any) => {
    ev.stopPropagation();
    cb();
  };

export type Deployment = {
  id: string;
  licenses: string;
  project: string;
  availableReplicas?: string;
  metadata: Record<string, any>;
  activeConfiguration: {
    name: string;
    actualConsumers: string;
    replicaCount: number;
    minReplicaCount?: number;
  };
  deploymentStatus: {
    pipelineEngineBeta: string;
    pipelineEngineVersion: string;
  };
  pipeline: {
    id: string;
    name: string;
    parameterizedReplica: string;
    versionMajor: number;
    versionMinor: number;
    triggerSpec: {
      name: string;
      type: string;
      concurrentScheduling: boolean;
      cronExpression: string;
      eventName: string;
    };
  };
  startTime: string;
  errorCount: string;
  oomCount: string;
  status: {
    text: string;
  };
};

export type GenericCardRuntimeProps = {
  deployment: Deployment;
  onClick: () => void;
  environment: string;
  realm: string;
  remove: (value: any, option?: any) => void;
  goToPipeline: (id: string) => void;
  disabledMenu: boolean;
  folderPathView: boolean;
  redeploy: (
    value: Deployment['pipeline'] & { isPipelineEngine: boolean }
  ) => void;
  openDetailPromote: (value: any) => void;
  theme: Record<string, unknown>;
  openModalRollbackDeploy: (value: any) => void;
  infos: Infos;
};

export const GenericCardRuntime = memo(
  ({
    deployment,
    onClick,
    environment,
    remove,
    realm,
    goToPipeline,
    disabledMenu,
    folderPathView = true,
    redeploy,
    theme = {},
    openDetailPromote,
    openModalRollbackDeploy,
    infos
  }: GenericCardRuntimeProps) => {
    const {
      confirmDialog,
      disabled,
      isRunMovePipelineShow,
      newEnvironment,
      promoteValue,
      trigger
    } = useEnhancer({ theme, deployment, environment });

    const showNewAutoScalingExperience = useIsAutoscaling({
      triggerType: deployment.pipeline.triggerSpec?.type
    });
    const showAutoScalingValues =
      isAutoScalingDeploy({
        minReplicaCount: deployment.activeConfiguration.minReplicaCount,
        replicaCount: deployment.activeConfiguration.replicaCount
      }) && showNewAutoScalingExperience;

    const details: DetailsProps[] = [
      {
        name: i18n.t('scenes.runtime.labels.deploy_size'),
        value: normalizeSizeDeploy(deployment.activeConfiguration.name)
      },
      {
        name: i18n.t('scenes.runtime.labels.max_executions'),
        nameTooltip: i18n.t('scenes.runtime.labels.max_executions_tooltip'),
        value: deployment.activeConfiguration.actualConsumers
      },
      {
        name: showAutoScalingValues
          ? i18n.t('scenes.runtime.labels.minimum_replicas')
          : i18n.t('scenes.runtime.labels.running_replicas'),
        value: showAutoScalingValues
          ? deployment.activeConfiguration.minReplicaCount
          : deployment.availableReplicas?.split('/')[0] || 0
      },
      {
        name: showAutoScalingValues
          ? i18n.t('scenes.runtime.labels.maximum_replicas')
          : i18n.t('scenes.runtime.labels.target_of_replicas'),
        value: deployment.activeConfiguration.replicaCount
      }
    ];

    return (
      <Card.Root>
        <Box
          onClick={onClick}
          data-testid={`run-card-${deployment?.pipeline?.name}`}
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '$3',
            padding: '$3'
          }}
        >
          <Box>
            <Card.Header
              css={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Text
                disabled={disabled}
                data-testid='run-project-title'
                css={{ fontWeight: '$medium', fontSize: '$3' }}
              >
                {deployment?.pipeline?.name}
              </Text>
              <Box css={{ display: 'flex', gap: '$2', alignItems: 'center' }}>
                <Box
                  as='img'
                  css={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    display: 'block'
                  }}
                  alt={i18n.t('scenes.beta_pipelines.labels.trigger_icon')}
                  height={40}
                  width={40}
                  src={`${trigger?.iconURL}`}
                />
                <div data-testid='run-card-actions-dots'>
                  <Role
                    name={[
                      `DEPLOYMENT:DELETE{ENV=${environment?.toUpperCase()}}`,
                      'DEPLOYMENT:DELETE'
                    ]}
                  >
                    <Menu.Root>
                      {/* @ts-ignore */}
                      <Menu.Content
                        componentTrigger={
                          <IconButton
                            size='small'
                            disabled={
                              disabledMenu ||
                              deployment?.status?.text ===
                                DEPLOY_STATUS.STARTING ||
                              deployment?.status?.text ===
                                DEPLOY_STATUS.DELETING
                            }
                          >
                            <DSIcon icon='ellipsis-v' size='md' />
                          </IconButton>
                        }
                        css={{ zIndex: 50 }}
                      >
                        <Menu.Item
                          size='large'
                          data-testid='run-card-action-delete'
                          css={{ fontSize: '$1', paddingRight: '30px' }}
                          onClick={handleClickWithoutBubbling(() =>
                            confirmDialog.show({
                              title: i18n.t('scenes.runtime.actions.confirm'),
                              content: i18n.t(
                                'scenes.runtime.messages.prompt.undeploy'
                              ),
                              danger: true,
                              confirmText: i18n.t(
                                'scenes.runtime.actions.confirm'
                              ),
                              cancelText: i18n.t(
                                'scenes.runtime.actions.cancel'
                              ),
                              onConfirm: async () => {
                                try {
                                  await remove({
                                    environment,
                                    realm,
                                    deployment: deployment.id
                                  });
                                } catch (error) {
                                  console.error(error);
                                }
                              }
                            })
                          )}
                        >
                          <div style={{ marginRight: '10px' }}>
                            <DSIcon icon='trash-alt' size='md' />
                          </div>
                          {i18n.t('scenes.runtime.actions.delete_deploy')}
                        </Menu.Item>
                        <Menu.Item
                          size='large'
                          data-testid='run-card-action-detail'
                          onClick={handleClickWithoutBubbling(() =>
                            goToPipeline(deployment?.pipeline?.id)
                          )}
                          css={{ fontSize: '$1' }}
                        >
                          <div style={{ marginRight: '10px' }}>
                            <DSIcon icon='project-diagram' size='md' />
                          </div>
                          {i18n.t('scenes.runtime.actions.go_to_pipeline')}
                        </Menu.Item>
                        <Menu.Item
                          size='large'
                          data-testid='run-card-action-redeploy-'
                          onClick={handleClickWithoutBubbling(() =>
                            redeploy({
                              ...deployment?.pipeline,
                              isPipelineEngine:
                                deployment?.deploymentStatus
                                  ?.pipelineEngineBeta === 'true'
                            })
                          )}
                          css={{ fontSize: '$1' }}
                        >
                          <div style={{ marginRight: '10px' }}>
                            <DSIcon icon='rocket' size='md' />
                          </div>
                          {i18n.t('scenes.runtime.labels.redeploy')}
                        </Menu.Item>
                        {isRunMovePipelineShow && (
                          <Menu.Item
                            size='large'
                            data-testid='run-card-action-promote'
                            onClick={handleClickWithoutBubbling(() =>
                              openDetailPromote({
                                text: promoteValue,
                                newEnvironment,
                                ...deployment
                              })
                            )}
                            css={{ fontSize: '$1' }}
                          >
                            <div style={{ marginRight: '10px', fontSize: 16 }}>
                              <FontAwesomeIcon icon={faUpFromDottedLine} />
                            </div>
                            {i18n.t('action.promote_to_environment')}
                          </Menu.Item>
                        )}
                        <Menu.Item
                          size='large'
                          data-testid='run-card-action-rollback'
                          onClick={handleClickWithoutBubbling(() =>
                            openModalRollbackDeploy(deployment)
                          )}
                          css={{ fontSize: '$1' }}
                        >
                          <div style={{ marginRight: '10px', fontSize: 16 }}>
                            <FontAwesomeIcon icon={faArrowRotateLeft} />
                          </div>
                          Rollback
                        </Menu.Item>
                      </Menu.Content>
                    </Menu.Root>
                  </Role>
                </div>
              </Box>
            </Card.Header>
            {deployment?.project && folderPathView && (
              <Box
                css={{
                  marginTop: '$1'
                }}
              >
                <Text
                  disabled={disabled}
                  css={{
                    fontWeight: '$regular',
                    fontSize: '$2',
                    color: '$black'
                  }}
                >
                  <Icon
                    name='FolderClosed'
                    // @ts-ignore
                    style={{
                      color: 'var(--colors-neu300)',
                      marginRight: '8px'
                    }}
                  />
                  {deployment?.project}
                </Text>
              </Box>
            )}
          </Box>
          <StatusHeader deployment={deployment} disabled={disabled} />
          <StatusBody
            deployment={deployment}
            disabled={disabled}
            // @ts-ignore
            isAlert={isAlert(deployment?.status.text)}
            isDeleting={deployment?.status?.text === DEPLOY_STATUS.DELETING}
            infos={infos}
          />
          <Box
            css={{
              borderBottom: '1px solid #E3E5E6',
              width: 'calc(100% + 4px)',
              marginLeft: '-2px'
            }}
          />
          <Flex
            css={{
              justifyContent: 'space-between'
            }}
          >
            {details.map(({ value, ...props }, idx) => (
              <Box
                key={value}
                css={{
                  width: idx > 1 ? '150px' : '100px'
                }}
              >
                <Details value={value} {...props} />
              </Box>
            ))}
          </Flex>
        </Box>
      </Card.Root>
    );
  }
);
