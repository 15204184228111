import React from 'react';
import { ValidatePipelineData } from '../types';
// import { DeploymentPlanConfig } from '../type/DeploymentPlan';

type DeploymentPlanValue = {
  deploymentsPlan: any;
  planSelected: any;
  getPlan: () => void;
  page:number;
  loading: boolean;
  validatePipeline: (pipeline: ValidatePipelineData) => Promise<any>;
  selectPlan: (plan: any) => void;
  loadingExecuteDeploy: boolean;
  changeLoadingExecuteDeploy: (value: boolean) => void;
  checkLicense: (params: any) => Promise<any>;
  changePage: (page: number) => void;
};

export const DeploymentPlanContext = React.createContext<DeploymentPlanValue>({
  deploymentsPlan: {},
  getPlan: () => {},
  loading: false,
  validatePipeline: async () => null,
  selectPlan: () => {},
  planSelected: {},
  loadingExecuteDeploy: false,
  changeLoadingExecuteDeploy: () => {},
  checkLicense: async () => null,
  page:0,
  changePage: () => {}
});
