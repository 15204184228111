import { Box, Button, Text } from '@digibee/beehive-ui';
import React from 'react';
import Role from '~/components/Role';

interface CustomAlertProps {
  text: string | JSX.Element;
  textButton?: string;
  onClick?: Function;
}

const CustomAlertV2: React.FC<CustomAlertProps> = ({
  text,
  textButton,
  onClick
}) => {
  return (
    <>
      <Text as='p'>{text}</Text>
      {textButton && onClick && (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '$3'
          }}
        >
          <Role name='BETA:CREATE-DEPLOYMENT'>
            <Button onClick={e => onClick(e)} variant='primary'>
              {textButton}
            </Button>
          </Role>
        </Box>
      )}
    </>
  );
};

export default CustomAlertV2;
