import PropTypes from 'prop-types';
import React from 'react';

import SizeButton from '../../../../components/SizeButton';

import Label from '~/components/Label';
import Radio from '~/components/Radio';

const toUpperCase = name => {
  if (!name) return '';
  return name.toUpperCase();
};

const SizeConsumer = ({ label, value, name }) => (
  <Label
    render={() => <SizeButton size={label} label={label} value={value} />}
    align='center'
    placement='end'
    text={toUpperCase(label)}
  >
    <Radio
      type='radio'
      data-testid={`size-consumer-${value}`}
      value={value}
      name={name}
    />
  </Label>
);

SizeConsumer.defaultProps = {
  label: '',
  value: '',
  name: ''
};

SizeConsumer.propTypes = {
  /** Indicates size and labels */
  label: PropTypes.string,
  /** Indicates value radio */
  value: PropTypes.string,
  /** props name radio */
  name: PropTypes.string
};

export default SizeConsumer;
