import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

import { apiKeys } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

// Locals

const normalizeData = ({ entity, data, id = 'id' }) => {
  const Entity = new schema.Entity(entity, {}, { idAttribute: id });
  const Schema = { [entity]: [Entity] };
  return normalize(data, Schema);
};

const initConsumer = {
  id: '',
  consumer: '',
  description: '',
  customId: '',
  acls: []
};

const initialState = Immutable({
  result: {
    apiKeys: [],
    pipelines: []
  },
  entities: {
    apiKeys: {},
    pipelines: {}
  },
  loading: {
    apiKeys: false
  },
  error: null,
  status: [],
  search: '',
  environment: '',
  modal: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    apiKey: initConsumer
  },
  modalApiKeys: {
    visible: false,
    loading: false,
    success: null,
    error: null,
    apiKey: initConsumer
  },
  modalBasicAuth: {
    loading: false,
    success: null,
    error: null
  }
});

const apiKeysModel = {
  name: 'apiKeys',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setEntity(state, { result, entities }) {
      return state.merge({
        ...state,
        result: {
          ...state.result,
          apiKeys: result?.apiKeys
        },
        entities: {
          ...state.entities,
          apiKeys: entities?.apiKeys
        }
      });
    },
    setEnvironment(state, environment) {
      return state.merge({ ...state, environment });
    },
    setAcls(state, { result, entities }) {
      return state.merge({
        ...state,
        result: {
          ...state.result,
          pipelines: result?.pipelines
        },
        entities: {
          ...state.entities,
          pipelines: entities?.pipelines
        }
      });
    },
    setError: (state, error) => state.merge({ error }),
    setModal(state, payload) {
      return state.merge(
        {
          modal: {
            ...state.modal,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setModalApiKeys(state, payload) {
      return state.merge(
        {
          modalApiKeys: {
            ...state.modalApiKeys,
            ...payload
          }
        },
        { deep: true }
      );
    },
    setActionModalApiKeys: (state, payload) =>
      state.merge({
        modalApiKeys: {
          ...state.modalApiKeys,
          ...payload
        }
      }),
    setActionModal: (state, payload) =>
      state.merge({
        modal: {
          ...state.modal,
          ...payload
        }
      }),
    setModalLoading(state, loading) {
      return state.merge({
        modal: {
          ...state.modal,
          loading
        }
      });
    },
    setModalBasicAuth(state, payload) {
      return state.merge({
        modalBasicAuth: {
          ...state.modalBasicAuth,
          ...payload
        }
      });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    reset: () => initialState
  },
  effects: dispatch => ({
    async find(params = {}, state) {
      try {
        const result = await apiKeys.find(
          Object.assign(params, {
            realm: state.application.realm.realm,
            environment: state.apiKeys.environment,
            search: state.apiKeys.search
          })
        );

        const data = normalizeData({
          entity: 'apiKeys',
          data: result.data
        });

        dispatch.apiKeys.setEntity(data);
      } catch (e) {
        dispatch.apiKeys.setError(e);
      }
    },
    async findConsumerApiKeys(params = {}, state) {
      try {
        const result = await apiKeys.findConsumerApiKeys(
          Object.assign(params, {
            realm: state.application.realm.realm,
            environment: state.apiKeys.environment,
            search: state.apiKeys.search
          })
        );
        const data = normalizeData({
          entity: 'apiKeys',
          data: {
            apiKeys: [...result?.data?.consumerApiKeys]
          }
        });

        dispatch.apiKeys.setEntity(data);
      } catch (e) {
        dispatch.apiKeys.setError(e);
      }
    },
    async findAcls(params = {}, state) {
      try {
        const result = await apiKeys.findAcls(
          Object.assign(params, {
            realm: state.application.realm.realm
          })
        );
        const data = normalizeData({
          entity: 'pipelines',
          data: result.data,
          id: 'name'
        });
        dispatch.apiKeys.setAcls(data);
      } catch (e) {
        dispatch.apiKeys.setError(e);
      }
    },
    async create({ params, props, showBasicAuth }, state) {
      try {
        dispatch.apiKeys.setModal({ loading: true });
        const response = await apiKeys.create({
          realm: state.application.realm.realm,
          environment: state.apiKeys.environment,
          acls: params?.acls,
          consumer: params.consumer,
          description: params.description
        });
        dispatch.apiKeys.setActionModal(
          { success: response, showBasicAuth },
          {
            snackbar: {
              text: i18n.t('label.create_consumer_msg_success', {
                consumerName: params.consumer
              }),
              success: true,
              open: true
            }
          }
        );
        props.resetForm();
      } catch (e) {
        dispatch.apiKeys.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async createApiKey(params, state) {
      try {
        dispatch.apiKeys.setModalApiKeys({ loading: true });
        const response = await apiKeys.createApiKey({
          realm: state.application.realm.realm,
          environment: state.apiKeys.environment,
          consumer: params?.id
        });
        dispatch.apiKeys.setActionModalApiKeys(
          { success: response, showBasicAuth: params.showBasicAuth },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_create_api_key'
              ),
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setActionModalApiKeys(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async edit(params, state) {
      try {
        dispatch.apiKeys.setModal({ loading: true });
        const response = await apiKeys.edit({
          id: params?.id,
          realm: state.application.realm.realm,
          environment: state.apiKeys.environment,
          acls: params?.acls,
          consumer: params.consumer,
          description: params.description,
          changeAcls: params.changeAcls
        });
        dispatch.apiKeys.setActionModal(
          { success: response, showBasicAuth: params?.showBasicAuth },
          {
            snackbar: {
              text: i18n.t('label.edit_consumer_msg_success', {
                consumerName: params.customId
              })
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async remove(params, state) {
      try {
        const remove = await apiKeys.remove({
          realm: state.application.realm.realm,
          environment: state.apiKeys.environment,
          consumerId: params.customId
        });
        dispatch.apiKeys.setActionModal(
          {
            success: remove,
            showBasicAuth: params?.showBasicAuth
          },
          {
            snackbar: {
              text: i18n.t('label.archive_consumer_msg_success', {
                consumerName: params.customId
              })
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setActionModal(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message)
            }
          }
        );
      }
    },
    async removeApiKey(params, state) {
      try {
        const remove = await apiKeys.removeApiKey({
          realm: state?.application?.realm?.realm,
          environment: state?.apiKeys?.environment,
          consumer: params?.customId,
          apikeyId: params.apikeyId
        });
        dispatch.apiKeys.setActionModalApiKeys(
          {
            success: remove,
            showBasicAuth: params?.showBasicAuth
          },
          {
            snackbar: {
              text: i18n.t(
                'scenes.api_keys.messages.success.snackbar_delete_api_key_value'
              )
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setActionModalApiKeys(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async createBasicAuth(params, state) {
      try {
        const response = await apiKeys.createBasicAuth({
          realm: state?.application?.realm?.realm,
          environment: state?.apiKeys?.environment,
          consumer: params?.consumer,
          username: params?.username,
          password: params?.password
        });
        dispatch.apiKeys.setModalBasicAuth(
          { success: response, showBasicAuth: params?.showBasicAuth },
          {
            snackbar: {
              text: i18n.t('label.create_basic_auth_msg_success'),
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setModalBasicAuth(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    },
    async deleteBasicAuth(params, state) {
      try {
        const response = await apiKeys.deleteBasicAuth({
          realm: state?.application?.realm?.realm,
          environment: state?.apiKeys?.environment,
          consumer: params?.consumer,
          username: params?.username
        });
        dispatch.apiKeys.setModalBasicAuth(
          { success: response, showBasicAuth: params?.showBasicAuth },
          {
            snackbar: {
              text: i18n.t('label.delete_basic_auth_msg_success'),
              open: true
            }
          }
        );
      } catch (e) {
        dispatch.apiKeys.setModalBasicAuth(
          { error: e },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              danger: true,
              open: true
            }
          }
        );
      }
    }
  }),
  logics: [
    {
      type: 'apiKeys/find',
      latest: true,
      process(context, dispatch, done) {
        dispatch.apiKeys.setLoading({ path: 'apiKeys', value: true });
        done();
      }
    },
    {
      type: 'apiKeys/findAcls',
      latest: true,
      process(context, dispatch, done) {
        dispatch.apiKeys.setLoading({ path: 'pipelines', value: true });
        done();
      }
    },
    {
      type: ['apiKeys/setAcls'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.apiKeys.setLoading({ path: 'pipelines', value: false });
        done();
      }
    },
    {
      type: ['apiKeys/setError', 'apiKeys/setEntity'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.apiKeys.setLoading({ path: 'apiKeys', value: false });
        done();
      }
    },
    {
      type: ['apiKeys/setActionModal'],
      latest: true,
      process(context, dispatch, done) {
        const { search, environment } = context.getState().apiKeys;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.apiKeys.setModal({
            visible: false,
            loading: false,
            success: true,
            consumer: initConsumer
          });
          if (payload?.showBasicAuth) {
            dispatch.apiKeys.findConsumerApiKeys({ search, environment });
          } else {
            dispatch.apiKeys.find({ search, environment });
          }
        } else {
          dispatch.apiKeys.setModal({ loading: false, success: false });
        }
        done();
      }
    },
    {
      type: ['apiKeys/setActionModalApiKeys'],
      latest: true,
      process(context, dispatch, done) {
        const { search, environment } = context.getState().apiKeys;
        const { payload } = context.action;
        if (payload.success) {
          dispatch.apiKeys.setModalApiKeys({ loading: false, success: true });
          if (payload?.showBasicAuth) {
            dispatch.apiKeys.findConsumerApiKeys({ search, environment });
          } else {
            dispatch.apiKeys.find({ search, environment });
          }
        } else {
          dispatch.apiKeys.setModalApiKeys({
            loading: false,
            success: false
          });
        }
        done();
      }
    },
    {
      type: ['apiKeys/setModalBasicAuth'],
      latest: true,
      process(context, dispatch, done) {
        const { search, environment } = context.getState().apiKeys;
        const { payload } = context.action;
        if (payload.success) {
          if (payload?.showBasicAuth) {
            dispatch.apiKeys.findConsumerApiKeys({ search, environment });
          } else {
            dispatch.apiKeys.find({ search, environment });
          }
        }
        done();
      }
    }
  ]
};

export default apiKeysModel;
