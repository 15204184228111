/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Card as DSCard,
  Icon as DSIcon,
  Text as DSText,
  IconButton,
  Menu
} from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import {
  faUpFromDottedLine,
  faArrowRotateLeft
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

// Locals
import Details from './components/Details';
import OldStatusBody from './components/StatusBody';
import StatusHeader from './components/StatusHeader';
import OldStatusHeaderV2 from './components/StatusHeader/OldStatusHeaderV2';
import * as Elements from './OldCardRuntime.elements';
import useEnhancer, { DEPLOY_STATUS } from './OldCardRuntime.hook';

import i18n from '~/common/helpers/i18n';
import Icon from '~/components/IconCommunity';
import List from '~/components/List';
import Role from '~/components/Role';
import Text from '~/components/Text';

const isAlert = (status: string) => {
  if (!status) return '';
  return [DEPLOY_STATUS.ERROR, DEPLOY_STATUS.DEGRADED].includes(status);
};

const normalizeSizeDeploy = (name?: string) =>
  name?.substring(0, 1)?.toUpperCase();

const normalizeSizeTitle = (name?: string) => {
  if (!name) return '';
  return name.split('-')[0]?.toUpperCase();
};

const handleClickWithoutBubbling =
  (cb = () => {}) =>
  (ev: any) => {
    ev.stopPropagation();
    cb();
  };

export type Deployment = {
  id: string;
  licenses: string;
  project: string;
  availableReplicas: string;
  metadata: Record<string, any>;
  activeConfiguration: {
    name: string;
    actualConsumers: string;
    replicaCount: number;
  };
  deploymentStatus: {
    pipelineEngineBeta: string;
    pipelineEngineVersion: string;
  };
  pipeline: {
    id: string;
    name: string;
    parameterizedReplica: string;
    versionMajor: number;
    versionMinor: number;
    triggerSpec: {
      name: string;
      type: string;
      concurrentScheduling: boolean;
      cronExpression: string;
      eventName: string;
    };
  };
  startTime: string;
  errorCount: string;
  oomCount: string;
  status: {
    text: string;
  };
};
type OldCardRuntimeProps = {
  deployment: Deployment;
  onClick: () => void;
  environment: string;
  realm: string;
  remove: (value: any, option?: any) => void;
  goToPipeline: (id: string) => void;
  disabledMenu: boolean;
  licenseModel: string;
  folderPathView: boolean;
  redeploy: (
    value: Deployment['pipeline'] & { isPipelineEngine: boolean }
  ) => void;
  openDetailPromote: (value: any) => void;
  theme: Record<string, unknown>;
  openModalRollbackDeploy: (value: any) => void;
};

const OldCardRuntime = ({
  deployment,
  onClick,
  environment,
  remove,
  realm,
  goToPipeline,
  disabledMenu,
  licenseModel = 'PIPELINE',
  folderPathView = true,
  redeploy,
  openDetailPromote,
  theme = {},
  openModalRollbackDeploy
}: OldCardRuntimeProps) => {
  const {
    confirmDialog,
    dataMessage,
    disabled,
    isRunMovePipelineShow,
    newEnvironment,
    promoteValue,
    showDesignSystemComponents
  } = useEnhancer({ theme, deployment, environment });

  if (showDesignSystemComponents) {
    return (
      <DSCard.Root>
        <Elements.ContainerV2
          onClick={onClick}
          data-testid={`run-card-${deployment?.pipeline?.name}`}
        >
          <Elements.Header>
            <DSCard.Header
              css={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <DSText
                disabled={disabled}
                data-testid='run-project-title'
                css={{ fontWeight: '$medium', fontSize: '$3' }}
              >
                {deployment?.pipeline?.name}
              </DSText>
            </DSCard.Header>
            <div data-testid='run-card-actions-dots'>
              <Role
                name={[
                  `DEPLOYMENT:DELETE{ENV=${environment?.toUpperCase()}}`,
                  'DEPLOYMENT:DELETE'
                ]}
              >
                <Menu.Root>
                  {/* @ts-ignore */}
                  <Menu.Content
                    componentTrigger={
                      <IconButton
                        size='small'
                        disabled={
                          disabledMenu ||
                          deployment?.status?.text === DEPLOY_STATUS.STARTING ||
                          deployment?.status?.text === DEPLOY_STATUS.DELETING
                        }
                      >
                        <DSIcon icon='ellipsis-v' size='md' />
                      </IconButton>
                    }
                    css={{ zIndex: 50 }}
                  >
                    <Menu.Item
                      size='large'
                      data-testid='run-card-action-delete'
                      css={{ fontSize: '$1', paddingRight: '30px' }}
                      onClick={handleClickWithoutBubbling(() =>
                        confirmDialog.show({
                          title: i18n.t('scenes.runtime.actions.confirm'),
                          content: i18n.t(
                            'scenes.runtime.messages.prompt.undeploy'
                          ),
                          danger: true,
                          confirmText: i18n.t('scenes.runtime.actions.confirm'),
                          cancelText: i18n.t('scenes.runtime.actions.cancel'),
                          onConfirm: async () => {
                            try {
                              await remove({
                                environment,
                                realm,
                                deployment: deployment.id
                              });
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        })
                      )}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <DSIcon icon='trash-alt' size='md' />
                      </div>
                      {i18n.t('scenes.runtime.actions.delete_deploy')}
                    </Menu.Item>
                    <Menu.Item
                      size='large'
                      data-testid='run-card-action-detail'
                      onClick={handleClickWithoutBubbling(() =>
                        goToPipeline(deployment?.pipeline?.id)
                      )}
                      css={{ fontSize: '$1' }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <DSIcon icon='project-diagram' size='md' />
                      </div>
                      {i18n.t('scenes.runtime.actions.go_to_pipeline')}
                    </Menu.Item>
                    <Menu.Item
                      size='large'
                      data-testid='run-card-action-redeploy-'
                      onClick={handleClickWithoutBubbling(() =>
                        redeploy({
                          ...deployment?.pipeline,
                          isPipelineEngine:
                            deployment?.deploymentStatus?.pipelineEngineBeta ===
                            'true'
                        })
                      )}
                      css={{ fontSize: '$1' }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <DSIcon icon='rocket' size='md' />
                      </div>
                      {i18n.t('scenes.runtime.labels.redeploy')}
                    </Menu.Item>
                    {isRunMovePipelineShow && (
                      <Menu.Item
                        size='large'
                        data-testid='run-card-action-promote'
                        onClick={handleClickWithoutBubbling(() =>
                          openDetailPromote({
                            text: promoteValue,
                            newEnvironment,
                            ...deployment
                          })
                        )}
                        css={{ fontSize: '$1' }}
                      >
                        <div style={{ marginRight: '10px', fontSize: 16 }}>
                          <FontAwesomeIcon icon={faUpFromDottedLine} />
                        </div>
                        {i18n.t('action.promote_to_environment')}
                      </Menu.Item>
                    )}
                    <Menu.Item
                      size='large'
                      data-testid='run-card-action-rollback'
                      onClick={handleClickWithoutBubbling(() =>
                        openModalRollbackDeploy(deployment)
                      )}
                      css={{ fontSize: '$1' }}
                    >
                      <div style={{ marginRight: '10px', fontSize: 16 }}>
                        <FontAwesomeIcon icon={faArrowRotateLeft} />
                      </div>
                      Rollback
                    </Menu.Item>
                  </Menu.Content>
                </Menu.Root>
              </Role>
            </div>
          </Elements.Header>
          {deployment?.project && folderPathView && (
            <Box
              css={{
                marginRight: '$3',
                marginLeft: '$3',
                marginTop: '$1',
                marginBottom: '$3'
              }}
            >
              <DSText
                disabled={disabled}
                css={{ fontWeight: '$regular', fontSize: '$2' }}
              >
                {/* @ts-ignore */}
                <Icon
                  name='FolderClosed'
                  // @ts-ignore
                  style={{ color: '#939899', marginRight: '8px' }}
                />{' '}
                {deployment?.project}
              </DSText>
            </Box>
          )}
          <Elements.SubHeader>
            <OldStatusHeaderV2
              // @ts-ignore
              deployment={deployment}
              disabled={disabled}
              dataMessage={dataMessage}
            />{' '}
          </Elements.SubHeader>
          <Elements.Body>
            <OldStatusBody
              deployment={deployment}
              disabled={disabled}
              // @ts-ignore
              isAlert={isAlert(deployment?.status.text)}
              isDeleting={deployment?.status?.text === DEPLOY_STATUS.DELETING}
            />
          </Elements.Body>
          <Elements.Divider />
          <Elements.Footer>
            <Details
              title={`${i18n.t(
                'scenes.runtime.guidance.deploy_size_tooltip'
              )} - ${normalizeSizeTitle(
                deployment?.activeConfiguration?.name
              )}`}
              disabled={disabled}
              name={i18n.t('scenes.runtime.labels.deploy_size')}
              result={normalizeSizeDeploy(
                deployment?.activeConfiguration?.name
              )}
            />
            <Details
              title={i18n.t('scenes.runtime.guidance.max_executions_tooltip')}
              disabled={disabled}
              name={i18n.t('scenes.runtime.labels.max_executions')}
              result={deployment?.activeConfiguration?.actualConsumers}
            />
            <Details
              disabled={disabled}
              name={i18n.t('scenes.runtime.labels.deploy_replicas')}
              title={i18n.t('scenes.runtime.labels.deploy_replicas')}
              result={deployment?.availableReplicas}
            />
            {/* @ts-ignore */}
            {iff(licenseModel?.planName === 'Pipeline Based Model', () => (
              <Details
                disabled={disabled}
                name={i18n.t('scenes.runtime.labels.deploy_licenses')}
                title={i18n.t(
                  'scenes.runtime.guidance.deploy_licenses_tooltip'
                )}
                result={deployment?.licenses}
              />
            ))}
          </Elements.Footer>
        </Elements.ContainerV2>
      </DSCard.Root>
    );
  }

  return (
    <Elements.Card
      onClick={onClick}
      data-testid={`run-card-${deployment?.pipeline?.name}`}
    >
      <Elements.Container>
        <Elements.Header>
          {/* @ts-ignore */}
          <Elements.TextTitle
            // @ts-ignore
            className='fs-mask'
            align='left'
            title={deployment?.pipeline?.name}
            type={disabled ? 'disabled' : 'black'}
            weight='regular'
            size='lg'
            as='span'
          >
            {deployment?.pipeline?.name}
          </Elements.TextTitle>
          <div data-testid='run-card-actions-dots'>
            <Role
              name={[
                `DEPLOYMENT:DELETE{ENV=${environment?.toUpperCase()}}`,
                'DEPLOYMENT:DELETE'
              ]}
            >
              {/* @ts-ignore */}
              <Elements.Menu
                zIndexValue={0}
                component={({ toggle }: any) => (
                  <Elements.Icon
                    disabled={disabledMenu}
                    // @ts-ignore
                    onClick={handleClickWithoutBubbling(toggle)}
                    name='EllipsisV'
                  />
                )}
              >
                {/* @ts-ignore */}
                <List inset noHairlines>
                  <Elements.ContainerMenu>
                    <Elements.ListItem
                      data-testid='run-card-action-delete'
                      onClick={handleClickWithoutBubbling(() =>
                        remove(
                          { environment, realm, deployment: deployment.id },
                          {
                            confirmDialog: {
                              title: i18n.t('scenes.runtime.actions.confirm'),
                              text: i18n.t(
                                'scenes.runtime.messages.prompt.undeploy'
                              ),
                              confirmText: i18n.t(
                                'scenes.runtime.actions.confirm'
                              ),
                              cancelText: i18n.t(
                                'scenes.runtime.actions.cancel'
                              ),
                              variant: 'danger',
                              open: true
                            }
                          }
                        )
                      )}
                    >
                      {/* @ts-ignore */}
                      <Elements.Icon name='TrashAlt' faType='regular' />
                      {/* @ts-ignore */}
                      <Elements.LabelMenu as='span' align='left'>
                        {i18n.t('scenes.runtime.actions.delete_deploy')}
                      </Elements.LabelMenu>
                    </Elements.ListItem>
                    <Elements.ListItem
                      data-testid='run-card-action-detail'
                      onClick={handleClickWithoutBubbling(() =>
                        goToPipeline(deployment?.pipeline?.id)
                      )}
                    >
                      {/* @ts-ignore */}
                      <Elements.Icon name='ProjectDiagram' faType='regular' />
                      {/* @ts-ignore */}
                      <Elements.LabelMenu as='span' align='left'>
                        {i18n.t('scenes.runtime.actions.go_to_pipeline')}
                      </Elements.LabelMenu>
                    </Elements.ListItem>
                    <Elements.ListItem
                      data-testid='run-card-action-redeploy-'
                      onClick={handleClickWithoutBubbling(() =>
                        redeploy({
                          ...deployment?.pipeline,
                          isPipelineEngine:
                            deployment?.deploymentStatus?.pipelineEngineBeta ===
                            'true'
                        })
                      )}
                    >
                      {/* @ts-ignore */}
                      <Elements.Icon faType='regular' name='Rocket' />
                      {/* @ts-ignore */}
                      <Elements.LabelMenu as='span' align='left'>
                        {i18n.t('scenes.runtime.labels.redeploy')}
                      </Elements.LabelMenu>
                    </Elements.ListItem>
                    {isRunMovePipelineShow && (
                      <Elements.ListItem
                        data-testid='run-card-action-promote'
                        onClick={handleClickWithoutBubbling(() =>
                          openDetailPromote({
                            text: promoteValue,
                            newEnvironment,
                            ...deployment
                          })
                        )}
                      >
                        <Elements.Icon
                          // @ts-ignore
                          faType='regular'
                          name='UpFromDottedLine'
                        />
                        {/* @ts-ignore */}
                        <Elements.LabelMenu as='span' align='left'>
                          {i18n.t('action.promote_to_environment')}
                        </Elements.LabelMenu>
                      </Elements.ListItem>
                    )}
                    <Elements.ListItem
                      data-testid='run-card-action-rollback'
                      onClick={handleClickWithoutBubbling(() =>
                        openModalRollbackDeploy(deployment)
                      )}
                    >
                      {/* @ts-ignore */}
                      <Elements.Icon faType='regular' name='ArrowRotateLeft' />
                      {/* @ts-ignore */}
                      <Elements.LabelMenu as='span' align='left'>
                        Rollback
                      </Elements.LabelMenu>
                    </Elements.ListItem>
                  </Elements.ContainerMenu>
                </List>
              </Elements.Menu>
            </Role>
          </div>
        </Elements.Header>
        {deployment?.project && (
          <Elements.ProjectName>
            <Text as='span' align='left' type={disabled ? 'disabled' : 'black'}>
              {/* @ts-ignore */}
              <Icon name='FolderClosed' /> {deployment?.project}
            </Text>
          </Elements.ProjectName>
        )}
        <Elements.SubHeader>
          <StatusHeader
            // @ts-ignore
            deployment={deployment}
            disabled={disabled}
            dataMessage={dataMessage}
          />{' '}
        </Elements.SubHeader>
        <Elements.Body>
          <OldStatusBody
            deployment={deployment}
            disabled={disabled}
            // @ts-ignore
            isAlert={isAlert(deployment?.status.text)}
            isDeleting={deployment?.status?.text === DEPLOY_STATUS.DELETING}
          />
        </Elements.Body>
        <Elements.Divider />
        <Elements.Footer>
          <Details
            title={`${i18n.t(
              'scenes.runtime.guidance.deploy_size_tooltip'
            )} - ${normalizeSizeTitle(deployment?.activeConfiguration?.name)}`}
            disabled={disabled}
            name={i18n.t('scenes.runtime.labels.deploy_size')}
            result={normalizeSizeDeploy(deployment?.activeConfiguration?.name)}
          />
          <Details
            title={i18n.t('scenes.runtime.guidance.max_executions_tooltip')}
            disabled={disabled}
            name={i18n.t('scenes.runtime.labels.max_executions')}
            result={deployment?.activeConfiguration?.actualConsumers}
          />
          <Details
            disabled={disabled}
            name={i18n.t('scenes.runtime.labels.deploy_replicas')}
            title={i18n.t('scenes.runtime.labels.deploy_replicas')}
            result={deployment?.availableReplicas}
          />
          {/* @ts-ignore */}
          {iff(licenseModel?.planName === 'Pipeline Based Model', () => (
            <Details
              disabled={disabled}
              name={i18n.t('scenes.runtime.labels.deploy_licenses')}
              title={i18n.t('scenes.runtime.guidance.deploy_licenses_tooltip')}
              result={deployment?.licenses}
            />
          ))}
        </Elements.Footer>
      </Elements.Container>
    </Elements.Card>
  );
};

export default memo(OldCardRuntime);
