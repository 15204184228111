import { isApolloError, gql } from '@apollo/client';

import clientApollo from './clientApollo';

import { Deployment } from '~/entities/Deployment';
import { Pipeline } from '~/entities/Pipeline';
import { TriggerSpec } from '~/entities/TriggerSpec';

type PipelinesFetchData = {
  realm: string;
  search: {
    name: string;
    projectId: string;
    page: number;
  }
};

type UpgradePipelineVersionData = {
  realm: string;
  id: string;
  projectId: string;
};

type VersionMinor = {
  alert: string | null;
  deploy: boolean;
  disabled: boolean;
  hasAlert: boolean | null;
  id: string;
  name: string;
  parameterizedReplica: any;
  versionMajor: number;
  versionMinor: number;
};

export type SearchPipelinesContent = {
  name: string;
  apiExposed: boolean;
  canvasVersion: number;
  deployments: Pick<
    Deployment,
    | 'accounts'
    | 'activeConfiguration'
    | 'deploymentStatus'
    | 'environment'
    | 'environmentParameters'
    | 'id'
    | 'metadata'
    | 'pipeline'
    | 'pipelineNameNoReplica'
    | 'project'
    | 'replicaName'
    | 'startTime'
    | 'status'
  >[];
  description?: string;
  disabled: boolean;
  draft: boolean;
  iconTrigger: string;
  iconTriggerUrl: string;
  inSpec: string;
  projectLink: string;
  security: boolean;
  thumbnailName: string;
  thumbnailUrl: string;
  triggerSpec: TriggerSpec;
  versionMajor: number;
  versionMinor: number;
  _id: string;
};

type SearchPipelines = {
  content: SearchPipelinesContent[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
};

export type PipelinesFetchResult = {
  searchPipelines: SearchPipelines;
};

type UpgradePipelineVersionResult = {
  upgradePipelineVersion: string;
};

type SensitiveFieldsProp = {
  logSensitiveFields: string[];
};

type VersionMinorDataResult = {
  pipelineOnDeployById: Pipeline;
  pipeline: Pipeline;
  sensitiveFields: SensitiveFieldsProp;
};

type VersionMinorResult = {
  versionMinor: VersionMinor[];
};

type VersionMinorData = {
  environment: string;
  id: string;
  realm: string;
  pipelineMajorVersion?: number;
};

type PipelineProjectNameResult = {
  projectByPipeline: PipelineProject;
};

type PipelineProject = {
  id: string;
  name: string;
  description: string;
};

let controllers: AbortController[] = [];

const FETCH_PIPELINE = gql`
  query data($realm: String!, $id: ID!) {
    pipeline(realm: $realm, id: $id) {
      id
      name
      description
      draft
      versionMajor
      versionMinor
      inSpec
      outSpec
      parameterizedReplica
      triggerSpec
      hasAlert
      alert
      thumbnailName
      flowSpec
      realm
    }
    sensitiveFields(realm: $realm, id: $id) {
      logSensitiveFields
    }
  }
`;

const FETCH_PIPELINE_VERSION = gql`
  query versionMinor(
    $realm: String!
    $pipelineName: String!
    $versionMajor: Int!
    $environment: String!
  ) {
    versionMinor(
      realm: $realm
      pipelineName: $pipelineName
      versionMajor: $versionMajor
      environment: $environment
    ) {
      id
      name
      versionMajor
      versionMinor
      deploy
      hasAlert
      alert
      disabled
      parameterizedReplica
    }
  }
`;

const FETCH_PIPELINE_PROJECT_NAME = gql`
  query projectByPipeline(
    $realm: String!
    $pipelineName: String!
    $pipelineMajorVersion: Int!
  ) {
    projectByPipeline(
      realm: $realm
      pipelineName: $pipelineName
      pipelineMajorVersion: $pipelineMajorVersion
    ) {
      id
      name
      description
    }
  }
`;

const FETCH_PIPELINE_ON_DEPLOY = gql`
  query data($realm: String!, $id: ID!) {
    pipelineOnDeployById(realm: $realm, id: $id) {
      id
      name
      description
      draft
      versionMajor
      versionMinor
      inSpec
      outSpec
      parameterizedReplica
      triggerSpec
      hasAlert
      alert
      thumbnailName
      realm
    }
    sensitiveFields(realm: $realm, id: $id) {
      logSensitiveFields
    }
  }
`;
const pipelines = {
  fetch: async (params: PipelinesFetchData) => {
    controllers.forEach(controller => controller.abort());

    controllers = [];

    const controller = new window.AbortController();

    controllers.push(controller);

    try {
      const result = await clientApollo.query<PipelinesFetchResult>({
        query: gql`
          query searchPipelines($realm: String!, $search: JSON) {
            searchPipelines(realm: $realm, search: $search) {
              content
              last
              number
              first
              numberOfElements
              size
            }
          }
        `,
        variables: {
          ...params
        },
        context: { fetchOptions: { signal: controller.signal } }
      });
      return result;
    } catch (e) {
      throw Error(e as string);
    }
  },
  // list: async (params: PipelinesFetchData) => {
  //   controllers.forEach(controller => controller.abort());

  //   controllers = [];

  //   const controller = new window.AbortController();

  //   controllers.push(controller);

  //   try {
  //     const result = await clientApollo.query<PipelinesFetchResult>({
  //       query: gql`
  //         query listPipelines($realm: String!, $search: JSON) {
  //           listPipelines(realm: $realm, search: $search) {
  //             content
  //             last
  //             number
  //             first
  //             numberOfElements
  //             size
  //           }
  //         }
  //       `,
  //       variables: params,
  //       context: { fetchOptions: { signal: controller.signal } }
  //     });

  //     return result;
  //   } catch (e) {
  //     throw Error(e as string);
  //   }
  // },
  // getDeployments: async ({ realm }: { realm: string }) => {
  //   try {
  //     const result = await clientApollo.query({
  //       query: gql`
  //         query getPipelinesDeployments($realm: String!) {
  //           getPipelinesDeployments(realm: $realm)
  //         }
  //       `,
  //       variables: { realm }
  //     });

  //     return result;
  //   } catch (e) {
  //     throw Error(e as string);
  //   }
  // },
  // getTriggers: async ({ realm }: { realm: string }) => {
  //   try {
  //     const result = await clientApollo.query({
  //       query: gql`
  //         query getTriggers($realm: String!) {
  //           triggers(realm: $realm) {
  //             name
  //             jsonExample
  //             configurable
  //             icon
  //             iconName
  //             iconURL
  //             jsonSchema
  //             documentationURL
  //           }
  //         }
  //       `,
  //       variables: { realm }
  //     });

  //     return result;
  //   } catch (e) {
  //     throw Error(e as string);
  //   }
  // },
  cancel: () => controllers.map(controller => controller.abort),
  upgradePipelineVersion: async (params: UpgradePipelineVersionData) => {
    try {
      const response = await clientApollo.mutate<UpgradePipelineVersionResult>({
        mutation: gql`
          mutation upgradePipelineVersion(
            $realm: String!
            $id: ID!
            $projectId: String
          ) {
            upgradePipelineVersion(
              realm: $realm
              id: $id
              projectId: $projectId
            )
          }
        `,
        variables: {
          ...params
        }
      });
      return response;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  pipelinesVersionMinor: async (variables: VersionMinorData) => {
    try {
      const { data } = await clientApollo.query<VersionMinorDataResult>({
        query: FETCH_PIPELINE,
        variables
      });

      const response = await clientApollo.query<VersionMinorResult>({
        query: FETCH_PIPELINE_VERSION,
        variables: {
          pipelineName: data?.pipeline?.name,
          versionMajor: data?.pipeline?.versionMajor,
          ...variables
        }
      });

      return {
        id: data?.pipeline?.id,
        name: data?.pipeline?.name,
        description: data?.pipeline?.description,
        licenseTotal: data?.pipeline?.realm?.licenseTotal,
        parameterizedReplica: data?.pipeline?.parameterizedReplica,
        ...response?.data
      };
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  pipelineProjectName: async (variables: VersionMinorData) => {
    try {
      const pipelineMajorVersion = variables.pipelineMajorVersion;

      const { data } = await clientApollo.query<VersionMinorDataResult>({
        query: FETCH_PIPELINE_ON_DEPLOY,
        variables
      });

      const responseProjectByPipeline =
        await clientApollo.query<PipelineProjectNameResult>({
          query: FETCH_PIPELINE_PROJECT_NAME,
          variables: {
            pipelineName: data?.pipelineOnDeployById?.name,
            pipelineMajorVersion: pipelineMajorVersion
              ? pipelineMajorVersion
              : data?.pipelineOnDeployById.versionMajor,
            realm: variables.realm
          }
        });

      return {
        project: responseProjectByPipeline.data.projectByPipeline
      };
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};
export default pipelines;
