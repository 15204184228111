import { isApolloError, gql } from '@apollo/client';

import clientApollo from './clientApollo';

import { PlanName } from '~/scenes/Governance/types/Plan';

export type LicenseEnvironment = {
  [keyof: string]: {
    usedPipeline: number;
    licensesDeployed: number;
  };
}

export type Limit = {
  name: string;
  myLimit: number | string;
  unitOfMeasure: string;
  limitUsed: number | string;
  nextUpdate: string;
  healthStatus: HealthStatus;
  pipeline: string | null;
  project: string | null;
};

export type Project = {
  name: string;
  description: string;
};

export type ProjectUsage = {
  name: string;
  description?: string;
  pipelineUsageProd: number;
  pipelineUsageTest: number;
  rtuUsageProd: number;
  rtuUsageTest: number;
  environment: string;
  typeProject: 'LARGE' | 'SMALL' | 'MEDIUM' | 'UNDEFINED';
};

export type PipelinesUsage = {
  environment: string;
  pipelineName: string;
  replica: number;
  rtu: number;
  projectName: string;
  size: string;
  concurrentExection?: number;
  projectDescription?: string;
  pipelineVersion?: string;
};

export type PipelineAndCapacityBasedModel = {
  licenses: number;
  licensesAvailable: number;
  environment: LicenseEnvironment;
}

export type License = {
  planName: PlanName;
  pipelineBasedModel?: {
    licenses: number;
    licensesAvailable: number;
    environment: {
      [keyof: string]: {
        usedPipeline: number;
        licensesDeployed: number;
      };
    };
  };
  capacityBasedModel?: {
    licenses: number;
    licensesAvailable: number;
    environment: {
      [keyof: string]: {
        usedPipeline: number;
        licensesDeployed: number;
      };
    };
  };
  subscriptionBasedModel?: {
    environment: {
      [keyof: string]: {
        rtu: {
          allowedRtu: number;
          availableRtu: number;
          usedRtu: number;
        };
        subscription: {
          allowedPipeline: number;
          availablePipeline: number;
          usedPipeline: number;
        };
      };
    };
  };
  projectsInformation?: {
    projects: ProjectUsage[];
  };
};

export type RealmContacts = {
  CSM: {
    name: string;
    email: string;
  };
  HEAD: {
    name: string;
    email: string;
  };
  FUNCTIONAL_ANALYST: {
    name: string;
    email: string;
  };
};

export type LimitOfUse = {
  usageLimits: Limit[];
};

export type TrafficLight = {
  metric: string;
  pipeline: string | null;
  project: string | null;
  realm: string;
  status: string;
  environment: string;
  createdAt: string;
  limitUsed: number | string;
  limitAcquired: number | string;
};

export type LimitDetails = {
  limitUsed: number | string;
  limitAcquired: number | string;
  infractions: TrafficLight[];
  trafficLights: TrafficLight[];
};

export enum HealthStatus {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED'
}

export type ProposalUpsell = {
  rtu: number;
  proposalRtu: number;
  realmLimits: {
    name: string;
    myLimit: number | string;
    unitOfMeasure: string;
  }[];
  pipelineLimits?: {
    name: string;
    myLimit: number | string;
    unitOfMeasure: string;
  }[];
};

const license = {
  getLicense: ({
    realm,
    projectsInformation,
    environment
  }: {
    realm: string;
    projectsInformation: boolean;
    environment: string;
  }) =>
    clientApollo.query<{ license: License }>({
      query: gql`
        query license(
          $realm: String!
          $projectsInformation: Boolean
          $environment: String
        ) {
          license(
            realm: $realm
            projectsInformation: $projectsInformation
            environment: $environment
          )
        }
      `,
      variables: {
        realm,
        projectsInformation,
        environment
      }
    }),
  getRealmContacts: (realm: string) =>
    clientApollo.query<{ realmContacts: RealmContacts }>({
      query: gql`
        query realmContacts($realm: String!) {
          realmContacts(realm: $realm)
        }
      `,
      variables: {
        realm
      }
    }),
  getLimitOfUse: ({
    realm,
    environment,
    isGlobal,
    pipeline,
    status
  }: {
    realm: string;
    environment: string;
    isGlobal: boolean;
    pipeline?: string,
    status?: string[]
  }) =>
    clientApollo.query<{ limitOfUse: LimitOfUse }>({
      query: gql`
        query limitOfUse($realm: String!, $environment: String!, $isGlobal: Boolean, $pipeline: String, $status: [String]) {
          limitOfUse(realm: $realm, environment: $environment, isGlobal: $isGlobal, pipeline: $pipeline, status: $status)
        }
      `,
      variables: {
        realm,
        environment,
        isGlobal,
        pipeline,
        status
      }
    }),
  getPipelinesUsage: async ({
    realm,
    environment,
    project
  }: {
    realm: string;
    environment: string;
    project?: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        pipelinesUsage: {
          deployments: PipelinesUsage[];
          project: Project | null;
        };
      }>({
        query: gql`
          query pipelinesUsage(
            $realm: String!
            $environment: String
            $project: String
          ) {
            pipelinesUsage(
              realm: $realm
              environment: $environment
              project: $project
            )
          }
        `,
        variables: {
          realm,
          environment,
          project
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getLimitOfUseDetail: async ({
    realm,
    environment,
    type,
    dateFrom,
    dateTo
  }: {
    realm: string;
    environment: string;
    type: string;
    dateFrom: string;
    dateTo: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        limitOfUseDetail: LimitDetails;
      }>({
        query: gql`
          query limitOfUseDetail(
            $realm: String!
            $environment: String!
            $type: String!
            $dateFrom: String!
            $dateTo: String!
          ) {
            limitOfUseDetail(
              realm: $realm
              environment: $environment
              type: $type
              dateFrom: $dateFrom
              dateTo: $dateTo
            )
          }
        `,
        variables: {
          realm,
          environment,
          type,
          dateFrom,
          dateTo
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  getProposalUpsell: async ({
    realm,
    environment
  }: {
    realm: string;
    environment: string;
  }) => {
    try {
      const response = await clientApollo.query<{
        proposalUpsell: ProposalUpsell;
      }>({
        query: gql`
          query proposalUpsell($realm: String!, $environment: String!) {
            proposalUpsell(realm: $realm, environment: $environment)
          }
        `,
        variables: {
          realm,
          environment
        }
      });
      return response?.data;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default license;
