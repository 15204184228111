import { Icon as DSIcon, Text as DSText } from '@digibee/beehive-ui';
import { choose, otherwise, when } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconNative from '../IconCommunity';
import Text from '../Text';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

const Container = styled.div`
  height: 32px;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gra300};
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;
const ContentBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
`;

ContentBack.defaultProps = {
  'data-testid': 'build-topbar-back-to-pipelines-link'
};

const Icon = styled(IconNative)`
  padding-left: 8px;
  padding-right: 8px;
`;

const IconWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
`;
const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;
  width: 100%;
`;

const TopBar = ({ title, linkText, onBack, afterTitle }) => {
  const { BUILD_CANVAS_SCENE_DESIGN_SYSTEM_MIGRATION } = featureFlagConstants;

  const { treatments } = useFeatureFlag([
    BUILD_CANVAS_SCENE_DESIGN_SYSTEM_MIGRATION
  ]);
  const showNewDesignSystemComponents =
    treatments[BUILD_CANVAS_SCENE_DESIGN_SYSTEM_MIGRATION].treatment === 'on';

  const onClick = event => {
    const isTargetBlank = event.metaKey || event.ctrlKey;
    onBack(isTargetBlank);
  };

  return (
    <>
      {choose(
        when(showNewDesignSystemComponents, () => (
          <Container>
            <ContentBack onBack={() => {}} onClick={onClick}>
              <IconWrapper>
                <DSIcon
                  data-testid='build-topbar-back-to-pipelines-link'
                  icon='arrow-circle-left'
                  variant='primary'
                />
              </IconWrapper>
              <DSText
                variant='b2'
                css={{ color: '$pri500', fontWeight: '$medium' }}
              >
                {linkText}
              </DSText>
            </ContentBack>
            <ContentTitle>
              <DSText variant='b2' css={{ fontWeight: 500 }}>
                {title}
              </DSText>
              {afterTitle && afterTitle}
            </ContentTitle>
          </Container>
        )),
        otherwise(() => (
          <Container>
            <ContentBack onBack={() => {}} onClick={onClick}>
              <Icon name='ArrowCircleLeft' primary />
              <Text size='sm' type='primary' weight='medium'>
                {linkText}
              </Text>
            </ContentBack>
            <ContentTitle>
              <Text weight='regular' size='sm'>
                {title}
              </Text>
              {afterTitle && afterTitle}
            </ContentTitle>
          </Container>
        ))
      )}
    </>
  );
};

TopBar.defaultProps = {
  afterTitle: null
};

TopBar.propTypes = {
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  afterTitle: PropTypes.node
};

export default TopBar;
