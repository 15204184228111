import { isApolloError, gql } from '@apollo/client';
import { ComponentSpec } from '@digibee/flow';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

import clientApollo from './clientApollo';

import { API_KEY, URL } from '~/common/helpers/environment';
import { Pipeline } from '~/entities/Pipeline';

type GenerateAIDocumentationParams = {
  realm: string;
  triggerSpec: Record<string, unknown>;
  flowSpec: Record<string, unknown>;
  name: string;
  versionMajor: number;
  versionMinor: number;
};

export type GenerateAIDocumentationData = {
  description: string;
  systemsInvolved: ComponentSpec[];
  events: ComponentSpec[];
  globals: (ComponentSpec & { global_variables: string[] })[];
  accounts: (ComponentSpec & { global_variables: string[] })[];
  uniqueEvents: string[];
  uniqueGlobals: string[];
  uniqueAccounts: string[];
};

type GenerateAIDocumentationResult = {
  generateAIDocumentation: GenerateAIDocumentationData;
};

type PipelineFetchData = {
  id: string;
  realm: string;
};

type SensitiveFieldsProp = {
  logSensitiveFields: string[];
};

type PipelineFetchResult = {
  pipelineV2: Pick<
    Pipeline,
    | 'id'
    | 'name'
    | 'description'
    | 'draft'
    | 'versionMajor'
    | 'versionMinor'
    | 'inSpec'
    | 'outSpec'
    | 'triggerSpec'
    | 'hasAlert'
    | 'alert'
    | 'thumbnailName'
    | 'flowSpec'
    | 'disconnectedFlowSpecs'
    | 'canvasVersion'
    | 'disabled'
    | 'parameterizedReplica'
  >;
  sensitiveFields: SensitiveFieldsProp | null;
};

type SavePipelineMiniatureResult = {
  contentName: string;
  success: boolean;
  url: string;
};

type SavePipelineMiniatureData = {
  token: string;
  image: string;
  realm: string;
};

type SavePipelineData = {
  realm: string;
  trackingId: string;
  pipeline: Pipeline;
  projectId: string;
};

type SavePipeline = Pick<
  Pipeline,
  | 'id'
  | 'name'
  | 'description'
  | 'draft'
  | 'parameterizedReplica'
  | 'thumbnailName'
  | 'versionMajor'
  | 'versionMinor'
>;

type SavePipelineV2Result = {
  updatePipelineV2: SavePipeline;
};

type UpdateSensitiveFieldsData = {
  realm: string;
  pipelineId: string;
  sensitiveFields: string[];
};

type UpdateSentiveFieldsResult = {
  updatePipelineSensitiveFields: boolean;
};

type RevertPipelineCanvasVersionData = {
  realm: string;
  pipelineId: string;
  trackingId: string;
};

type RevertPipelineResult = {
  revertPipelineCanvasVersion: SavePipeline;
};

const pipelineV2 = {
  getPipeline: async (variables: PipelineFetchData) => {
    try {
      const result = await clientApollo.query<PipelineFetchResult>({
        query: gql`
          query data($realm: String!, $id: ID!) {
            pipelineV2(realm: $realm, id: $id) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              inSpec
              outSpec
              parameterizedReplica
              triggerSpec
              hasAlert
              alert
              thumbnailName
              flowSpec
              disconnectedFlowSpecs
              stepsDocumentations
              disabled
              canvasVersion
              componentsCount
              connectedOnFlowComponentsCount
              usedComponents
              suggestedComponentsIds
              createdAt
              lastModified
            }
            sensitiveFields(realm: $realm, id: $id) {
              logSensitiveFields
            }
          }
        `,
        variables
      });

      return {
        data: {
          pipeline: result.data.pipelineV2,
          sensitiveFields: result.data.sensitiveFields
        }
      };
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  saveImage: async ({
    token,
    image,
    realm
  }: SavePipelineMiniatureData): Promise<string> => {
    try {
      const formData = new FormData();
      const name = `${uuid()}.jpg`;
      const file = new File([image], name, { type: 'image/jpg' });
      const headers = {
        'Content-Type': 'multipart/form-data',
        apikey: API_KEY,
        authorization: token
      };

      formData.append('file', file);

      const { data } = await axios.post<SavePipelineMiniatureResult>(
        `${URL}/design/realms/${realm}/staticContent`,
        formData,
        { headers }
      );

      return data.contentName;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        throw new Error(e.message);
      }
      throw e;
    }
  },

  save: async ({
    realm,
    trackingId,
    pipeline,
    projectId
  }: SavePipelineData) => {
    try {
      const response = await clientApollo.mutate<SavePipelineV2Result>({
        mutation: gql`
          mutation updatePipelineV2(
            $realm: String!
            $trackingId: String!
            $pipeline: PipelineVersionInput!
            $projectId: String
          ) {
            updatePipelineV2(
              realm: $realm
              pipeline: $pipeline
              trackingId: $trackingId
              projectId: $projectId
            ) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              parameterizedReplica
              thumbnailName
            }
          }
        `,
        variables: {
          realm,
          trackingId,
          pipeline,
          projectId
        }
      });

      return response.data?.updatePipelineV2 || {};
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  async updateSensitiveFields({
    realm,
    pipelineId,
    sensitiveFields
  }: UpdateSensitiveFieldsData): Promise<boolean> {
    try {
      const response = await clientApollo.mutate<UpdateSentiveFieldsResult>({
        mutation: gql`
          mutation updatePipelineSensitiveFieldsV2(
            $realm: String!
            $pipelineId: String!
            $sensitiveFields: [String]
          ) {
            updatePipelineSensitiveFields(
              realm: $realm
              pipelineId: $pipelineId
              sensitiveFields: $sensitiveFields
            )
          }
        `,
        variables: {
          realm,
          pipelineId,
          sensitiveFields
        }
      });

      return response.data?.updatePipelineSensitiveFields || false;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  async generateAIDocumentation({
    realm,
    name,
    versionMajor,
    versionMinor,
    triggerSpec,
    flowSpec
  }: GenerateAIDocumentationParams): Promise<GenerateAIDocumentationData> {
    try {
      const response = await clientApollo.mutate<GenerateAIDocumentationResult>(
        {
          mutation: gql`
            mutation generateAIDocumentation(
              $realm: String!
              $lang: String!
              $pipeline: PipelineAIDocumentationInput!
            ) {
              generateAIDocumentation(
                realm: $realm
                lang: $lang
                pipeline: $pipeline
              )
            }
          `,
          variables: {
            realm,
            lang: navigator.language || 'en-US',
            pipeline: {
              triggerSpec,
              flowSpec,
              name,
              versionMajor,
              versionMinor
            }
          }
        }
      );

      return response.data?.generateAIDocumentation!;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  revertPipelineCanvasVersion: async ({
    realm,
    pipelineId,
    trackingId
  }: RevertPipelineCanvasVersionData) => {
    try {
      const response = await clientApollo.mutate<RevertPipelineResult>({
        mutation: gql`
          mutation revertPipelineCanvasVersion(
            $realm: String!
            $pipelineId: String!
            $trackingId: String!
          ) {
            revertPipelineCanvasVersion(
              realm: $realm
              pipelineId: $pipelineId
              trackingId: $trackingId
            ) {
              id
              name
              description
              draft
              versionMajor
              versionMinor
              parameterizedReplica
              thumbnailName
            }
          }
        `,
        variables: {
          realm,
          pipelineId,
          trackingId
        }
      });

      return response.data?.revertPipelineCanvasVersion || {};
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default pipelineV2;
