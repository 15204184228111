/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import withHooks from './withHooks';

import { API_KEY_INTERCOM } from '~/common/helpers/environment';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const withIntercom = withHooks(({ appID = API_KEY_INTERCOM }) => {
  const { authentication, intercom, profile } = useSelector(state => state);
  const props = {
    user_id: authentication.userData ? authentication.userData.email : '',
    email: authentication.userData ? authentication?.userData?.email : '',
    user_hash: intercom.key || '',
    namer: authentication.userData
      ? `${authentication.userData.realm} - ${authentication.userData.email}`
      : ``,
    name: profile.user
      ? `${profile?.user?.firstName} ${profile?.user?.lastName}`
      : '',
    realm: authentication.userData ? authentication.userData.realm : ''
  };
  const [toggle, setToggle] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [timeoutFallback, setTimeoutFallback] = useState(null);

  const actions = {
    onToggle: () => {
      setToggle(!toggle);
      window.Intercom(!toggle ? 'show' : 'hide');
    }
  };
  useEffect(() => {
    if (!toggle) clearTimeout(timeoutFallback);

    if (toggle) {
      const fallback = setTimeout(() => {
        const intercomElement = document.getElementsByClassName(
          'intercom-messenger-frame'
        );
        if (!intercomElement?.length) {
          window.store.dispatch.intercom.setModal({
            path: 'fallback',
            value: true
          });
          setToggle(false);
        }
      }, 2000);
      setTimeoutFallback(fallback);
    }
  }, [toggle]);

  useEffect(() => {
    if (!window.Intercom && canUseDOM) {
      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${appID}`;
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
      window.Intercom('onHide', () => {
        setToggle(false);
      });
      window.Intercom('onShow', () => {
        setToggle(true);
      });
      window.Intercom('onUnreadCountChange', count => {
        setUnreadCount(count);
      });
    }

    return () => {
      if (!canUseDOM || !window.Intercom) return;
      window.Intercom('shutdown');
      delete window.Intercom;
      delete window.intercomSettings;
    };
  }, []);

  useEffect(() => {
    if (window.Intercom) {
      if (props.user_hash && props.user_id) {
        window.Intercom('boot', {
          ...props,
          hide_default_launcher: true,
          app_id: appID
        });
      } else {
        window.Intercom('boot', {
          hide_default_launcher: true,
          app_id: appID
        });
      }
    }
  }, [props.user_hash, props.user_id]);

  return {
    ...actions,
    unreadCount
  };
});
export default withIntercom;
