import { parse as cronParser } from '@datasert/cronjs-parser';
import { Box, SideSheet, Tag, TagProps } from '@digibee/beehive-ui';
import { choose, when } from '@digibee/control-statements';
import {
  faCalendar,
  faClockTenThirty,
  faFileSignature,
  faFolderClosed,
  faGears,
  faLayerGroup,
  faSlidersSimple,
  faUserLarge
} from '@fortawesome/pro-regular-svg-icons';
import cronstrue from 'cronstrue';
import moment from 'moment';

import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';
import { isAutoScalingDeploy } from '../../utils/isAutoScalingDeploy';
import { Details } from '../CardRuntime/components/Details';
import { Infos, StatusBody } from '../CardRuntime/components/StatusBody';

import capitalizeFirstLetter from '~/common/helpers/capitalizeFirstLetter';
import i18n from '~/common/helpers/i18n';
import { Deployment } from '~/entities/Deployment';
import {
  getMessageStatus,
  type DataMessageType
} from '~/scenes/Runtime/components/StatusDeploy';

interface PipelineDeployWithServerlessProps {
  deployment: Deployment;
}

const normalizeSizeTitle = (name?: string) => {
  if (!name) return '';
  return name.at(0)?.toUpperCase();
};

const PipelineDeployWithServerless = ({
  deployment
}: PipelineDeployWithServerlessProps) => {
  const dataMessage: DataMessageType = getMessageStatus(deployment as any);
  const showNewAutoScalingExperience = useIsAutoscaling({
    triggerType: deployment.pipeline.triggerSpec?.type
  });
  const showAutoScalingValues =
    isAutoScalingDeploy({
      minReplicaCount: deployment.activeConfiguration?.minReplicaCount,
      replicaCount: deployment.activeConfiguration?.replicaCount
    }) && showNewAutoScalingExperience;

  const checkCronExpression = () => {
    try {
      // @ts-ignore
      cronParser(deployment.pipeline.triggerSpec.cronExpression, {
        hasSeconds: true
      });
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  };

  const infos: Infos = [
    [
      {
        title: i18n.t('label.environment'),
        value: capitalizeFirstLetter(
          deployment.activeConfiguration?.environment.name || ''
        )
      }
    ],
    [
      {
        icon: faFolderClosed,
        title: i18n.t('noun.project'),
        value: deployment.project
      }
    ],
    [
      {
        icon: faCalendar,
        value: moment(deployment.startTime, 'x').format('DD/MM/YYYY HH:mm:ss')
      },
      {
        icon: faGears,
        title: i18n.t('label.engine_version'),
        value: deployment.deploymentStatus?.pipelineEngineVersion?.replace(
          /^([^.]*\.[^.]*\.[^.]*).*/,
          '$1'
        )
      }
    ],
    [
      {
        icon: faUserLarge,
        value: deployment.metadata?.userName
      },
      {
        icon: faLayerGroup,
        value: deployment.pipeline.parameterizedReplica
          ? 'Multi Instance'
          : null
      }
    ],
    [
      {
        icon: faFileSignature,
        title: i18n.t('scenes.runtime.labels.event_name'),
        value: deployment.pipeline.triggerSpec?.eventName
      }
    ],
    [
      {
        icon: faSlidersSimple,
        title: i18n.t('scenes.runtime.labels.conc_scheduling'),
        titleTooltip: i18n.t('scenes.runtime.labels.conc_scheduling_tooltip'),
        value: (() => {
          if (
            deployment.pipeline.triggerSpec?.concurrentScheduling === undefined
          ) {
            return null;
          }

          return deployment.pipeline.triggerSpec.concurrentScheduling
            ? 'on'
            : 'off';
        })()
      },
      {
        icon: faClockTenThirty,
        title: i18n.t('scenes.runtime.labels.recurring_intervals'),
        value: checkCronExpression()
          ? cronstrue.toString(
              // @ts-ignore
              deployment.pipeline.triggerSpec?.cronExpression
            )
          : null,
        valueTooltip: checkCronExpression()
          ? cronstrue.toString(
              // @ts-ignore
              deployment.pipeline.triggerSpec?.cronExpression
            )
          : null
      }
    ]
  ];

  return (
    <>
      <SideSheet.Section title={deployment.pipeline.name}>
        <Box css={{ display: 'flex', gap: '$3', paddingTop: '$1' }}>
          <Tag variant='version'>
            {`${i18n.t('scenes.runtime.labels.version')} ${
              deployment.pipeline.versionMajor
            }.${deployment.pipeline.versionMinor}`}
          </Tag>
          {!deployment.isHistory && (
            <Tag variant={dataMessage.style as TagProps['variant']}>
              {dataMessage.text}
            </Tag>
          )}
          {showAutoScalingValues && <Tag variant='info'>Autoscaling</Tag>}
        </Box>

        <StatusBody
          deployment={deployment as any}
          disabled={false}
          isAlert={false}
          isDeleting={false}
          infos={infos}
          showAlert={false}
          size='md'
        />
      </SideSheet.Section>
      <SideSheet.Section>
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box
            css={{
              width: '80px'
            }}
          >
            <Details
              name={i18n.t('scenes.runtime.labels.deploy_size')}
              value={normalizeSizeTitle(deployment.activeConfiguration?.name)}
            />
          </Box>
          <Box
            css={{
              width: '100px'
            }}
          >
            <Details
              name={i18n.t('scenes.runtime.labels.max_executions')}
              nameTooltip={i18n.t(
                'scenes.runtime.labels.max_executions_tooltip'
              )}
              value={deployment.activeConfiguration?.actualConsumers}
            />
          </Box>
          {choose(
            when(showAutoScalingValues, () => (
              <Box
                css={{
                  width: '150px'
                }}
              >
                <Details
                  name={i18n.t('scenes.runtime.labels.minimum_replicas')}
                  value={deployment.activeConfiguration?.minReplicaCount}
                />
              </Box>
            )),
            when(!!deployment.availableReplicas, () => (
              <Box
                css={{
                  width: '150px'
                }}
              >
                <Details
                  name={i18n.t('scenes.runtime.labels.running_replicas')}
                  value={deployment.availableReplicas?.split('/')[0]}
                />
              </Box>
            ))
          )}
          <Box
            css={{
              width: '150px'
            }}
          >
            <Details
              name={
                showAutoScalingValues
                  ? i18n.t('scenes.runtime.labels.maximum_replicas')
                  : i18n.t('scenes.runtime.labels.target_of_replicas')
              }
              value={deployment.activeConfiguration?.replicaCount}
            />
          </Box>
        </Box>
      </SideSheet.Section>
    </>
  );
};

export default PipelineDeployWithServerless;
