import styled, { css } from 'styled-components';

import ellipsis from '~/common/styled/mixins/ellipsis';
import CardNative from '~/components/Card';
import IconNative from '~/components/IconCommunity';
import List from '~/components/List';
import MenuNative from '~/components/Menu';
import Text from '~/components/Text';

export const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.gra400};
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const ContainerV2 = styled(Container)`
  border: none;
`;

export const Card = styled(CardNative)`
  display: flex;
  flex-direction: column;
  height: 320px;
  cursor: pointer;
  width: 100%;
  border: none;
  box-shadow: none;
  position: relative;
`;

export const Menu = styled(MenuNative)`
  top: 90%;
  left: -180px;
`;

export const ContainerMenu = styled.div`
  background: ${props => props?.theme?.colors?.white};
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
`;

export const TextTitle = styled(Text)`
  ${ellipsis('300px')}
`;

export const ListItem = styled(List.Item)`
  cursor: pointer;
  padding: 3px 5px;
`;

export const Icon = styled(IconNative)`
  font-size: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  ${props =>
    props.disabled &&
    css`
      curso: default;
      pointer-events: none;
      color: ${props.theme.colors.gra400};
    `};
  color: ${props =>
    props.disabled
      ? props.theme.colors.gra300
      : props.theme.colors.gra600} !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  justify-content: space-between;
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
`;

export const LabelMenu = styled(Text)`
  padding: 0 10px;
  cursor: pointer;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${props => props?.theme?.colors?.gra100};
  width: 95%;
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
`;

export const DividerServerless = styled.div`
  border-bottom: 1px solid ${props => props?.theme?.colors?.gra100};
  width: calc(100% + 4px);
  margin-left: -2px;
`;

export const ProjectName = styled.div`
  width: 50%;
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
`;

export const TriggerImage = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
`;
