import queryString from 'querystring';

import { last, omit, identity, pickBy } from 'lodash';
import moment from 'moment';
import { normalize, schema } from 'normalizr';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';
import uuid from 'uuid/v4';

import * as api from '~/api';
import i18n from '~/common/helpers/i18n';

const getInitPropsSearch = () => {
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAUT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment().seconds(0).milliseconds(0);
  return {
    pipelineName: STRING_EMPTY,
    pipelineKey: STRING_EMPTY,
    pipelineVersion: STRING_EMPTY,
    time: TIME_TYPE_DEFAUT,
    payloadSearch: STRING_EMPTY,
    source: STRING_EMPTY,
    jwtUser: STRING_EMPTY,
    jwtHash: STRING_EMPTY,
    errorCode: STRING_EMPTY,
    debug: STRING_EMPTY,
    messageType: STRING_EMPTY,
    specific: {
      dateFrom: SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAUT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment().seconds(0).milliseconds(0);
  return {
    pipelineName: params.pipelineName || STRING_EMPTY,
    pipelineKey: params.pipelineKey || STRING_EMPTY,
    pipelineVersion: params.pipelineVersion || STRING_EMPTY,
    time: params.time || TIME_TYPE_DEFAUT,
    payloadSearch: params.payloadSearch || STRING_EMPTY,
    source: params.source || STRING_EMPTY,
    jwtUser: params.jwtUser || STRING_EMPTY,
    jwtHash: params.jwtHash || STRING_EMPTY,
    errorCode: params.errorCode || STRING_EMPTY,
    debug: params.debug || STRING_EMPTY,
    messageType: params.messageType || STRING_EMPTY,
    specific: {
      dateFrom: moment(params.dateFrom) || SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: moment(params.dateTo) || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const parseStringDate = ({ dateTo, dateFrom }) => ({
  dateFrom: moment(dateFrom).seconds(0).milliseconds(0).toISOString(),
  dateTo: moment(dateTo).seconds(59).milliseconds(999).toISOString()
});

const initialState = Immutable({
  result: {
    messages: [],
    messagesByKey: []
  },
  entities: {
    messages: {},
    messagesByKey: {}
  },
  loading: {
    fetch: false,
    more: false,
    reexecute: false,
    fetchMessagesByKey: false
  },
  success: {
    reexecute: null
  },
  last: true,
  error: {
    fetch: null
  },
  modal: {
    reexecution: false,
    logsDetails: false,
    pipeline: null
  },
  pipelines: {
    content: []
  },
  search: getPropsSearch()
});

const messagesEntity = new schema.Entity('messages');
const messagesSchema = { messages: [messagesEntity] };

const messagesByKeyEntity = new schema.Entity('messagesByKey');
const messagesByKeySchema = { messagesByKey: [messagesByKeyEntity] };

const transformFormatDate = (values, params) =>
  moment().subtract(values, params).toISOString();

const roundNumber = roundVersion => {
  const number = Math.round(roundVersion[0] * 100);
  const results = Math.ceil(number) / 100;
  return results;
};

const messagesModel = {
  name: 'messages',
  state: initialState,
  reducers: {
    set(state, payload) {
      return state.merge({ ...payload }, { deep: true });
    },
    loading(state, { path, value }) {
      return state.merge(
        {
          loading: {
            [path]: value
          }
        },
        { deep: true }
      );
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          [path]: value
        }
      });
    },
    success(state, { path, value }) {
      return state.merge({
        success: {
          [path]: value
        }
      });
    },
    setLast(state, payload) {
      return state.merge({
        last: payload
      });
    },
    open(state, { path, value }) {
      return state.merge(
        {
          modal: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    setModalPipeline(state, payload) {
      return state.merge(
        {
          modal: {
            pipeline: payload
          }
        },
        { deep: true }
      );
    },
    setSearch(state, payload) {
      return state.merge({
        search: payload
      });
    },
    setMessagesByKey(state, payload) {
      return state.merge(
        {
          ...payload
        },
        { deep: true }
      );
    },
    clearError(state) {
      return state.merge(
        {
          error: {
            fetch: null
          }
        },
        { deep: true }
      );
    },
    clearMessage(state) {
      return state.merge({
        result: {
          messages: []
        },
        entities: {
          messages: {}
        }
      });
    },
    clearMessagesByKey(state) {
      return state.merge(
        {
          result: {
            messagesByKey: []
          },
          entities: {
            messagesByKey: {}
          }
        },
        { deep: true }
      );
    },
    clearSearch(state) {
      return state.merge(
        {
          search: getInitPropsSearch()
        },
        { deep: true }
      );
    },
    setPipelines(state, { pipelines }) {
      return state.merge({
        pipelines: {
          content: pipelines.historyPipeline.content
        }
      });
    }
  },
  effects: dispatch => ({
    async fetchMessagesByKey(params, { application }) {
      try {
        const { realm } = application.realm;
        const response = await api.messages.messagesByKey({
          realm,
          ...params
        });
        const responseMessages = {
          messagesByKey: response.messagesByKey.map(message => ({
            id: uuid(),
            ...message
          }))
        };
        const normalizeMessagesBykey = normalize(
          responseMessages,
          messagesByKeySchema
        );
        dispatch.messages.setMessagesByKey(normalizeMessagesBykey);
      } catch (e) {
        dispatch.messages.setError({
          path: 'messagesByKey',
          value: e.message
        });
      }
    },
    async reexecute(params, { messages, application }) {
      try {
        const { modal } = messages;
        const version = roundNumber(modal.pipeline.pipeline_version);
        const pipeName = modal.pipeline.pipeline_name;
        const payload = JSON.parse(params.payload);
        const { realm } = application.realm;
        const response = await api.messages.reexecute({
          realm,
          ...params,
          payload,
          version,
          pipeName
        });
        dispatch.messages.success({
          path: 'reexecute',
          value: response
        });
      } catch (e) {
        toast.error(i18n.t('scenes.messages.messages.error.reexecute'));
        dispatch.messages.setError({
          path: 'reexecute',
          value: e.message
        });
      }
    },
    async clearRoute() {
      dispatch.router.navigate({
        pathname: window.location.pathname
      });
    },
    async fetch(params, rootState) {
      try {
        const { realm } = rootState.application.realm;
        const mapTimes = {
          '5 Minutes': {
            dateFrom: transformFormatDate(5, 'minutes'),
            dateTo: moment().toISOString()
          },
          '15 Minutes': {
            dateFrom: transformFormatDate(15, 'minutes'),
            dateTo: moment().toISOString()
          },
          '1 Hour': {
            dateFrom: transformFormatDate(1, 'hour'),
            dateTo: moment().toISOString()
          }
        };
        const getMapTimes = (type = '5 Minutes', payload) =>
          mapTimes[type] || {
            dateFrom: moment(payload.dateFrom)
              .seconds(0)
              .milliseconds(0)
              .toISOString(),
            dateTo: moment(payload.dateTo)
              .seconds(59)
              .milliseconds(999)
              .toISOString()
          };
        const paramsSearch = params.isQueryString
          ? getPropsSearch()
          : params?.search;
        const specific = getMapTimes(
          paramsSearch?.time,
          paramsSearch?.specific
        );
        const search = {
          ...omit(
            pickBy({ ...paramsSearch, ...specific }, identity),
            'specific'
          )
        };
        dispatch.router.navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams(search)}`,
          options: { replace: true }
        });
        const response = await api.messages.fetch({
          realm,
          ...search,
          environment: params.environment,
          ...specific
        });
        const messages = normalize(response.data, messagesSchema);
        dispatch.messages.set(messages, { ...search, specific });
        dispatch.messages.setLast(messages?.result?.messages?.length !== 50);
      } catch (error) {
        dispatch.messages.setError({
          path: 'fetch',
          value: error.message
        });
      }
    },
    async getMore(params, rootState) {
      const { realm } = rootState.application.realm;
      const { messages } = rootState;
      const messageId = last(messages.result.messages);
      const message = messages.entities.messages[messageId];
      const { specific } = messages.search;
      const range = parseStringDate(specific);
      const search = {
        ...omit(
          pickBy({ ...messages?.search, ...specific }, identity),
          'specific'
        )
      };
      try {
        const response = await api.messages.fetch({
          realm,
          ...search,
          ...params,
          ...range,
          searchAfterId: messageId,
          searchAfterTimestamp: message?.request_timestamp
        });
        const messagesNormalize = normalize(response.data, messagesSchema);
        const result = [
          ...messages.result.messages,
          ...messagesNormalize.result.messages
        ];
        const entities = {
          ...messages.entities.messages,
          ...messagesNormalize.entities.messages
        };
        const value = {
          result: {
            messages: result
          },
          entities: {
            messages: entities
          }
        };
        dispatch.messages.set(value, { ...search, specific });
        dispatch.messages.setLast(
          messagesNormalize?.result?.messages?.length !== 50
        );
      } catch (e) {
        dispatch.messages.error({
          path: 'more',
          value: e.message
        });
      }
    },
    async fetchPipelineName(params, { application }) {
      try {
        const { realm } = application.realm;

        if (params.pipelineName) {
          const { data } = await api.pipelineHistory.findHistoryPipeline({
            ...params,
            realm
          });

          dispatch.messages.setPipelines({
            pipelines: data
          });
        }
      } catch (error) {
        dispatch.messages.setError({ error: { pipelines: error.message } });
      }
    }
  }),
  logics: [
    {
      type: 'messages/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.messages.loading({
          path: 'fetch',
          value: true
        });
        dispatch.messages.clearError();
        done();
      }
    },
    {
      type: 'messages/fetchMessagesByKey',
      latest: true,
      process(context, dispatch, done) {
        dispatch.messages.loading({
          path: 'fetchMessagesByKey',
          value: true
        });
        done();
      }
    },
    {
      type: 'messages/setMessagesByKey',
      latest: true,
      process(context, dispatch, done) {
        dispatch.messages.loading({
          path: 'fetchMessagesByKey',
          value: false
        });
        done();
      }
    },
    {
      type: 'messages/set',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.messages.setSearch(meta);
        dispatch.messages.loading({
          path: 'more',
          value: false
        });
        dispatch.messages.loading({
          path: 'fetch',
          value: false
        });
        done();
      }
    },
    {
      type: 'messages/setError',
      latest: true,
      process({ action }, dispatch, done) {
        if (['fetch'].includes(action?.payload?.path)) {
          dispatch.messages.loading({
            path: action?.payload.path,
            value: false
          });
          dispatch.messages.clearMessage();
        }
        dispatch.messages.loading({ path: 'reexecute', value: false });
        done();
      }
    },
    {
      type: 'messages/getMore',
      latest: true,
      process(_, dispatch, done) {
        dispatch.messages.loading({
          path: 'more',
          value: true
        });
        done();
      }
    },
    {
      type: 'messages/open',
      latest: true,
      process({ action }, dispatch, done) {
        const { meta } = action;
        dispatch.messages.setModalPipeline(meta);
        done();
      }
    },
    {
      type: 'messages/reexecute',
      latest: true,
      process(_, dispatch, done) {
        dispatch.messages.loading({ path: 'reexecute', value: true });
        done();
      }
    },
    {
      type: ['messages/success'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.messages.loading({ path: 'reexecute', value: false });
        dispatch.messages.open({
          path: 'reexecution',
          value: false
        });
        done();
      }
    },
    {
      type: ['messages/fetchMessagesByKey'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.messages.loading({ path: 'fetchMessagesByKey', value: true });
        done();
      }
    }
  ]
};

export default messagesModel;
