import DeployPipelineWithServerless from './DeployPipelineWithServerless';
import OldDeployPipeline from './OldDeployPipeline';
import { useIsAutoscaling } from '../../hooks/useIsAutoscaling';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

export const DeployPipeline = (props: any) => {
  const { RUN_SET_ENGINE_VERSION } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_SET_ENGINE_VERSION]);

  const showNewAutoScalingExperience = useIsAutoscaling({
    shouldUseTriggerType: false
  });

  const isPipelineEngine =
    treatments[RUN_SET_ENGINE_VERSION].treatment === 'on';

  if (!showNewAutoScalingExperience) {
    return <OldDeployPipeline {...props} isPipelineEngine={isPipelineEngine} />;
  }

  return (
    <DeployPipelineWithServerless
      {...props}
      isPipelineEngine={isPipelineEngine}
    />
  );
};
