import { Box, Alert } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import React from 'react';

import DeploymentValidationV2 from '../DeploymentValidation/DeploymentValidationV2';

import i18n from '~/common/helpers/i18n';


type Props = {
  hasAlert: boolean;
  configurationLastDeploy: boolean;
  validationLicense: [];
  validationPipeline: [];
};

const DeploymentAlertV2: React.FC<Props> = ({
  hasAlert,
  configurationLastDeploy,
  validationLicense,
  validationPipeline
}) => (
  <>
    {iff(hasAlert, () => (
      <Box>
        <Alert
          title={i18n.t('scenes.runtime.messages.error.alert')}
          variant='warning'
        />
      </Box>
    ))}
    {iff(configurationLastDeploy, () => (
      <Box>
        <Alert
          title={i18n.t('scenes.runtime.messages.error.already_deployed')}
          variant='warning'
        />
      </Box>
    ))}
    {iff(validationLicense?.length > 0, () => (
      <Box>
        <DeploymentValidationV2 validation={validationLicense} />
      </Box>
    ))}
    {iff(validationPipeline?.length > 0, () => (
      <Box>
        <DeploymentValidationV2 validation={validationPipeline} />
      </Box>
    ))}
  </>
);

export default DeploymentAlertV2;
