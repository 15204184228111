/* eslint-disable */
import { gql } from '@apollo/client';
import clientApollo from './clientApollo';

const connectors = {
  get: variables =>
    clientApollo.query({
      query: gql`
        query data($realm: String!, $betaShapes: Boolean) {
          components(realm: $realm, betaShapes: $betaShapes) {
            name
            category
            jsonExample
            jsonSchema
            jsonSchemaEdge
            visual
            type
            icon
            iconName
            iconURL
            shape
            deprecated
            title
            documentationURL
          }
          libraries(realm: $realm) {
            id
            name
            description
            icon
            iconName
            iconURL
            libraryServices {
              id
              name
              description
              icon
              iconName
              iconURL
              inSpec
              title
              versionMajor
              versionMinor
              type
              configExample
              disabled
            }
            realmMaintainer {
              id
              name
              description
              companyName
            }
          }
          replicas(realm: $realm) {
            label
          }
          triggers(realm: $realm) {
            name
            jsonExample
            configurable
            icon
            iconName
            iconURL
            jsonSchema
            documentationURL
          }
          librariesConsumers(realm: $realm) {
            id
            name
            description
            icon
            iconName
            iconURL
            libraryServices {
              id
              name
              description
              icon
              iconName
              iconURL
              inSpec
              title
              versionMajor
              versionMinor
              versionFix
              type
              configExample
              disabled
              jsonSchema
              visual
            }
          }
          capsulesConsumers(realm: $realm) {
            id
            name
            description
            icon
            colorDefault
            colorBackground
            colorAccent
            capsuleCollectionHeader
            iconName
            iconURL
            realmMaintainer {
              name
            }
            capsuleServices {
              id
              name
              capsuleCollectionGroup
              description
              icon
              iconName
              iconURL
              inSpec
              title
              versionMajor
              versionMinor
              versionFix
              type
              configExample
              disabled
              jsonSchema
              visual
              shape
              documentation
            }
          }
        }
      `,
      variables
    }),

  getReplicas: variables =>
    clientApollo.query({
      query: gql`
        query data($realm: String!) {
          replicas(realm: $realm) {
            label
          }
        }
      `,
      variables
    })
};

export default connectors;
