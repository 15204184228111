import { SearchBar } from '@digibee/beehive-ui';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';

// Locals
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import Search from '~/components/Search';

const Container = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 50vw;
  @media only screen and (max-width: 761px) {
    width: 100%;
    padding: 0 15px;
  }
`;

interface HeaderProps {
  find?: () => void;
  realm?: string;
  environment?: string;
  setSearch?: (searchTerm: string) => void;
  search: Function;
  status: string[];
  selectedTags: string[];
}

const Header: React.FC<HeaderProps> = ({ search, status, selectedTags }) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const tags = [
    {
      label: i18n.t('scenes.runtime.labels.deployed'),
      value: 'SERVICE_ACTIVE'
    },
    {
      label: i18n.t('scenes.runtime.labels.status_error'),
      value: 'SERVICE_ERROR'
    },
    {
      label: i18n.t('scenes.runtime.labels.redeploy'),
      value: 'REDEPLOY'
    },
    {
      label: i18n.t('scenes.runtime.labels.starting'),
      value: 'STARTING'
    }
  ];

  const [searchParams] = useSearchParams();
  const statusTag = [searchParams.get('status')??""];

  return (
    <Container>
      <Content>
        {showDesignSystemComponents ? (
          <SearchBar
            data-testid='run-input-search'
            placeholder={i18n.t('common.actions.search')}
            placeholderTags={i18n.t('label.all')}
            textValue={searchParams.get('pipelineName') || ''}
            onSearch={(e, tags) => search(e, tags)}
            //@ts-ignore
            tagsSource={tags}
            tagsValue={statusTag}
          />
        ) : (
          <Search
            placeholder={i18n.t('scenes.runtime.actions.search')}
            data-testid='run-input-search'
            onSearch={(e: any, tags: any) => search(e, tags)}
            tagsSource={tags}
            zIndexValue={'auto'}
            tagsValue={selectedTags}
            onTextChange={() => {}}
            onTagsChange={() => {}}
            onChange={() => {}}
            textValue={undefined}
          />
        )}
      </Content>
    </Container>
  );
};

export default Header;
