import { Box, SideSheet, Text, Tag, TagProps } from '@digibee/beehive-ui';
import {
  faCalendar,
  faFolderClosed,
  faGears,
  faLayerGroup,
  faUserLarge
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';

import DetailsV2 from '../Details/DetailsV2';

import capitalizeFirstLetter from '~/common/helpers/capitalizeFirstLetter';
import i18n from '~/common/helpers/i18n';
import { Deployment } from '~/entities/Deployment';
import { Trigger } from '~/entities/Trigger';
import {
  getMessageStatus,
  type DataMessageType
} from '~/scenes/Runtime/components/StatusDeploy';

interface PipelineDeployV2Props {
  deployment: Deployment;
  trigger: Trigger;
  linkGoToPipeline?: string;
  title?: string;
}

const normalizeSizeTitle = (name: string) => {
  if (!name) return '';
  return name.split('-')[0]?.toUpperCase();
};

const normalizeSizeDeploy = (name: string) =>
  capitalizeFirstLetter(name?.split('-')[0]);

const PipelineDeployV2: React.FC<PipelineDeployV2Props> = ({
  deployment,
  title
}) => {
  const dataMessage: DataMessageType = getMessageStatus(deployment as any);

  return (
    <>
      <SideSheet.Section title={title}>
        <Box css={{ display: 'flex', gap: '$3', paddingTop: '$1' }}>
          <Tag variant='version'>
            {`${i18n.t('scenes.runtime.labels.version')} ${
              deployment?.pipeline.versionMajor
            }.${deployment?.pipeline?.versionMinor}`}
          </Tag>
          <Tag variant={dataMessage.style as TagProps['variant']}>
            {dataMessage.text}
          </Tag>
        </Box>
        <Box
          css={{
            marginTop: '$2',
            padding: '5px 0'
          }}
        >
          <Text
            css={{
              fontSize: '$2',
              color: '$black',
              lineHeight: '$2'
            }}
          >{`${i18n.t('label.environment')}: ${capitalizeFirstLetter(
            deployment.activeConfiguration?.environment.name || ''
          )}`}</Text>
        </Box>
        <Box
          css={{
            display: 'flex',
            padding: '5px 0',
            gap: '$2',
            alignItems: 'center'
          }}
        >
          <FontAwesomeIcon
            icon={faGears}
            style={{ color: 'var(--colors-neu400)' }}
          />
          <Text
            css={{
              fontSize: '$2',
              color: '$black',
              lineHeight: '$2'
            }}
          >{`${i18n.t('label.engine_version')}: ${
            deployment?.deploymentStatus?.pipelineEngineVersion
              ? deployment?.deploymentStatus?.pipelineEngineVersion
              : i18n.t('label.legacy')
          }`}</Text>
        </Box>
        <>
          {deployment?.project && (
            <Box
              css={{
                display: 'flex',
                padding: '5px 0',
                gap: '$2',
                alignItems: 'center'
              }}
            >
              <FontAwesomeIcon
                icon={faFolderClosed}
                style={{ color: 'var(--colors-neu300)' }}
              />
              <Text
                css={{
                  fontSize: '$2',
                  color: '$black',
                  lineHeight: '$2'
                }}
              >{`${i18n.t('noun.project')}: ${deployment.project}`}</Text>
            </Box>
          )}
        </>
        <Box
          css={{
            display: 'flex',
            padding: '5px 0',
            gap: '$4',
            alignItems: 'center'
          }}
        >
          {deployment?.metadata?.userName && (
            <Box
              css={{
                display: 'flex',
                gap: '$2',
                alignItems: 'center'
              }}
            >
              <FontAwesomeIcon
                icon={faUserLarge}
                style={{ color: 'var(--colors-neu300)' }}
              />
              <Text
                css={{
                  fontSize: '$2',
                  color: '$black',
                  lineHeight: '$2'
                }}
                title={deployment?.metadata?.userName}
              >
                {deployment?.metadata?.userName}
              </Text>
            </Box>
          )}
          <Box
            css={{
              display: 'flex',
              gap: '$2',
              alignItems: 'center'
            }}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ color: 'var(--colors-neu300)' }}
            />
            <Text
              css={{
                fontSize: '$2',
                color: '$black',
                lineHeight: '$2'
              }}
            >
              {`${i18n.t('scenes.runtime.labels.deploy_start_date')} ${moment(
                deployment?.startTime,
                'x'
              ).format('DD/MM/YYYY HH:mm')}`}
            </Text>
          </Box>
          {deployment?.pipeline?.parameterizedReplica && (
            <Box
              css={{
                display: 'flex',
                gap: '$2',
                alignItems: 'center'
              }}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                style={{ color: 'var(--colors-neu300)' }}
              />
              <Text
                css={{
                  fontSize: '$2',
                  color: '$black',
                  lineHeight: '$2'
                }}
              >
                Multi-instance
              </Text>
            </Box>
          )}
        </Box>
      </SideSheet.Section>
      <SideSheet.Section>
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '$3'
          }}
        >
          <DetailsV2
            title={`${i18n.t(
              'scenes.runtime.guidance.deploy_size_tooltip'
            )} - ${normalizeSizeTitle(
              deployment?.activeConfiguration?.name || ''
            )}`}
            name={i18n.t('scenes.runtime.labels.deploy_size')}
            result={normalizeSizeDeploy(
              deployment?.activeConfiguration?.name || ''
            )}
          />
          <DetailsV2
            title={i18n.t('scenes.runtime.guidance.max_executions_tooltip')}
            name={i18n.t('scenes.runtime.labels.max_executions')}
            result={
              deployment?.activeConfiguration?.actualConsumers.toString() || ''
            }
          />
          <DetailsV2
            name={i18n.t('scenes.runtime.labels.deploy_replicas')}
            title={i18n.t('scenes.runtime.labels.deploy_replicas')}
            result={deployment?.availableReplicas?.toString() || ''}
          />
          <DetailsV2
            name={i18n.t('scenes.runtime.labels.deploy_licenses')}
            title={i18n.t('scenes.runtime.guidance.deploy_licenses_tooltip')}
            result={deployment?.licenses?.toString() || ''}
          />
        </Box>
      </SideSheet.Section>
    </>
  );
};

export default PipelineDeployV2;
