import { Tag } from '@digibee/beehive-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import * as Elements from './OldStatusHeader.elements';

import i18n from '~/common/helpers/i18n';
import { OldStatusDeployV2 } from '~/scenes/Runtime/components/StatusDeploy';

const OldStatusHeaderV2 = ({
  deployment,
  disabled,
  theme,
  // eslint-disable-next-line react/prop-types
  showEngineBeta = true
}) => (
  <Elements.Container>
    <Elements.Row>
      <Tag
        variant='version'
        title={`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
        onClick={ev => ev.stopPropagation()}
        data-testid='build-card-tag-version'
      >
        {`${i18n.t('scenes.runtime.labels.version')} ${
          deployment?.pipeline.versionMajor
        }.${deployment?.pipeline?.versionMinor}`}
      </Tag>
      <OldStatusDeployV2
        deployment={deployment}
        theme={theme}
        disabled={disabled}
        isMarginBottom={false}
      />
    </Elements.Row>
  </Elements.Container>
);

OldStatusHeaderV2.defaultProps = {
  theme: {}
};

OldStatusHeaderV2.propTypes = {
  deployment: PropTypes.objectOf({
    pipeline: PropTypes.objectOf({
      id: PropTypes.string,
      name: PropTypes.string,
      parameterizedReplica: PropTypes.string,
      versionMajor: PropTypes.number,
      versionMinor: PropTypes.number
    }),
    startTime: PropTypes.string,
    errorCount: PropTypes.string,
    oomCount: PropTypes.string,
    status: PropTypes.objectOf({})
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.objectOf({})
};

export default withTheme(OldStatusHeaderV2);
