import { connectors as ConnectorsApi } from '~/api';
import getBase64Url from '~/common/helpers/getBase64Url';
import createIconCapsule from '~/common/helpers/createIconCapsule';
import { Connectors } from './getConnector';

type Params = {
  realm: string;
  userToken: string;
};

const fetchConnectors = async ({
  realm,
  userToken
}: Params): Promise<Connectors> => {
  const response = await ConnectorsApi.get({ realm, betaShapes: true });
  const components = await Promise.all(
    // @ts-ignore
    response?.data?.components?.map(async c => {
      const iconURL = c?.iconURL;

      return {
        ...c,
        iconURL: iconURL ? await getBase64Url(iconURL) : null,
        shape: c.shape === 'ellipsis' ? 'ellipse' : c.shape
      };
    })
  ).catch(() => response?.data?.components);

  const triggers = await Promise.all(
    // @ts-ignore
    response?.data?.triggers?.map(async c => {
      const iconURL = c?.iconURL;
      return {
        ...c,
        iconURL: iconURL ? await getBase64Url(iconURL) : null
      };
    })
  ).catch(() => response?.data?.triggers);

  const capsulesConsumers = await Promise.all(
    // @ts-ignore
    response?.data?.capsulesConsumers?.map(async c => {
      const header = await getBase64Url(
        c?.capsuleCollectionHeader?.iconURL,
        userToken
      );

      return {
        ...c,
        // @ts-ignore
        capsuleServices: c.capsuleServices.map(capsule => {
          const iconURL = createIconCapsule({
            header,
            accent: c?.colorAccent,
            background: c?.colorBackground,
            icon: capsule?.iconName
          });

          return {
            ...capsule,
            iconURL
          };
        })
      };
    })
  ).catch(() => response?.data?.capsulesConsumers);

  const librariesConsumers = await Promise.all(
    // @ts-ignore
    response?.data?.librariesConsumers?.map(async c => {
      const iconURL = await getBase64Url(c?.iconURL);

      return {
        ...c,
        iconURL: iconURL || null,
        // @ts-ignore
        libraryServices: c?.libraryServices?.map(l => ({
          ...l,
          iconURL: iconURL || null
        }))
      };
    })
  ).catch(() => response?.data?.librariesConsumers);

  const libraries = response?.data?.libraries;

  const connectors = {
    components,
    triggers,
    capsulesConsumers,
    librariesConsumers,
    libraries
  };

  return connectors;
};

export default fetchConnectors;
