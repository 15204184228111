/* eslint-disable react/prop-types */
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import find from 'lodash/find';
import head from 'lodash/head';
import moment from 'moment';
import styled from 'styled-components';

import CustomAlert from './components/CustomAlert';
import * as Elements from './DetailsDeployment.elements';
import DetailsDeploymentV2 from './DetailsDeploymentV2';
import PipelineDeploy from '../PipelineDeploy';
import TriggerDetails from '../TriggerDetails';

import i18n from '~/common/helpers/i18n';
import Alert from '~/components/Alert';
import { SidesheetHeader } from '~/components/SideSheet';
import Skeleton from '~/components/Skeleton';

const SeparatorAlert = styled.div`
  padding-bottom: 16px;
`;

const Content = styled.div`
  padding: 15px;
  word-wrap: break-w;
  width: 95%;
  line-height: 1.3;
`;

const getMessage = deployment => {
  if (deployment?.lastError || deployment?.lastOMM) {
    return ` - ${moment(
      deployment?.lastError || deployment?.lastOMM,
      'x'
    ).format('DD/MM/YYYY HH:mm:ss')}`;
  }
  return '';
};

const DetailsDeployment = ({
  deployment,
  visible,
  onCancel,
  title,
  triggers,
  editPipelinePath,
  redeploy
}) => {
  const ERROR = 'Error';
  const DEGRADED = 'degraded';

  const isAlert = status => {
    if (!status) return '';
    return [ERROR, DEGRADED].includes(status);
  };

  const trigger = find(
    triggers,
    t =>
      t.jsonExample.name === deployment?.pipeline.triggerSpec?.name &&
      t.jsonExample.type === deployment?.pipeline.triggerSpec?.type
  );

  const mapValueSchema = (values = {}, schema = []) => {
    const keys = Object.keys(values);
    return keys.reduce((acc, current) => {
      const prop = schema.find(x => x.property === current);
      if (prop) {
        acc.push({
          label: prop.label,
          value: JSON.stringify(values[current])
        });
        return acc;
      }
      return acc;
    }, []);
  };
  const triggerSource = mapValueSchema(
    deployment?.pipeline?.triggerSpec,
    head(trigger?.jsonSchema)?.schema
  );

  const linkGoToPipeline = `${editPipelinePath}/${deployment?.pipeline?.id}`;

  if (visible) {
    return (
      <DetailsDeploymentV2
        onCancel={onCancel}
        title={title}
        deployment={deployment}
        trigger={trigger}
        linkGoToPipeline={linkGoToPipeline}
        triggerSource={triggerSource}
        redeploy={redeploy}
        isRyclingMessage
        isAlert={isAlert(deployment?.status.text)}
      />
    );
  }

  return (
    <Elements.SideSheet
      onCloseClick={onCancel}
      opened={visible}
      width='80%'
      data-testid='run-page-detail'
    >
      <>
        <SidesheetHeader>{title}</SidesheetHeader>
        <Content>
          <PipelineDeploy
            editPipelinePath={editPipelinePath}
            deployment={deployment}
            trigger={trigger}
            linkGoToPipeline={linkGoToPipeline}
          />
          <Elements.WrapperTrigger>
            {choose(
              when(!trigger, () => (
                <Skeleton variant='retangular' width='100%' height='60px' />
              )),
              otherwise(() => (
                <TriggerDetails
                  source={triggerSource}
                  title={head(trigger?.jsonSchema)?.label}
                  triggers={deployment?.trigger.data}
                />
              ))
            )}
          </Elements.WrapperTrigger>
          {iff(deployment?.oomCount > 0, () => (
            <SeparatorAlert>
              <Alert
                text={`Out of Memory ${getMessage(deployment)}`}
                width='100%'
                collapsable
                type='warning'
                content={
                  <CustomAlert
                    text={
                      <>
                        {i18n.t('scenes.runtime.messages.error.out_of_memory')}
                        <Elements.LinkDoc
                          href={i18n.t('action.out_of_memory_error_doc_url')}
                          data-testid='run-detail-link-out-of-memory'
                          target='_blank'
                        >
                          {i18n.t('label.learn_about_this_error_link')}
                        </Elements.LinkDoc>
                      </>
                    }
                  />
                }
              />
            </SeparatorAlert>
          ))}
          {iff(deployment?.errorCount > 0, () => (
            <SeparatorAlert>
              <Alert
                width='100%'
                collapsable
                type='info'
                text={`${i18n.t('label.recycled_on')} ${getMessage(
                  deployment
                )}`}
                content={
                  <CustomAlert
                    text={
                      <>
                        {i18n.t(
                          'label.event_occurred_in_pipe_recycled_card_details_msg_info'
                        )}
                        <br />
                        <Elements.LinkDoc
                          href={i18n.t('action.learn_more_about_recycled_url')}
                          data-testid='run-detail-link-recycled'
                          target='_blank'
                        >
                          {i18n.t('action.learn_more_about_recycled')}
                        </Elements.LinkDoc>
                      </>
                    }
                  />
                }
              />
            </SeparatorAlert>
          ))}
          {iff(deployment?.metadata?.errorOutdatedGlobal, () => (
            <SeparatorAlert>
              <Alert
                width='100%'
                collapsable
                type='warning'
                text={i18n.t('label.global_has_been_edited_details_title_msg')}
                content={
                  <CustomAlert
                    text={
                      <>
                        {i18n.t(
                          'label.update_globals_redeploy_needed_card_details_msg_warning'
                        )}
                        <br />
                        <Elements.LinkDoc
                          href={i18n.t('action.learn_more_about_globals_url')}
                          data-testid='run-detail-link-recycled'
                          target='_blank'
                        >
                          {i18n.t(
                            'action.learn_more_about_globals_warning_link'
                          )}
                        </Elements.LinkDoc>
                      </>
                    }
                  />
                }
              />
            </SeparatorAlert>
          ))}
          {iff(deployment?.pipelineUpdateProject, () => (
            <SeparatorAlert>
              <Alert
                width='100%'
                collapsable
                type='warning'
                text={`${i18n.t('label.needs_redeploy_alert')}`}
                content={
                  <CustomAlert
                    text={i18n.t('label.needs_redeploy_msg')}
                    textButton={i18n.t('action.redeploy_pipeline')}
                    onClick={() => {
                      onCancel();
                      redeploy(deployment?.pipeline);
                    }}
                  />
                }
              />
            </SeparatorAlert>
          ))}
        </Content>
      </>
    </Elements.SideSheet>
  );
};

export default DetailsDeployment;
