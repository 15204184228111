/* eslint-disable react/prop-types */
import { Box } from '@digibee/beehive-ui';
import { choose, when, otherwise, iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './DeployPipelineDialog.elements';
import { useIsAutoscaling } from '../../../../hooks/useIsAutoscaling';
import { CardRuntime } from '../../../CardRuntime';

import {
  SHOW_LINK_TO_PIPELINE_SUBSCRIPTION_ARTICLE,
  SHOW_LINK_TO_RTU_ARTICLE
} from '~/common/helpers/environment';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import CardLicense from '~/scenes/Runtime/components/CardLicense/CardLicense';

function DeployPipeline({
  environment,
  deployment,
  triggers,
  isRedeploy = false,
  folderPathView = true
}) {
  const { RUN_ENABLE_LICENSEV2 } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_ENABLE_LICENSEV2]);

  const showNewAutoScalingExperience = useIsAutoscaling({
    // eslint-disable-next-line react/prop-types
    triggerType: deployment.pipeline?.triggerSpec?.type
  });
  const isLicenseV2 = treatments[RUN_ENABLE_LICENSEV2].treatment === 'on';

  return (
    <Elements.Box top={20}>
      <CardRuntime
        disabledMenu
        deployment={deployment}
        triggers={triggers}
        licenseModel={deployment.licenseStatus.licenseModel}
        folderPathView={folderPathView}
      />
      {choose(
        when(showNewAutoScalingExperience, () => null),
        when(isLicenseV2, () => (
          <Box css={{ marginTop: '$2' }}>
            <CardLicense
              environment={environment || deployment.newEnvironment}
              isRedeploy={isRedeploy}
              licenses={deployment.licenses}
              licenseStatus={deployment.licenseStatus}
            />
          </Box>
        )),
        when(deployment.licenseStatus.licenseModel === 'PIPELINE', () => (
          <>
            <Elements.Details top={16} bottom={8} className='fs-mask'>
              <Elements.Text size='md' align='left' as='span' type='secondary'>
                {i18n.t('scenes.runtime.labels.total_realm_licenses_alert')}
              </Elements.Text>
              <Elements.Text size='md' align='left' as='span' type='secondary'>
                {deployment.licenseStatus.totalAvailableLicenses}
              </Elements.Text>
            </Elements.Details>

            <Elements.Details bottom={8} className='fs-mask'>
              <Elements.Text size='md' align='left' as='span' type='secondary'>
                {isRedeploy
                  ? i18n.t('label.redeploy_licenses_alert')
                  : i18n.t('scenes.runtime.labels.deploy_licenses_alert')}
              </Elements.Text>
              <Elements.Text size='md' align='left' as='span' type='secondary'>
                {deployment.licenses}
              </Elements.Text>
            </Elements.Details>

            <Elements.Separator />

            <Elements.Details top={16} className='fs-mask'>
              <Elements.Text size='md' align='left' as='span'>
                {isRedeploy
                  ? i18n.t('label.licenses_available_after_redeploy')
                  : i18n.t('scenes.runtime.labels.available_licenses_deploy')}
              </Elements.Text>
              <Elements.Text size='md' align='left' as='span'>
                {deployment.licenseStatus.totalAvailableLicenses -
                  deployment.licenses}
              </Elements.Text>
            </Elements.Details>
          </>
        )),
        otherwise(() => (
          <Elements.Card>
            <Elements.Details bottom={8} className='fs-mask'>
              <Elements.Text size='md' align='left' as='span' weight='regular'>
                {i18n.t(
                  environment === 'prod'
                    ? 'label.prod_pipeline_subscription'
                    : 'label.test_pipeline_subscription'
                )}
                &nbsp;
                {iff(
                  SHOW_LINK_TO_PIPELINE_SUBSCRIPTION_ARTICLE === 'true',
                  () => (
                    <Elements.Link
                      href={i18n.t('action.learn_more_pipe_subs_url')}
                      target='_blank'
                    >
                      <span>{i18n.t('label.learn_more')}</span>
                      <Elements.LinkIcon name='ExternalLink' faType='regular' />
                    </Elements.Link>
                  )
                )}
              </Elements.Text>
            </Elements.Details>

            <Elements.CardContent bottom={8} className='fs-mask'>
              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionProd
                        .totalAmountOfPipelineSubscriptionAllowedProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionTest
                        .totalAmountOfPipelineSubscriptionAllowedTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.licensed')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>-</Elements.Operator>

              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionProd.pipelineSubscriptionUsageProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionTest.pipelineSubscriptionUsageTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.in_use')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>-</Elements.Operator>

              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionProd.pipelineSubscriptionAddProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionTest.pipelineSubscriptionAddTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.used_in_this_deploy')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>=</Elements.Operator>

              <Elements.Info total>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionProd
                        .availableAmountOfPipelineSubscriptionProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .pipelineSubscriptionTest
                        .availableAmountOfPipelineSubscriptionTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.available_after_deploy')}
                </Elements.Text>
              </Elements.Info>
            </Elements.CardContent>

            <Elements.Separator />

            <Elements.Details top={16} bottom={8} className='fs-mask'>
              <Elements.Text size='md' align='left' as='span' weight='regular'>
                {i18n.t(
                  environment === 'prod'
                    ? 'label.prod_runtime_unit'
                    : 'label.test_runtime_unit'
                )}
                &nbsp;
                {iff(SHOW_LINK_TO_RTU_ARTICLE === 'true', () => (
                  <Elements.Link
                    href={i18n.t('action.learn_more_rtu_url')}
                    target='_blank'
                  >
                    <span>{i18n.t('label.learn_more')}</span>
                    <Elements.LinkIcon name='ExternalLink' faType='regular' />
                  </Elements.Link>
                ))}
              </Elements.Text>
            </Elements.Details>

            <Elements.CardContent className='fs-mask'>
              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuProd.totalRtuAllowedProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuTest.totalRtuAllowedTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.licensed')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>-</Elements.Operator>

              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuProd.totalRtuUsageProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuTest.totalRtuUsageTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.in_use')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>-</Elements.Operator>

              <Elements.Info>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuProd.totalRtuAddProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuTest.totalRtuAddTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.used_in_this_deploy')}
                </Elements.Text>
              </Elements.Info>

              <Elements.Operator>=</Elements.Operator>

              <Elements.Info total>
                <Elements.Text size='md' as='span'>
                  {environment === 'prod'
                    ? deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuProd.totalRtuAvailableProd
                    : deployment.licenseStatus.pipelineSubscriptionAndRtu
                        .rtuTest.totalRtuAvailableTest}
                </Elements.Text>
                <Elements.Text size='sm' as='span'>
                  {i18n.t('label.available_after_deploy')}
                </Elements.Text>
              </Elements.Info>
            </Elements.CardContent>
          </Elements.Card>
        ))
      )}
    </Elements.Box>
  );
}

DeployPipeline.propTypes = {
  environment: PropTypes.string.isRequired,
  deployment: PropTypes.shape({
    licenses: PropTypes.number.isRequired,
    newEnvironment: PropTypes.string.isRequired,
    licenseStatus: PropTypes.shape({
      licenseModel: PropTypes.string.isRequired,
      totalAvailableLicenses: PropTypes.number,
      pipelineSubscriptionAndRtu: PropTypes.shape({
        pipelineSubscriptionProd: PropTypes.shape({
          totalAmountOfPipelineSubscriptionAllowedProd: PropTypes.number,
          pipelineSubscriptionUsageProd: PropTypes.number,
          pipelineSubscriptionAddProd: PropTypes.number,
          availableAmountOfPipelineSubscriptionProd: PropTypes.number
        }),
        pipelineSubscriptionTest: PropTypes.shape({
          totalAmountOfPipelineSubscriptionAllowedTest: PropTypes.number,
          pipelineSubscriptionUsageTest: PropTypes.number,
          pipelineSubscriptionAddTest: PropTypes.number,
          availableAmountOfPipelineSubscriptionTest: PropTypes.number
        }),
        rtuProd: PropTypes.shape({
          totalRtuAllowedProd: PropTypes.number,
          totalRtuUsageProd: PropTypes.number,
          totalRtuAvailableProd: PropTypes.number,
          totalRtuAddProd: PropTypes.number
        }),
        rtuTest: PropTypes.shape({
          totalRtuAllowedTest: PropTypes.number,
          totalRtuUsageTest: PropTypes.number,
          totalRtuAvailableTest: PropTypes.number,
          totalRtuAddTest: PropTypes.number
        })
      })
    }).isRequired
  }).isRequired,
  isRedeploy: PropTypes.bool.isRequired,
  folderPathView: PropTypes.bool.isRequired,
  triggers: PropTypes.objectOf({}).isRequired
};

export default DeployPipeline;
