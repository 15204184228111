import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const application = {
  find: realm =>
    clientApollo.query({
      query: gql`
        query application($realm: String!) {
          environments(realm: $realm) {
            id
            name
          }
          realms {
            id
            companyName
            name
          }
        }
      `,
      variables: {
        realm
      }
    }),
  getPermissions: ({ realm, environmentCapable }) =>
    clientApollo.query({
      query: gql`
        query permissions($realm: String!, $environmentCapable: Boolean) {
          permissions(realm: $realm, environmentCapable: $environmentCapable)
        }
      `,
      variables: {
        realm,
        environmentCapable
      }
    }),
  getPlatformParameter: ({ realm, name }) =>
    clientApollo.query({
      query: gql`
        query platformParameters($realm: String!, $name: String!) {
          platformParameters(realm: $realm, name: $name)
        }
      `,
      variables: {
        realm,
        name
      }
    }),
  updatePlatformParameter: ({ realm, parameter, value, especific = false }) =>
    clientApollo.mutate({
      mutation: gql`
        mutation updatePlatformParameter($realm: String!, $parameter: String!, $value: JSON, $especific: Boolean) {
          updatePlatformParameter(realm: $realm, parameter: $parameter, value: $value, especific: $especific)
        }
      `,
      variables: {
        realm,
        parameter,
        value,
        especific
      }
    }),
    getTogaiToken: (email) =>
      clientApollo.mutate({
        mutation: gql`
          mutation getTogaiToken($email: String!) {
            getTogaiToken(email: $email)
          }
        `,
        variables: {
          email
        }
      }),
};

export default application;
