import { useContext } from 'react';
import { ConfirmDialogContext } from '../contexts/confirmDialogContext';
import { ConfirmDialogConfig } from '../type/ConfirmDialog';

export default function useConfirmDialog() {
  const confirmDialog = useContext(ConfirmDialogContext);

  function config(config: ConfirmDialogConfig) {
    confirmDialog.setConfig(config);
  }

  function show(config?: ConfirmDialogConfig) {
    if (config) confirmDialog.setConfig(config);
    confirmDialog.setOpen(true);
  }

  function hide() {
    confirmDialog.setOpen(false);
  }

  return {
    config,
    show,
    hide
  };
}
