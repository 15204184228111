import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';

import apiLicenseStatus from '~/api/licenseStatus';
import apiRun from '~/api/runtime';
// Locals
import i18n from '~/common/helpers/i18n';
import WebAnalytics from '~/common/helpers/webAnalytics';
import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';
import useAcls from '~/common/hooks/useAcls';
import { useHeader } from '~/components/HeaderProvider';

const mapStateToProps = ({
  application,
  runtime,
  pipeline,
  acls,
  project,
  authentication,
  license
}) => ({
  loading: runtime.loading,
  loadingSearch: runtime.loadingSearch,
  error: runtime.error,
  errorFind: runtime.errorFind,
  loadingCreate: runtime.loadingCreate,
  activeRealm: application.activeRealm,
  realms: application.realms,
  environment: application.environment,
  env: runtime.env,
  deployments: runtime.deployments,
  allIds: runtime.allIds,
  byId: runtime.byId,
  modalCreateDeploy: runtime.modalCreateDeploy,
  pipelines: pipeline.pipelines,
  projectById: project?.entities?.project,
  projectAllIds: project.result.project,
  versonMinoir: runtime.createDeploy.versonMinoir,
  multiInstances: pipeline.multiInstance.items,
  current: project.current,
  pipelineLoading: pipeline.loading,
  modalDeployment: runtime.modalDeployment,
  deploymentDetails: runtime.deploymentDetails,
  environments: application.environments.asMutable({ deep: true }),
  scopes: acls.scopes,
  search: runtime.search,
  fetchProjectLoading: project?.loading?.fetch,
  license: license.license,
  canvasVersion: runtime.canvasVersion,
  user: authentication.userData
});

const mapDispatchToProps = ({
  runtime,
  configuration,
  pipeline,
  application,
  project,
  snackbar
}) => ({
  find: runtime.find,
  setCurrentProject: project.setCurrent,
  remove: runtime.remove,
  create: runtime.create,
  showCreateDeploy: runtime.showCreateDeploy,
  showBetaCreateDeploy: runtime.showBetaCreateDeploy,
  findPipelines: pipeline.findPipelines,
  pipelineVersonMinoir: runtime.pipelineVersonMinoir,
  findConfiguration: configuration.find,
  validationDeploy: runtime.validationDeploy,
  findMultiInstance: pipeline.findMultiInstance,
  onShowModalDeployment: runtime.onShowModalDeployment,
  findEnvironments: application.findEnvironments,
  setSearch: runtime.setSearch,
  setPipeline: runtime.setPipeline,
  fetchProject: project.fetch,
  reset: runtime.reset,
  onShowModalPipelineDeployment: runtime.onShowModalPipelineDeployment,
  snackbarCreate: snackbar.create,
  loadingDeployments: runtime.loading
});

const QTDPAGE = 6;

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAcls,
  withHooks(props => {
    const [objSearch, setObjSearch] = useState(null);
    const [triggers, setTriggers] = useState([]);
    const [isOpenDetailPromote, setIsOpenDetailPromote] = useState(false);
    const [isOpenRollback, setIsOpenRollback] = useState(false);
    const [isDeleteRollback, setIsDeleteRollback] = useState(false);
    const [loadingRollback, setLoadingRollback] = useState(false);
    const [deploymentSelected, setDeploymentSelected] = useState(null);
    const [currentProject, setCurrentProject] = useState();
    const [newDeploymentsPage, setNewDeploymentsPage] = useState();
    const [currentPage, setCurrentPage] = useState(QTDPAGE);
    const [, containScopes] = useAcls();

    const time = 5;

    const [timePulling, setTimePulling] = useState(parseInt(time, 10) || 5);

    const intervalRef = useRef();
    const navigate = useNavigate();
    const actionsButton = [
      {
        icon: 'RocketLaunch',
        text: i18n.t('common.actions.deploy'),
        role: [
          'DEPLOYMENT:CREATE',
          `DEPLOYMENT:CREATE{ENV=${props.environment?.toUpperCase()}}`
        ],
        'data-testid': 'run-button-create',
        action: () => {
          props.showBetaCreateDeploy(true);
        }
      }
    ];

    const editPipelinePath = `/${props?.activeRealm}/design/pipelines`;
    const logsPath = `/${props?.activeRealm}/operation/monitor/${props.environment}/pipeline-logs`;

    useHeader(
      {
        configActionButton: actionsButton
      },
      [props.environment]
    );

    const actions = {
      changeEnvironment: environment => {
        navigate(`/${props.activeRealm}/operation/run/${environment}`);
      },
      goToPipeline: id =>
        window.open(
          `/${props.activeRealm}/design/v2/pipelines/${id}`,
          '_blank'
        ),
      createDeploy: () => {
        props.showBetaCreateDeploy(true);
      },
      redeploy: pipeline => {
        props.setPipeline(pipeline);
      }
    };
    useEffect(() => {
      async function getTriggers() {
        const { data } = await apiRun.getTriggers({ realm: props.activeRealm });
        setTriggers(data.triggers);
      }

      getTriggers();
    }, []);
    useEffect(() => () => props.reset(), {});

    async function getDeploymentPaginated(search) {
      props.loadingDeployments(true);
      const status = search?.status?.length > 0 ? search.status : [''];
      const { data } = await apiRun.findPaginated({
        realm: props.activeRealm,
        environment: props.environment,
        pipelineName: search?.name || '',
        status,
        projectName: search?.projectName || '',
        pageSize: currentPage,
        page: 0
      });

      setNewDeploymentsPage(data.deploymentsPaginated);
      props.loadingDeployments(false);
    }

    useEffect(() => {
      getDeploymentPaginated();
      props.findEnvironments({ realm: props.activeRealm });
      props.fetchProject(null, { loading: true });
    }, [props.activeRealm]);

    function changeObjSearch(newoBJ) {
      setCurrentProject(newoBJ.search.projectId);
      setObjSearch(newoBJ);
    }

    function changePulling(newTime) {
      clearInterval(intervalRef.current);
      setTimePulling(newTime);
    }

    function getNameSize(name) {
      return name.split('-')[0];
    }

    async function openDetailPromote(deployment) {
      const enviromentsOptions = [];
      props.environments.allNames
        .filter(env => env !== deployment.activeConfiguration?.environment.name)
        .forEach(env => {
          if (containScopes([`DEPLOYMENT:CREATE{ENV=${env.toUpperCase()}}`])) {
            enviromentsOptions.push({
              label: env,
              value: env
            });
          }
        });

      const env = enviromentsOptions[0]?.value;
      let licenseStatus = {};
      if (env) {
        licenseStatus = await apiLicenseStatus.fetch({
          environment: env,
          realm: props.activeRealm,
          replicas: deployment.activeConfiguration.replicaCount,
          pipelineId: deployment.pipeline.id,
          size: getNameSize(deployment.activeConfiguration.name)
        });
      }

      setDeploymentSelected({
        ...deployment,
        newEnvironment: env,
        enviromentsOptions,
        pipeline: {
          ...deployment.pipeline,
          licenseStatus
        }
      });

      setIsOpenDetailPromote(true);
    }

    function closeDetailPromotion() {
      setIsOpenDetailPromote(false);
    }

    async function getData(obj) {
      return await getDeploymentPaginated(obj.search);
    }

    async function remove(deployment) {
      await props.remove(deployment);
      await getData(objSearch);
    }

    useEffect(() => {
      const getInternalData = async () => {
        if (objSearch) {
          WebAnalytics.sendEvent('[RUN] Polling Time', {
            time: timePulling
          });
          await getData(objSearch);
          if (objSearch?.search?.projectId) {
            props.setCurrentProject(objSearch?.search?.projectId);
          }

          if (timePulling) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(
              getData,
              timePulling * 1000,
              objSearch
            );
          }
        }
      };
      getInternalData();
      return () => clearInterval(intervalRef.current);
    }, [objSearch, timePulling, currentPage]);

    useEffect(() => {
      clearInterval(intervalRef.current);
      return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
      const fetchInitial = async () => {
        const objectFind = {
          realm: props.activeRealm,
          environment: props.environment,
          search: {
            projectName: null
          }
        };
        setCurrentProject(null);
        setObjSearch(objectFind);
      };
      fetchInitial();
    }, [props.activeRealm, props.environment]);

    async function openModalRollbackDeploy(deployment) {
      setDeploymentSelected(deployment);
      const payload = {
        realm: props.activeRealm,
        deployment: deployment.id,
        environment: props.environment,
        pipelineName: deployment.pipeline.name,
        pipelineMajorVersion: deployment.pipeline.versionMajor.toString()
      };
      const { data } = await apiRun.rollbackDeployDetails(payload);

      setIsDeleteRollback(!data?.rollbackDeployDetails);
      setIsOpenRollback(true);
    }

    function closeModalRollback() {
      setIsOpenRollback(false);
    }

    async function rollbackDeploy() {
      setLoadingRollback(true);
      const payload = {
        realm: props.activeRealm,
        deployment: deploymentSelected.id,
        environment: props.environment,
        pipelineName: deploymentSelected.pipeline.name,
        pipelineMajorVersion:
          deploymentSelected.pipeline.versionMajor.toString()
      };

      try {
        await apiRun.rollbackDeploy(payload);
        setLoadingRollback(false);
        props.snackbarCreate({
          text: i18n.t('label.pipe_deploy_rollback_done_msg_success', {
            pipeName: deploymentSelected.pipeline.name
          }),
          success: true,
          open: true
        });
        await getData(objSearch);
        closeModalRollback();
      } catch (error) {
        setLoadingRollback(false);
      }
    }

    function setDefaultCurrentPage() {
      setNewDeploymentsPage([]);
      setCurrentPage(QTDPAGE);
    }

    function onInfinityScroll() {
      setCurrentPage(currentPage + QTDPAGE);
    }

    return {
      ...props,
      ...actions,
      changeObjSearch,
      changePulling,
      openDetailPromote,
      closeDetailPromotion,
      openModalRollbackDeploy,
      closeModalRollback,
      rollbackDeploy,
      remove,
      deploymentSelected,
      timePulling,
      editPipelinePath,
      logsPath,
      triggers,
      isOpenDetailPromote,
      current: currentProject,
      projectById: {
        ALL: {
          id: null,
          name: i18n.t('action.all_projects')
        },
        ...props.projectById
      },
      isOpenRollback,
      isDeleteRollback,
      loadingRollback,
      newDeploymentsPage,
      onInfinityScroll,
      setDefaultCurrentPage
    };
  })
);

export default enhancer;
