import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Locals
import ellipse from '~/common/styled/mixins/ellipsis';
import EllipsisText from '~/components/EllipsisText';
import Text from '~/components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  ${ellipse('99%')};
`;

const Separator = styled.div`
  margin: 4px;
  font-size: 10px;
  color: ${props => props.theme.colors.gra500};
`;

const Description = styled.div`
  ${ellipse('50%')};
  color: ${props => props.theme.colors.gra500};
`;

const Fieldset = ({ title, description, ...props }) => (
  <Container {...props}>
    <EllipsisText
      text={title}
      length={45}
      title={title}
      as='span'
      weight='regular'
      size='lg'
    />
    {iff(description, () => (
      <>
        <Separator>•</Separator>
        <Description>
          <Text
            title={description}
            align='left'
            as='span'
            type='secondary'
            size='lg'
          >
            {description}
          </Text>
        </Description>
      </>
    ))}
  </Container>
);

Fieldset.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default Fieldset;
