// @ts-ignore
import apiFirstImage from '~/common/images/api-first.svg';
// @ts-ignore
import nonExistentComponentImage from '~/common/images/nonexistent-component.svg';

export type Connector = {
  type?: string;
  id?: string;
  name?: string;
  title?: string;
  image: string;
  shape: 'ellipse' | 'diamond' | 'round-pentagon' | 'hexagon';
  icon?: string;
  iconURL?: string;
  jsonExample?: string;
  configExample?: Record<string, unknown>;
  catalog?: {
    name?: string;
  };
  service?: string;
  jsonSchema?: Record<string, unknown>[];
  jsonSchemaEdge?: Record<string, unknown>[];
  documentationURL?: string;
  documentation?: string;
  visual?: boolean;
  versionMajor?: string;
  versionMinor?: string;
  realmMaintainer?: {
    name?: string;
  };
  isSuggestion?: boolean;
  deprecated?: boolean;
  exists?: boolean;
  environment?: string;
  fullEndpoint?: string;
};

export type Connectors = {
  components: Connector[];
  triggers: Connector[];
  capsulesConsumers: {
    name: string;
    capsuleServices: Connector[];
  }[];
  librariesConsumers: {
    name: string;
    libraryServices: Connector[];
  }[];
};
const defaultTriggerImage =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iODBweCIgaGVpZ2h0PSI4MHB4IiB2aWV3Qm94PSIwIDAgODAgODAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+Y21wdC10cmlnZ2VyLW5vdC1jb25maWd1cmVkPC90aXRsZT4KICAgIDxnIGlkPSJ0ZW1wIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MDkuMDAwMDAwLCAtNTAzLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iY21wdC10cmlnZ2VyLW5vdC1jb25maWd1cmVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MDkuMDAwMDAwLCA1MDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLUNvcHktOCIgZmlsbD0iIzdBNzhCOCIgY3g9IjQwIiBjeT0iNDAiIHI9IjQwIj48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDxnIGlkPSJwbGF5LWNpcmNsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTUuMDAwMDAwLCAxNS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yNC44LDQ5LjYgQzM4LjUsNDkuNiA0OS42LDM4LjUgNDkuNiwyNC44IEM0OS42LDExLjEgMzguNSwwIDI0LjgsMCBDMTEuMSwwIDAsMTEuMSAwLDI0LjggQzAsMzguNSAxMS4xLDQ5LjYgMjQuOCw0OS42IFogTTMuMiwyNC44IEMzLjIsMTIuOTMgMTIuODEsMy4yIDI0LjgsMy4yIEMzNi42NywzLjIgNDYuNCwxMi44MSA0Ni40LDI0LjggQzQ2LjQsMzYuNjcgMzYuNzksNDYuNCAyNC44LDQ2LjQgQzEyLjkzLDQ2LjQgMy4yLDM2Ljc5IDMuMiwyNC44IFogTTM2LjM3LDIzIEwxOC43NywxMi4zIEMxNy4xOSwxMS40MiAxNS4yLDEyLjU1IDE1LjIsMTQuNCBMMTUuMiwzNS4yIEMxNS4yLDM3LjA0IDE3LjE4LDM4LjE4IDE4Ljc3LDM3LjMgTDM2LjM3LDI3LjIgQzM4LjAxLDI2LjI5IDM4LjAxLDIzLjkyIDM2LjM3LDIzIFoiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=';
const cache = new Map<string, Connector>();
const getConnector = (
  connectors: Connectors,
  rawType: string,
  rawName?: string
): Connector => {
  const type = rawType?.toLowerCase();
  const name = rawName?.toLowerCase().replace(/\./, '');
  const defaultTrigger: Connector = {
    name: rawName,
    type: rawType,
    shape: 'ellipse',
    image: defaultTriggerImage,
    exists: false
  };
  const nonExistentCapsule: Connector = {
    name: rawName,
    shape: 'hexagon',
    image: nonExistentComponentImage,
    type: 'capsule',
    exists: false
  };
  const nonExistentLibrary: Connector = {
    name: rawName,
    shape: 'hexagon',
    image: nonExistentComponentImage,
    type: 'library',
    exists: false
  };
  const nonExistentComponent: Connector = {
    name: rawName,
    type: rawType,
    shape: 'ellipse',
    image: nonExistentComponentImage,
    exists: false
  };

  if (type === 'trigger') {
    const trigger = connectors.triggers.find(
      trigger => trigger.name && name && trigger.name === name
    );

    if (trigger) {
      const image = trigger.iconURL
        ? trigger.iconURL
        : `data:image/png;base64,${trigger.icon}`;

      return { ...trigger, shape: 'ellipse', image, exists: true };
    }

    return defaultTrigger;
  }

  if (type === 'start-track' || type === 'starter-node') return defaultTrigger;

  if (type === 'capsule') {
    const capsules = connectors.capsulesConsumers.reduce<Connector[]>(
      (acc, capsule) => [...acc, ...capsule.capsuleServices],
      []
    );
    const foundCapsule = capsules.find(
      currentCapsule =>
        currentCapsule.name && currentCapsule.name.replace(/\./, '') === name
    );

    if (foundCapsule) {
      const capsule = {
        ...foundCapsule,
        exists: true,
        image: `"data:image/svg+xml;charset=utf-8,${foundCapsule.iconURL}"`
      };
      return capsule;
    }
    return nonExistentCapsule;
  }

  if (type === 'library') {
    const libraries = connectors.librariesConsumers.reduce<Connector[]>(
      (acc, library) => [...acc, ...library.libraryServices],
      []
    );
    const foundLibrary = libraries.find(
      currentLibrary =>
        currentLibrary.name && currentLibrary.name.replace(/\./, '') === name
    );

    if (foundLibrary) {
      const library = {
        ...foundLibrary,
        exists: true,
        image: `"data:image/png;base64,${foundLibrary.icon}"`
      };
      return library;
    }

    return nonExistentLibrary;
  }

  const component = connectors.components.find(currentComponent => {
    const jsonExample: { name?: string } = currentComponent.jsonExample
      ? JSON.parse(currentComponent.jsonExample)
      : {};
    const isNameEquals =
      currentComponent.name && currentComponent.name.toLowerCase() === name;
    const isJsonExampleNameEquals =
      jsonExample.name && jsonExample.name.toLowerCase() === name;

    if (type === 'connector') return isNameEquals || isJsonExampleNameEquals;

    const isTypeEquals =
      currentComponent.type && currentComponent.type.toLowerCase() === type;

    return isNameEquals || isTypeEquals || isJsonExampleNameEquals;
  });

  if (component && name?.startsWith('api-first:') && type === 'choice') {
    return {
      ...component,
      image: apiFirstImage || '',
      exists: true
    };
  }

  if (component)
    return { ...component, image: component.iconURL || '', exists: true };

  return nonExistentComponent;
};
const cacheResult = (id: string, func: Function) => {
  if (cache.has(id)) return cache.get(id);

  const result = func();

  cache.set(id, result);

  return result;
};

export default (
  connectors: Connectors,
  rawType: string,
  rawName?: string
): Connector => {
  const id = `${rawType}-${rawName}`;
  const func = () => getConnector(connectors, rawType, rawName);

  return cacheResult(id, func);
};
