// import { inspect } from '@xstate/inspect';
import ReactDOM from 'react-dom';
import 'reset-css/reset.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from '~/scenes/Others/scenes/App';

import '~/common/helpers/i18n';

// if (process.env.NODE_ENV !== 'production') {
//   inspect({
//     // options,
//     url: 'https://stately.ai/viz?inspect', // (default)
//     iframe: false // open in new window
//   });
// }

ReactDOM.render(<App />, document.getElementById('root'));
