import PropTypes from 'prop-types';

const defaultAcls = ['GROUP:ADMIN'];

const Role = ({ name, scopes, children }) => {
  const roles = [].concat(name, defaultAcls);
  const isAuthorized = scopes.some(scope => roles.includes(scope));
  return isAuthorized && children ? children : null;
};

Role.PropTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  scopes: PropTypes.arrayOf(PropTypes.string)
};

export default Role;
