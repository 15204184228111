import React, { useContext } from 'react';
import { Props } from './types';

import {
  Box,
  Button,
  DataGrid,
  Flex,
  Icon,
  IconButton,
  Input,
  SideSheet,
  Tag,
  Text,
  TextArea
} from '@digibee/beehive-ui';
import { Navigate, useNavigate } from 'react-router-dom';
import { DeploymentPlanContext } from '../context/DeploymentPlanContext';
import { useSelector } from 'react-redux';
import i18n from '~/common/helpers/i18n';

const CellCustom = ({ value }: { value: string }) => {
  return (
    <Text
      as='p'
      css={{
        _truncate: '$12'
      }}
    >
      {value}
    </Text>
  );
};

const CellStatus = ({ value }: { value: string }) => {
  return (
    <Tag variant={value === 'DEPLOYED' ? 'success' : 'neutral'}>
      {i18n.t(value === 'DEPLOYED' ? 'label.deployed' : 'label.not_deployed')}
    </Tag>
  );
};

const CellEnv =
  () =>
  ({ row }: any) => {
    const valueOriginal = row.original;
    return (
      <>
        <Box css={{ display: 'flex' }}>
          <Tag
            //@ts-ignore
            css={{ marginRight: '$3' }}
            variant={valueOriginal.environment === 'prod' ? 'primary' : 'info'}
          >
            {valueOriginal.environment}
          </Tag>{' '}
          {valueOriginal.version}
        </Box>
      </>
    );
  };

const CellActions =
  ({ redirect }: any) =>
  ({ row }: any) => {
    return (
      <Flex direction='row'>
        {row?.original?.pipelineId !== '' && (
          <IconButton
            onClick={() =>
              redirect(row?.original?.pipelineId, row?.original?.canvasVersion)
            }
            size='medium'
            data-testid='run-button-action-show-pipeline'
          >
            <Icon icon='external-link' size='md' variant='primary' />
          </IconButton>
        )}
      </Flex>
    );
  };

const Detail: React.FC<Props> = ({ data, redirect }) => {
  const navigate = useNavigate();
  const { planSelected } = useContext(DeploymentPlanContext);
  const { application } = useSelector((state: any) => state);

  if (!planSelected) {
    return (
      <Navigate
        to={`/${application?.activeRealm}/operation/run/deployment/deploymentPlan`}
        replace
      />
    );
  }

  const columns = [
    {
      id: 'name',
      Header: i18n.t('label.pipeline_name_vers_two'),
      accessor: 'name',
      Cell: CellCustom
    },
    {
      id: 'environment',
      Header: i18n.t('label.envrm_slash_vers'),
      accessor: 'name',
      Cell: CellEnv()
    },
    {
      id: 'status',
      Header: i18n.t('label.status'),
      accessor: 'status',
      Cell: CellStatus
    },
    {
      id: 'size',
      Header: i18n.t('label.pipeline_size'),
      accessor: 'size',
      Cell: CellCustom
    },

    {
      id: 'replica',
      Header: i18n.t('label.replicas'),
      accessor: 'replicas',
      Cell: CellCustom
    },
    {
      id: 'consumers',
      Header: i18n.t('label.concurrent_executions'),
      accessor: 'actualConsumers',
      Cell: CellCustom
    },
    {
      id: 'action',
      Header: i18n.t('label.actions'),
      accessor: 'action',
      Cell: CellActions({
        redirect
      })
    }
  ];

  return (
    <SideSheet
      open
      onClose={() => {
        navigate(-1);
      }}
      header={i18n.t('label.deployment_plan_details')}
      subHeader={i18n.t('label.deployment_details_explanation')}
      data-testid='run-sidesheet-preview'
      size='medium'
    >
      <SideSheet.Section title={i18n.t('label.deployment_plan_information')}>
        <Box css={{ marginBottom: '$3' }}>
          <Input
            label={i18n.t('label.deployment_plan_name')}
            id='name'
            name='name'
            value={planSelected.name.toString()}
            placeholder={i18n.t(
              'label.enter_deployment_plan_name_here_placeholder'
            )}
            css={{ marginBottom: '$2' }}
            disabled
          />
        </Box>
        <Box css={{ marginBottom: '$3' }}>
          <TextArea
            value={planSelected?.description}
            id='description'
            name='description'
            label={i18n.t('label.description')}
            placeholder={i18n.t('label.enter_description_placeholder')}
            disabled
          />
        </Box>
      </SideSheet.Section>

      <SideSheet.Section
        title={i18n.t('label.pipeline_specific_configuration_environ')}
      >
        <Box>
          <DataGrid
            data-testid='deploymentPlan'
            //@ts-ignore
            columns={columns}
            data={data}
            showRowsPerPage={false}
          />
        </Box>
      </SideSheet.Section>

      <SideSheet.Footer>
        <Button outlined onClick={() => navigate(-1)}>
          {i18n.t('action.cancel')}
        </Button>
      </SideSheet.Footer>
    </SideSheet>
  );
};

export default Detail;
