import React from 'react';
import * as Elements from './List.elements';
import { Outlet, useNavigate } from 'react-router';

import { Props } from './types';
import { choose, otherwise, when } from '@digibee/control-statements';
import { Box, Empty, Text } from '@digibee/beehive-ui';
import theme from '~/common/styled/theme';
import { useTranslation } from 'react-i18next';
import TableDeploymentPlan from './components/TableDeploymentPlan';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import i18n from '~/common/helpers/i18n';

const List: React.FC<Props> = ({
  deploymentsPlan,
  loading,
  executePlan,
  deleteItem,
  timePulling
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <Elements.Container>
      {timePulling && (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '$2'
          }}
        >
          <Box css={{ width: '$2' }}>
            {loading && <Elements.Loading icon={faLoader} />}
          </Box>
          <Text
            data-testid='run-time-text-pulling'
            css={{ fontSize: '$1', marginLeft: '$3' }}
          >
            {i18n.t('label.refreshing_interval_chip', {
              numberHere: timePulling
            })}
          </Text>
        </Box>
      )}
      {choose(
        when(deploymentsPlan?.totalElements === 0 && !loading, () => (
          <Empty
            illustration={theme.images.emptyStateList}
            title={t('label.you_dont_have_deployment_plans_title_empty')}
            message={t('label.you_dont_have_deployment_plans_msg_empty')}
            buttonText={t('verb.create')}
            buttonClick={() => navigate('create')}
            data-testid='accounts-empty'
          />
        )),
        otherwise(() => (
          <TableDeploymentPlan
            loading={loading}
            executePlan={executePlan}
            deleteItem={deleteItem}
          />
        ))
      )}
      <Outlet />
    </Elements.Container>
  );
};

export default List;
