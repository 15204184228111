import {
  Empty as DSEmpty,
  Skeleton as DSSkeleton,
  Text as DSText
} from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { map, filter, includes, isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CardRuntime } from './components/CardRuntime';
import TextWithIcon from './components/CardRuntime/components/TextWithIcon';
import CardsLoading from './components/CardsLoading';
import DetailsDeployment from './components/DetailsDeployment';
import DetailsPromote from './components/DetailsPromote';
import Header from './components/Header';
import ModalRollback from './components/ModalRollback';
import SideBar from './components/SideBar';
import SideBarLoading from './components/SideBarLoading';
import SideSheetDeployPipeline from './components/SideSheetDeployPipeline';
import * as Elements from './Runtime.elements';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import theme from '~/common/styled/theme';
import Box from '~/components/Box';
import Empty from '~/components/Empty';
import Fieldset from '~/components/Fieldset';
import InfiniteScroll from '~/components/InfiniteScroll';
import Skeleton from '~/components/Skeleton';
import SmallSelect from '~/components/SmallSelect';
import Text from '~/components/Text';

const tags = [
  {
    label: i18n.t('scenes.runtime.labels.upper_and_capitalize.capitalized', {
      item: `${i18n.t('scenes.runtime.labels.deployed')}`
    }),
    value: 'SERVICE_ACTIVE'
  },
  {
    label: i18n.t('scenes.runtime.labels.upper_and_capitalize.capitalized', {
      item: `${i18n.t('scenes.runtime.labels.status_error')}`
    }),
    value: 'SERVICE_ERROR'
  },
  {
    label: i18n.t('scenes.runtime.labels.upper_and_capitalize.capitalized', {
      item: `${i18n.t('scenes.runtime.labels.redeploy')}`
    }),
    value: 'REDEPLOY'
  },
  {
    label: i18n.t('scenes.runtime.labels.upper_and_capitalize.capitalized', {
      item: `${i18n.t('scenes.runtime.labels.starting')}`
    }),
    value: 'STARTING'
  }
];

const handleClickWithoutBubbling =
  (cb = () => {}) =>
  ev => {
    ev.stopPropagation();
    cb();
  };

const Runtime = ({
  environment,
  remove,
  activeRealm,
  showBetaCreateDeploy,
  changeObjSearch,
  allIds,
  byId,
  loading,
  deploymentDetails,
  onShowModalDeployment,
  environments,
  loadingCreate,
  search,
  redeploy,
  goToPipeline,
  changeEnvironment,
  changePulling,
  timePulling,
  projectById,
  projectAllIds,
  current,
  fetchProjectLoading,
  loadingSearch,
  triggers,
  editPipelinePath,
  logsPath,
  containScopes,
  license,
  canvasVersion,
  deploymentSelected,
  openDetailPromote,
  isOpenDetailPromote,
  closeDetailPromotion,
  create,
  openModalRollbackDeploy,
  closeModalRollback,
  isOpenRollback,
  isDeleteRollback,
  rollbackDeploy,
  loadingRollback,
  newDeploymentsPage,
  onInfinityScroll,
  setDefaultCurrentPage
}) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const resultNotFound = searchObject => {
    const resultStatusFilter = filter(tags, tag =>
      includes(searchObject?.status, tag.value)
    );
    const labels = map(resultStatusFilter, status => status.label);

    return `${labels.length ? `(${labels})` : ''} ${
      search.name ? ` ${labels.length ? ' - ' : ''} ${search.name}` : ''
    } `;
  };

  const createObjectDeployment = () => deploymentDetails?.deployment;

  const canCreate = !containScopes([
    'DEPLOYMENT:CREATE',
    `DEPLOYMENT:CREATE{ENV=${environment?.toUpperCase()}}`
  ]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const deployments = newDeploymentsPage?.content?.map(deployment => {
    const isStatusError = deployment.status === 'SERVICE_ERROR';

    const statusFormated =
      deployment.status.substring(0, 1) +
      deployment.status.substring(1).toLowerCase();
    return {
      ...deployment,
      status: {
        text: isStatusError ? 'Error' : statusFormated
      }
    };
  });
  const groupsProjects = deployments?.reduce((grupos, item) => {
    const { project } = item;
    const novoGrupos = grupos;
    if (!novoGrupos[project]) {
      novoGrupos[project] = [];
    }
    novoGrupos[project].push(item);
    return novoGrupos;
  }, {});

  const gruposItem = Object.fromEntries(
    Object.entries(groupsProjects || []).sort(([keyA], [keyB]) => {
      const valueA = keyA.toLowerCase();
      const valueB = keyB.toLowerCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    })
  );

  return (
    <>
      <SideSheetDeployPipeline
        realm={activeRealm}
        environment={environment}
        triggers={triggers}
        loading={loadingCreate}
        onCancel={() => showBetaCreateDeploy(false)}
        editPipelinePath={editPipelinePath}
        find={changeObjSearch}
      />

      <DetailsDeployment
        logsPath={logsPath}
        autoHeight
        redeploy={redeploy}
        triggers={triggers}
        title={`${i18n.t('scenes.runtime.labels.details_deployment')} -
          ${capitalizeFirstLetter(environment)}`}
        visible={deploymentDetails.visible}
        onCancel={() => onShowModalDeployment({ modalVisible: false, id: '' })}
        deployment={createObjectDeployment(deploymentDetails?.id)}
        editPipelinePath={editPipelinePath}
        canvasVersion={canvasVersion}
      />
      {isOpenDetailPromote && (
        <DetailsPromote
          close={closeDetailPromotion}
          enviroments={environments.allNames}
          deploymentSelected={deploymentSelected}
          realm={activeRealm}
          triggers={triggers}
          editPipelinePath={editPipelinePath}
          find={changeObjSearch}
          create={create}
          changeEnvironment={changeEnvironment}
          remove={remove}
        />
      )}
      {isOpenRollback && (
        <ModalRollback
          closeModalRollback={closeModalRollback}
          deployment={deploymentSelected}
          isDeleteRollback={isDeleteRollback}
          rollbackDeploy={rollbackDeploy}
          loadingRollback={loadingRollback}
        />
      )}
      <Elements.Content>
        <Elements.Container>
          <Elements.Top>
            {iff(environments?.allNames?.length > 0, () => (
              <SmallSelect
                options={environments?.allNames?.map(env => ({
                  label: env,
                  value: env
                }))}
                data-testid='run-environments'
                value={environment}
                onChange={env => changeEnvironment(env)}
                align='left'
              />
            ))}
          </Elements.Top>
          <Elements.ContentSideBar>
            {iff(fetchProjectLoading, () => (
              <SideBarLoading items={15} />
            ))}
            {iff(!fetchProjectLoading, () => (
              <SideBar
                current={current}
                byId={projectById}
                allIds={['ALL', ...projectAllIds]}
                onClick={projectId => {
                  setDefaultCurrentPage();
                  changeObjSearch({
                    environment,
                    realm: activeRealm,
                    search: {
                      projectId,
                      projectName: projectById[projectId]?.name
                    }
                  });
                }}
                getAll={changeObjSearch}
              />
            ))}
            <Elements.Body id='scrollable-content'>
              <Header
                status={search?.status}
                onChange={variant => {
                  changeObjSearch(
                    {
                      search: {
                        ...variant.search,
                        projectName: null
                      },
                      realm: activeRealm,
                      environment
                    },
                    { type: 'search' }
                  );
                }}
              />
              {iff(fetchProjectLoading, () => (
                <>
                  {iff(showDesignSystemComponents, () => (
                    <div style={{ maxWidth: '30%' }}>
                      <DSSkeleton
                        variant='rectangular'
                        sizes='medium'
                        style={{ width: '100%' }}
                      />
                    </div>
                  ))}
                  {iff(!showDesignSystemComponents, () => (
                    <Skeleton variant='rect' width='30%' height='16px' />
                  ))}
                </>
              ))}
              {iff(!fetchProjectLoading, () => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box>
                    {showDesignSystemComponents ? (
                      <DSText data-testid='run-project-title'>
                        {projectById[current]?.name}
                      </DSText>
                    ) : (
                      <Fieldset title={projectById[current]?.name} />
                    )}
                  </Box>
                  {timePulling && (
                    <Box display='flex'>
                      <Box width='8px'>
                        {loading && <Elements.Loading icon={faLoader} />}
                      </Box>

                      {showDesignSystemComponents ? (
                        <DSText
                          data-testid='run-time-text-pulling'
                          css={{ fontSize: '$1', marginLeft: '$3' }}
                        >
                          {i18n.t('label.refreshing_interval_chip', {
                            numberHere: timePulling
                          })}
                        </DSText>
                      ) : (
                        <TextWithIcon
                          text={i18n.t('label.refreshing_interval_chip', {
                            numberHere: timePulling
                          })}
                          data-testid='run-time-text-pulling'
                        />
                      )}
                    </Box>
                  )}

                  <Box>
                    <SmallSelect
                      iconName='SyncAlt'
                      data-testid='run-time-pulling'
                      zIndexValue={10}
                      options={[
                        {
                          label: `5 ${i18n.t('label.seconds_lowcase')}`,
                          value: 5
                        },
                        {
                          label: `10  ${i18n.t('label.seconds_lowcase')}`,
                          value: 10
                        },
                        {
                          label: `30  ${i18n.t('label.seconds_lowcase')}`,
                          value: 30
                        },
                        {
                          label: 'OFF',
                          value: null
                        }
                      ]}
                      align='left'
                      value={timePulling}
                      placeholder={i18n.t('label.auto_refresh_interval')}
                      onChange={value => {
                        changePulling(value);
                      }}
                    />
                  </Box>
                </Box>
              ))}
              {choose(
                when(loadingSearch || fetchProjectLoading, () => (
                  <CardsLoading items={15} />
                )),
                when(
                  !allIds?.length &&
                    !isEmpty(omit(search, ['projectName', 'projectId'])),
                  () => (
                    <>
                      {iff(!showDesignSystemComponents, () => (
                        <Empty
                          ilustration={theme.images.pictureNoResults}
                          title={`${i18n.t(
                            'scenes.runtime.guidance.not_found_title'
                          )} ${resultNotFound(search)}`}
                          subTitle={i18n.t(
                            'scenes.runtime.guidance.not_found_subtitle'
                          )}
                        />
                      ))}
                      {iff(showDesignSystemComponents, () => (
                        <DSEmpty
                          illustration={theme.images.pictureNoResults}
                          title={`${i18n.t(
                            'scenes.runtime.guidance.not_found_title'
                          )} ${resultNotFound(search)}`}
                          subTitle={i18n.t(
                            'scenes.runtime.guidance.not_found_subtitle'
                          )}
                          buttonText={
                            canCreate &&
                            i18n.t('scenes.runtime.actions.create_new_deploy')
                          }
                          buttonClick={() => showBetaCreateDeploy(true)}
                        />
                      ))}
                    </>
                  )
                ),
                when(newDeploymentsPage?.totalElements === 0, () => (
                  <DSEmpty
                    illustration={theme.images.emptyStateList}
                    title={i18n.t('scenes.runtime.guidance.empty_list_prompt')}
                    subTitle={
                      canCreate &&
                      i18n.t('scenes.runtime.guidance.empty_list_msg')
                    }
                    buttonText={
                      canCreate &&
                      i18n.t('scenes.runtime.actions.create_new_deploy')
                    }
                    buttonClick={() => showBetaCreateDeploy(true)}
                  />
                )),
                otherwise(() => (
                  <>
                    <InfiniteScroll
                      scrollableTarget='scrollable-content'
                      dataLength={newDeploymentsPage?.size}
                      pageStart={0}
                      style={{ overflow: 'hidden' }}
                      hasMore={!newDeploymentsPage?.last}
                      next={onInfinityScroll}
                      data-testid='run-pipeline-list'
                    >
                      {Object.keys(gruposItem).map(project => (
                        <Elements.ContainerProjectName key={project}>
                          {current === null && (
                            <>
                              {showDesignSystemComponents ? (
                                <DSText
                                  css={{
                                    fontSize: '$2',
                                    fontWeight: '$bold'
                                  }}
                                >
                                  {project}
                                </DSText>
                              ) : (
                                <Text
                                  as='span'
                                  size='lg'
                                  weight='bold'
                                  align='left'
                                >
                                  {project}
                                </Text>
                              )}
                            </>
                          )}

                          <ul>
                            <Elements.Grid items={gruposItem[project].length}>
                              {gruposItem[project].map(deployment => (
                                <Elements.GridCard key={deployment.id}>
                                  <CardRuntime
                                    onClick={() =>
                                      handleClickWithoutBubbling(
                                        onShowModalDeployment({
                                          visible: true,
                                          id: deployment.id,
                                          deployment
                                        })
                                      )
                                    }
                                    goToPipeline={goToPipeline}
                                    remove={remove}
                                    environment={environment}
                                    realm={activeRealm}
                                    deployment={deployment}
                                    licenseModel={license}
                                    redeploy={redeploy}
                                    openDetailPromote={openDetailPromote}
                                    currentProject={current}
                                    openModalRollbackDeploy={
                                      openModalRollbackDeploy
                                    }
                                  />
                                </Elements.GridCard>
                              ))}
                            </Elements.Grid>
                          </ul>
                        </Elements.ContainerProjectName>
                      ))}
                    </InfiniteScroll>
                  </>
                ))
              )}
            </Elements.Body>
          </Elements.ContentSideBar>
        </Elements.Container>
      </Elements.Content>
    </>
  );
};

Runtime.defaultProps = {
  showBetaCreateDeploy: () => {},
  onShowModalDeployment: () => {},
  goToPipeline: () => {},
  loadingSearch: () => {},
  byId: null,
  loadingCreate: false,
  allIds: [],
  environment: '',
  activeRealm: null,
  environments: null,
  deploymentDetails: null,
  projectById: null,
  projectAllIds: [],
  current: null,
  fetchProjectLoading: false,
  editPipelinePath: '',
  triggers: null,
  logsPath: '',
  search: ''
};

Runtime.propTypes = {
  byId: PropTypes.objectOf({}),
  loadingCreate: PropTypes.bool,
  allIds: PropTypes.arrayOf([]),
  environment: PropTypes.string,
  activeRealm: PropTypes.objectOf({}),
  environments: PropTypes.objectOf({}),
  loading: PropTypes.bool.isRequired,
  changeObjSearch: PropTypes.func.isRequired,
  changePulling: PropTypes.func.isRequired,
  timePulling: PropTypes.number.isRequired,
  redeploy: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onShowModalDeployment: PropTypes.func,
  deploymentDetails: PropTypes.objectOf({}),
  changeEnvironment: PropTypes.func.isRequired,
  search: PropTypes.string,
  goToPipeline: PropTypes.string,
  projectById: PropTypes.objectOf({}),
  projectAllIds: PropTypes.arrayOf([PropTypes.string]),
  current: PropTypes.string,
  fetchProjectLoading: PropTypes.bool,
  loadingSearch: PropTypes.func,
  editPipelinePath: PropTypes.string,
  triggers: PropTypes.arrayOf([]),
  logsPath: PropTypes.string,
  showBetaCreateDeploy: PropTypes.func,
  containScopes: PropTypes.func.isRequired,
  license: PropTypes.objectOf({}).isRequired,
  canvasVersion: PropTypes.number.isRequired,
  deploymentSelected: PropTypes.objectOf({}).isRequired,

  openDetailPromote: PropTypes.func.isRequired,
  isOpenDetailPromote: PropTypes.bool.isRequired,
  closeDetailPromotion: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  openModalRollbackDeploy: PropTypes.func.isRequired,
  closeModalRollback: PropTypes.func.isRequired,
  isOpenRollback: PropTypes.bool.isRequired,
  isDeleteRollback: PropTypes.objectOf({}).isRequired,
  rollbackDeploy: PropTypes.func.isRequired,
  loadingRollback: PropTypes.bool.isRequired,
  isInfiniteScroll: PropTypes.bool.isRequired,
  setDefaultCurrentPage: PropTypes.func.isRequired
};

export default Runtime;
