import { omit } from 'lodash';
import { normalize, schema } from 'normalizr';
import Immutable from 'seamless-immutable';

// Locals
import { roles } from '~/api';
import i18n from '~/common/helpers/i18n';
import normalizeError from '~/common/helpers/normalizeError';

const rolesEntity = new schema.Entity('roles');
const rolesSchema = { roles: [rolesEntity] };

const initialState = Immutable({
  result: {
    roles: []
  },
  entities: {
    roles: {}
  },
  data: [],
  pagination: {
    roles: {
      last: true,
      totalElements: null,
      totalPages: null,
      size: 10,
      number: 0,
      first: null,
      numberOfElements: null
    }
  },
  modal: {
    form: {
      visible: false
    }
  },
  loading: {
    fetch: false,
    remove: false
  },
  error: {
    fetch: null,
    remove: null
  },
  status: [],
  search: ''
});

const rolesModel = {
  name: 'roles',
  state: initialState,
  reducers: {
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          ...state.loading,
          [path]: value
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge({
        error: {
          ...state.error,
          [path]: value
        }
      });
    },
    setCreated: state => state,
    setEdited: state => state,
    setRemoved: state => state,
    setPagination(state, { path, value }) {
      return state.merge({
        pagination: {
          ...state.pagination,
          [path]: value
        }
      });
    },
    setModal(state, { path, value }) {
      return state.merge({
        modal: {
          ...state.modal,
          [path]: value
        }
      });
    },
    setPayload(state, payload) {
      return state.merge({ ...payload });
    },
    setSearch(state, search) {
      return state.merge({
        search
      });
    },
    clear: () => initialState
  },
  effects: dispatch => ({
    async fetch(params, state) {
      try {
        const request = await roles.find({
          realm: state.application.realm.realm,
          search: {
            page: state?.roles?.pagination?.roles?.number,
            size: state?.roles?.pagination?.roles?.size,
            ...state?.roles?.search,
            ...params?.search
          }
        });
        const rolesData = normalize(
          {
            roles: request?.data?.roles?.content
          },
          rolesSchema
        );

        dispatch.roles.setPagination({
          path: 'roles',
          value: omit(request?.data?.roles, ['content'])
        });

        dispatch.roles.setPayload({
          ...rolesData,
          data: request?.data?.roles?.content
        });
      } catch (e) {
        dispatch.roles.setError({ path: 'fetch', value: e });
      }
    },
    async remove({ id, explanation }, state) {
      try {
        const { realm } = state.application?.realm;
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: explanation
        });
        await roles.remove({
          realm,
          id
        });
        dispatch.roles.setRemoved({
          name: state.roles?.entities?.roles?.[id]?.name
        });
      } catch (e) {
        dispatch.roles.setError(
          {
            path: 'remove',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async create({ name, description, permissions }, { application }) {
      try {
        const { realm } = application.realm;
        await roles.create({
          realm,
          name,
          description,
          permissions
        });
        dispatch.roles.setCreated({ name });
      } catch (e) {
        dispatch.roles.setError(
          {
            path: 'create',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      }
    },
    async edit(
      { id, name, description, permissions, duplicate = false, explanation },
      { application }
    ) {
      try {
        const { realm } = application.realm;
        dispatch.dialogs.setConfirmDialogContext({
          key: 'explanation',
          value: explanation
        });
        const request = await roles.edit({
          id,
          realm,
          name,
          description,
          permissions
        });
        dispatch.roles.setEdited({ duplicate, role: request?.data?.editRole });
      } catch (e) {
        dispatch.roles.setError(
          {
            path: 'edit',
            value: e.message
          },
          {
            snackbar: {
              text: normalizeError.onGraphQL(e.message),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
      } finally {
        dispatch.dialogs.reset();
      }
    }
  }),
  logics: [
    {
      type: 'roles/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.roles.setLoading({ path: 'fetch', value: true });
        done();
      }
    },
    {
      type: 'roles/create',
      latest: true,
      process(context, dispatch, done) {
        dispatch.roles.setLoading({ path: 'create', value: true });
        done();
      }
    },
    {
      type: 'roles/edit',
      latest: true,
      process(context, dispatch, done) {
        dispatch.roles.setLoading({ path: 'edit', value: true });
        done();
      }
    },
    {
      type: ['roles/setError', 'roles/setPayload'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.roles.setLoading({ path: 'fetch', value: false });
        done();
      }
    },
    {
      type: ['roles/setSearch'],
      latest: true,
      process(context, dispatch, done) {
        dispatch.roles.fetch({ search: { page: 0 } });
        done();
      }
    },
    {
      type: ['roles/setRemoved'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.roles.fetch(
          { search: { page: 0 } },
          {
            snackbar: {
              text: i18n.t(
                'scenes.roles.messages.success.snackbar_delete_role',
                {
                  roleName: action?.payload?.name
                }
              ),
              action: {
                label: i18n.t('common.labels.ok')
              }
            }
          }
        );
        done();
      }
    },
    {
      type: ['roles/setCreated'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.roles.setLoading({ path: 'create', value: false });
        dispatch.roles.setModal({
          path: 'form',
          value: {
            visible: false
          }
        });
        dispatch.snackbar.create({
          text: i18n.t('scenes.roles.messages.success.snackbar_create_role', {
            roleName: action?.payload?.name
          }),
          action: {
            label: i18n.t('common.labels.ok')
          }
        });
        dispatch.roles.fetch({ search: { page: 0 } });
        done();
      }
    },
    {
      type: ['roles/setEdited'],
      latest: true,
      process({ action }, dispatch, done) {
        dispatch.roles.setLoading({ path: 'edit', value: false });
        dispatch.roles.setModal({
          path: 'form',
          value: {
            visible: false
          }
        });
        if (action?.payload?.duplicate) {
          const role = {
            ...action?.payload?.role,
            name: `${action?.payload?.role?.name}-copy`
          };
          dispatch.roles.setModal({
            path: 'form',
            value: {
              data: role,
              visible: true,
              create: true
            }
          });
        }
        if (!action?.payload?.duplicate) {
          dispatch.snackbar.create({
            text: i18n.t('scenes.roles.messages.success.snackbar_edit_role', {
              roleName: action?.payload?.role?.name
            }),
            action: {
              label: i18n.t('common.labels.ok')
            }
          });
        }
        dispatch.roles.fetch({ search: { page: 0 } });
        done();
      }
    },
    {
      type: ['roles/setModal'],
      latest: true,
      process({ action }, dispatch, done) {
        if (action?.meta?.entity) {
          dispatch.roles.setSelected(action?.meta?.entity);
        }
        done();
      }
    }
  ]
};

export default rolesModel;
