const generalFeatureFlags = {};

const featureFlagsComponents = {
  PLAYGROUND: 'components_playground',
  CONSUMER_BASIC_AUTH: 'components_consumer_basic_auth'
};

const featureFlagDesignSystem = {
  THEME_NEW_BRAND: 'design_system_theme_new_brand',
  SETTINGS_GLOBALS_V2: 'settings_globals_v2',
  SETTINGS_ACCOUNTS_V2: 'settings_accounts_v2',
  CAPSULES_NEW_DESIGN: 'capsules_designSystem_newCapsulesPage'
};

const featureFlagsLicense = {
  PREDICTION_BUTTON: 'subscription_project_license_prediction_button',
  USAGE_LIMITS_PAGE_LINK: 'subscription_usage_limits_page_view',
  USAGE_LIMITS_DETAIL_PAGE: 'subscription_usage_limits_internal_page_view',
  UPSELL_ALERT: 'subscription_license_checkout_modal'
};

const featureFlagsIdentityAccess = {
  ENABLE_IDP_ACTIVATION_RULE:
    'identityAccess_samlGroupMapping_enableActivationRule',
  RECAPTCHA_TEMPORARY_LOCKING:
    'identityAccess_nativeLogin_enableTemporaryLocked',
  SETTINGS_ACCOUNTS_NEW_DS: 'settings_accoutns_new_ds'
};

const featureFlagsGovernance = {
  GOVERNANCE_DS_MIGRATION_LICENSE_USAGE:
    'governance-ds-migration-license_usage',
  GOVERNANCE_DS_MIGRATION_ROLES: 'governance-ds-migration-roles',
  GOVERNANCE_DS_MIGRATION_USERS: 'governance-ds-migration-users',
  GOVERNANCE_ENABLE_SUPPORT_ACCESS: 'governance-enable-support-access',
  GOVERNANCE_ENABLE_POLICY_MANAGEMENT_SYSTEM:
    'governance-policy-management-system',
  GOVERNANCE_DS_MIGRATION_GROUPS: 'governance-ds-migration-groups',
  GOVERNANCE_DS_MIGRATION_CONSUMER_APIS:
    'governance-ds-migration-consumer-apis',
  GOVERNANCE_ENABLE_EXPORT_AUDIT: 'governance-audit-export-button',
  GOVERNANCE_DS_MIGRATION_MULTI_INSTANCE:
    'governance-ds-migration-multi-instance',
  GOVERNANCE_DS_MIGRATION_LOGIN_PAGES: 'governance-ds-migration-login-pages',
  GOVERNANCE_NEW_LOGIN_PAGE: 'governance-new-login-page',
  GOVERNANCE_NEW_THEME_LOGIN_PAGE: 'governance-new-theme-login-page',
  GOVERNANCE_NEW_LICENSE_PAGE: 'governance-new-license-page'
};

const featureFlagsRun = {
  RUN_ENABLE_DEPLOYMENTPLAN: 'run_enable_deploymentplan',
  RUN_LIST_DESIGNSYSTEM: 'run_list_designsystem',
  RUN_CHANGE_FETCHAPI: 'run_change_fetchapi',
  RUN_POOLING_TIMER_DEPLOYMENTPLAN: 'run_pooling_timer_deploymentPlan',
  RUN_ENABLE_3DOTS_HISTORY: 'run_enable_3dots_history',
  RUN_ENABLE_LICENSEV2: 'run_enable_licensev2',
  RUN_ROUTES_PARAMS: 'run_routes_params',
  RUN_CORE_ENGINE_DEPLOY_KEDA: 'CORE_ENGINE_DEPLOY_KEDA',
  RUN_SET_ENGINE_VERSION: 'run_set_engine_version',
  RUN_PIPELINE_AUTOSCALING_BY_TRIGGER: 'run_pipeline_autoscaling_by_trigger',
  RUN_WITH_RUNNING_REPLICAS: 'run_with_running_replicas'
};

const featureFlagsMonitor = {
  MONITOR_SETDEFAULTENVIRONMENT_FRONT: 'monitor_setdefaultenvironment_front',
  MONITOR_OVERVIEW_PAGINATION_ORDER: 'monitor_overview_pagination_order',
  MONITOR_GET_STATS_ALL: 'monitor_get_stats_all'
};

const featureFlagsBuild = {
  BUILD_CANVAS_SCENE_DESIGN_SYSTEM_MIGRATION:
    'build_canvas_scene_design_system_migration',
  BUILD_CANVAS_AI_DOCUMENTATION: 'build_canvas_ai_documentation',
  BUILD_SYNTAX_HIGHLIGHTING: 'build_syntax_highlighting',
  CANVAS_DESIGN_MIGRATION_ENABLED: 'build_canvas_design_migration_enabled',
  BUILD_FANTA_FORM_DS_MIGRATION: 'build_fanta_form_ds_migration',
  BUILD_CANVAS_AI_COPILOT: 'build_canvas_ai_copilot',
  BUILD_CANVAS_AUTOCOMPLETE: 'build_canvas_autocomplete',
  BUILD_LINT_ITEM: 'build_lint_item',
  BUILD_TEST_MODE_SCALE_TO_ZERO: 'build_test_mode_scale_to_zero',
  SMART_CONNECTOR: 'smart_connector',
  SMART_CONNECTOR_AI_SUGGESTIONS: 'smart_connector_ai_suggestions',
  SMART_CONNECTOR_ENDPOINTS: 'smart_connector_endpoints',
  BUILD_ITEM_DBV2: 'build_item_dbv2',
  SMART_CONNECTOR_ADD_STEP: 'smart_connector_add_step',
  BUILD_API_FIRST: 'build_api_first'
};

const featureFlagsAI = {
  AI_CAPSULE_DOCUMENTATION: 'ai_capsule_documentation'
};

const featureFlagsCapsules = {
  CAPSULE_ALLOW_UPDATE_TO_PUBLIC:
    'capsules_capsuleCollection_changeCapsuleCollectionToPublic',
  CAPSULE_API_V2: 'capsule_api_v2',
  CAPSULE_IGNORE_OUTSPEC_SCHEMA:
    'capsules_validateOutSpecSchema_ignoreValidation',
  CAPSULE_NEW_FORM_SETTINGS: 'capsule_new_form_settings',
  CAPSULES_CANVAS_MIGRATION: 'capsules_canvas_migration',
  CAPSULE_API_V2_GROUPS: 'capsule_api_v2_groups',
  CAPSULES_CANVAS_SAVE_FORM_NAME_FIELD: 'capsules_canvas_save_form_name_field',
  CAPSULE_SAVE_SETTINGS_ALERT: 'capsule_save_settings_alert'
};

const featureFlagsConnectivity = {
  CONNECTIVITY_ZTNA_MVP_ENABLED_FRONT: 'connectivity_ztna_mvp_enabled_front',
  CONNECTIVITY_ZTNA_MVP_CREATE_CONNECTION_ENABLED_FRONT:
    'connectivity_ztna_mvp_create_connection_enabled_front'
};

const featureFlagConstants = {
  ...generalFeatureFlags,
  ...featureFlagsBuild,
  ...featureFlagsAI,
  ...featureFlagsLicense,
  ...featureFlagsIdentityAccess,
  ...featureFlagsRun,
  ...featureFlagsMonitor,
  ...featureFlagDesignSystem,
  ...featureFlagsComponents,
  ...featureFlagsGovernance,
  ...featureFlagsCapsules,
  ...featureFlagsConnectivity
};

export default featureFlagConstants;
