import { Text as TextV2 } from '@digibee/beehive-ui';
import React from 'react';
import styled, { css } from 'styled-components';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import ellipsis from '~/common/styled/mixins/ellipsis';
import Nav from '~/components/SideNav';
import TextNative from '~/components/Text';

const Text = styled(TextNative)<{ actived?: boolean }>`
  ${props =>
    props.actived
      ? css`
          font-weight: 500;
          color: ${props.theme.colors.pri500};
        `
      : ''};
`;

interface NavProps {
  isDragging: boolean;
}

// @ts-ignore
const NavItem = styled(Nav.Item)<NavProps>`
  ${props =>
    props.isDragging
      ? css`
          cursor: grabbing !important;
          background: ${props.theme.colors.white} !important;
        `
      : ''}
`;

const WrapperText = styled.div`
  display: flex;
  align-items: center;
`;

interface TitleProps {
  align?: string;
  as: string;
  'data-tip'?: string;
  actived: boolean;
  children: any;
}

const Title = styled(Text)<TitleProps>`
  ${ellipsis('160px')};
  padding-right: 4px;
  padding-left: 8px;
  line-height: 20px;
`;

interface ContentProps {
  isDragging?: boolean;
  actived: boolean;
  isDraggingOver?: boolean;
  children: any;
}

const Content = styled.div<ContentProps>`
  width: 192px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  ${props =>
    !props.isDragging
      ? css`
          &:hover {
            background: ${props.theme.colors.pri25};
          }
        `
      : ''}
  ${props =>
    props.actived
      ? css`
          background: ${props.theme.colors.pri25};
        `
      : ''};

  ${props =>
    props.isDraggingOver
      ? css`
          border: 1px solid ${props.theme.colors.yel900};
          background: ${props.theme.colors.yel200};
        `
      : ''}
`;

interface ProjectProps {
  current: string;
  id: string;
  name: string;
  fetchComponents: () => void;
  resetSearch: () => void;
  onClick: (id: string) => void;
  draggingId: string;
}

const Project: React.FC<ProjectProps> = ({
  current,
  id,
  name,
  onClick,
  draggingId
}) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  return (
    <>
      <NavItem
        isDragging={!!draggingId}
        key={id}
        onClick={() => onClick?.(id)}
        data-testid={`run-project-item-${name}`}
      >
        <Content actived={id === current}>
          <WrapperText>
            {showDesignSystemComponents ? (
              <TextV2
                css={{
                  paddingRight: '$1',
                  paddingLeft: '$2',
                  lineHeight: '$3',
                  fontWeight: id === current ? '$medium' : '300',
                  color: id === current ? '$pri500' : '$black'
                }}
                variant='b3'
              >
                {name}
              </TextV2>
            ) : (
              <Title
                align='left'
                as='span'
                data-tip={name}
                actived={id === current}
              >
                {name}
              </Title>
            )}
          </WrapperText>
        </Content>
      </NavItem>
    </>
  );
};

export default Project;
