import i18n from '~/common/helpers/i18n';
import asyncComponent from '~/components/AsyncComponent';
import { ConfirmDialogProvider } from '~/components/ConfirmDialogV2/contexts/confirmDialogProvider';
import Operation from '~/scenes/Others/scenes/App/Operation';

const Capsule = asyncComponent(() => import('~/scenes/Build/scenes/Capsule'));
const Capsules = asyncComponent(() => import('~/scenes/Build/scenes/Capsules'));
const HistoryPipeline = asyncComponent(
  () => import('~/scenes/Build/scenes/PipelineHistory')
);
const DesignPipeline = asyncComponent(
  () => import('~/scenes/Build/scenes/DesignPipeline')
);
const Pipelines = asyncComponent(
  () => import('~/scenes/Build/scenes/Pipelines')
);
const CapsuleHeader = asyncComponent(
  () => import('~/scenes/Build/scenes/CapsuleHeader')
);

const buildSettingsRoutes = [
  {
    path: 'capsules/headers',
    name: 'settings-capsules-headers',
    title: i18n.t('label.capsule_headers_tab'),
    element: <CapsuleHeader />
  }
];

const BuildRoutes = () => [
  {
    path: '/:realm/design/pipelines/:pipelineId',
    name: 'build-design-pipeline',
    title: i18n.t('label.pipelines_tab'),
    element: <DesignPipeline />
  },
  {
    path: '/:realm/design/v2/pipelines/:pipelineId',
    name: 'build-design-pipeline',
    title: i18n.t('label.pipelines_tab'),
    element: <DesignPipeline />
  },
  {
    path: '/:realm/design/capsules/:capsuleId',
    name: 'build-design-capsule',
    title: i18n.t('label.capsules_tab'),
    element: (
      <ConfirmDialogProvider>
        <Capsule />
      </ConfirmDialogProvider>
    )
  },
  {
    path: '/:realm/design/v2/capsules/:capsuleId',
    name: 'build-design-capsule',
    title: i18n.t('label.capsules_tab'),
    element: (
      <ConfirmDialogProvider>
        <Capsule />
      </ConfirmDialogProvider>
    )
  },
  {
    path: '/:realm/design/pipelines/history/:pipelineId',
    name: 'build-pipeline-history',
    title: i18n.t('label.pipeline_history_tab'),
    element: <DesignPipeline />
  },
  {
    path: '/:realm/operation/build',
    element: (
      <Operation
        links={[
          {
            roles: ['PIPELINE:READ'],
            'data-testid': 'build-pipeline-tab',
            routeName: 'build-pipelines',
            path: 'operation/build/pipelines',
            name: i18n.t(
              'scenes.beta.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('common.labels.pipeline_plural')}`
              }
            )
          },
          {
            roles: ['CAPSULE:READ'],
            'data-testid': 'build-capsule-tab',
            routeName: 'build-capsules',
            path: 'operation/build/capsules',
            name: i18n.t(
              'scenes.beta.labels.upper_and_capitalize.capitalized',
              {
                item: `${i18n.t('scenes.beta.labels.capsule_plural')}`
              }
            )
          }
        ]}
      />
    ),
    children: [
      {
        path: 'pipelines',
        name: 'build-pipelines',
        title: i18n.t('label.pipelines_tab'),
        element: <Pipelines />,
        children: [
          {
            path: ':name/:versionMajor/history',
            title: i18n.t('label.pipeline_history_tab'),
            element: <HistoryPipeline />
          }
        ]
      },
      {
        path: 'capsules',
        name: 'build-capsules',
        title: i18n.t('label.capsules_tab'),
        element: (
          <ConfirmDialogProvider>
            <Capsules />
          </ConfirmDialogProvider>
        )
      }
    ]
  }
];

export default BuildRoutes;

export { buildSettingsRoutes };
