import queryString from 'querystring';

import { omit, pickBy, identity } from 'lodash';
import moment from 'moment';
import { normalize, schema } from 'normalizr';
import { createSearchParams } from 'react-router-dom';
import Immutable from 'seamless-immutable';

import audit from '~/api/audit';

const normalizeData = ({ entity, data }) => {
  const pipelinesSchema = new schema.Entity(entity);
  const mySchema = [pipelinesSchema];
  return normalize(data, mySchema);
};

const getPropsSearch = () => {
  const params = queryString.parse(window.location.search.replace('?', ''));
  const STRING_EMPTY = '';
  const TIME_TYPE_DEFAULT = '5 Minutes';
  const SPECIFIC_DATE_FROM_DEFAULT = moment();
  return {
    email: params.email || '',
    description: params.description || STRING_EMPTY,
    time: params.time || TIME_TYPE_DEFAULT,
    method: params.method || STRING_EMPTY,
    service: params.service || STRING_EMPTY,
    objectId: params.objectId || STRING_EMPTY,
    status: params.status || STRING_EMPTY,
    specific: {
      dateFrom: moment(params.dateFrom) || SPECIFIC_DATE_FROM_DEFAULT,
      dateTo: moment(params.dateTo) || SPECIFIC_DATE_FROM_DEFAULT
    }
  };
};

const transformFormatDate = (values, params) =>
  moment().subtract(values, params).toISOString();

const initialState = Immutable({
  result: {
    audits: []
  },
  entities: {
    audits: {}
  },
  data: [],
  loading: {
    audits: false
  },
  error: {
    audits: null
  },
  last: false,
  first: true,
  numberOfElements: 0,
  size: 0,
  search: getPropsSearch(),
  totalPages: 0,
  number: 0,
  totalElements: 0
});

const audits = {
  name: 'audits',
  state: initialState,
  reducers: {
    setResultAndEntities(state, { result, entities, data, ...props }) {
      return state.merge(
        { result: { audits: result }, entities, data, ...props },
        { deep: true }
      );
    },
    setData(state, data) {
      return state.merge({ data });
    },
    setLoading(state, { path, value }) {
      return state.merge({
        loading: {
          [path]: value
        }
      });
    },
    setSearch(state, params) {
      return state.merge({ search: params });
    },
    clearResult(state) {
      return state.merge({
        result: {
          audits: []
        },
        entities: {
          audits: []
        }
      });
    },
    setError(state, { path, value }) {
      return state.merge(
        {
          error: {
            [path]: value
          }
        },
        {
          deep: true
        }
      );
    },
    clearSearch(state) {
      return state.merge(
        {
          search: getPropsSearch()
        },
        { deep: true }
      );
    }
  },
  effects: dispatch => ({
    async fetch(params, { application }) {
      try {
        const { realm } = application.realm;
        const mapTimes = {
          '5 Minutes': {
            dateFrom: transformFormatDate(5, 'minutes'),
            dateTo: moment().toISOString()
          },
          '15 Minutes': {
            dateFrom: transformFormatDate(15, 'minutes'),
            dateTo: moment().toISOString()
          },
          '1 Hour': {
            dateFrom: transformFormatDate(1, 'hour'),
            dateTo: moment().toISOString()
          }
        };
        const getMapTimes = (type = '5 Minutes', dates) => {
          const defaultTime = {
            dateFrom: moment(dates?.dateFrom).milliseconds(0).toISOString(),
            dateTo: moment(dates?.dateTo).toISOString()
          };

          return mapTimes[type] || defaultTime;
        };
        const specific = getMapTimes(
          params.isQueryParams ? getPropsSearch().time : params?.search?.time,
          params.isQueryParams
            ? getPropsSearch().specific
            : params?.search?.specific
        );
        const paramsSearch = params.isQueryParams
          ? getPropsSearch()
          : params?.search;

        const actionsDictionary = {
          GET: 'GET',
          POST: 'POST',
          PUT: 'PUT',
          PATCH: 'PATCH',
          DELETE: 'DELETE',
          VIEWED: 'GET',
          CREATED: 'POST',
          UPDATED: 'PUT',
          'REMOVED/ARCHIVE': 'DELETE'
        };
        const propsFind = {
          ...paramsSearch,
          ...specific,
          method: actionsDictionary[paramsSearch.method?.toUpperCase()] || '',
          realm,
          page: params?.page
        };

        window.history.replaceState(null, null, `?${createSearchParams({
          ...omit(
            pickBy({ ...paramsSearch, ...specific }, identity),
            'specific'
          )
        })}`);

        const { data } = await audit.find(propsFind);
        const auditsData = normalizeData({
          entity: 'audits',
          data: data.audit.content
        });
        dispatch.audits.setResultAndEntities(
          {
            ...auditsData,
            ...omit(data.audit, ['content']),
            data: data?.audit?.content
          },
          {
            ...paramsSearch,
            ...specific
          }
        );
      } catch (e) {
        dispatch.audits.setError({
          path: 'audits',
          value: e
        });
      }
    }
  }),
  logics: [
    {
      type: 'audits/fetch',
      latest: true,
      process(context, dispatch, done) {
        dispatch.audits.setLoading({ path: 'audits', value: true });
        done();
      }
    },
    {
      type: 'audits/setError',
      latest: true,
      process(context, dispatch, done) {
        dispatch.audits.setLoading({ path: 'audits', value: false });
        done();
      }
    },
    {
      type: ['audits/setResultAndEntities'],
      latest: true,
      process(context, dispatch, done) {
        const { meta } = context.action;
        dispatch.audits.setSearch(meta);
        dispatch.audits.setError({ path: 'audits', value: null });
        done();
      }
    }
  ]
};

export default audits;
