import {
  faQuestion,
  faWarning,
  faTrash,
  faCircleCheck
} from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';

import { type Deployment } from '.';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import ConfirmDialog from '~/components/ConfirmDialogV2';

export const DEPLOY_STATUS = {
  STARTING: 'Starting',
  DELETING: 'Deleting',
  DEPLOYED: 'Deployed',
  ERROR: 'Error',
  DEGRADED: 'degraded'
};

const getMessage = (deployment: Deployment, theme: Record<string, any>) => {
  const types = {
    error: {
      border: theme?.colors?.red900
    },
    success: {
      border: theme?.colors?.gre600
    },
    starting: {
      border: theme?.colors?.blu100
    },
    deleting: {
      border: theme?.colors?.gra600
    }
  };

  if (!deployment?.status?.text) return '';
  switch (deployment?.status?.text) {
    case DEPLOY_STATUS.DELETING:
      return {
        text: i18n.t('label.deleting'),
        icon: faTrash,
        style: types.deleting
      };
    case DEPLOY_STATUS.STARTING:
      return {
        text: i18n.t('label.starting'),
        icon: null,
        loader: true,
        style: types.starting
      };
    case DEPLOY_STATUS.DEGRADED:
      return {
        text: i18n.t('label.starting'),
        icon: faQuestion,
        style: types.starting
      };

    case DEPLOY_STATUS.ERROR:
      return {
        text: i18n.t('label.error'),
        style: types.error,
        icon: faWarning
      };
    case DEPLOY_STATUS.DEPLOYED:
      return {
        text: i18n.t('label.deployed'),
        style: types.success,
        icon: faCircleCheck
      };
    default:
      return {
        text: i18n.t('label.deployed'),
        style: types.success,
        icon: faCircleCheck
      };
  }
};
const isDisabled = (status: string) => {
  if (!status) return false;
  return [DEPLOY_STATUS.STARTING, DEPLOY_STATUS.DELETING].includes(status);
};

type Props = {
  theme: Record<string, any>;
  deployment: Deployment;
  environment: string;
};

const useEnhancer = ({ theme, deployment, environment }: Props) => {
  const confirmDialog = ConfirmDialog.useConfirmDialog();
  const [disabled, setDisabled] = useState(
    isDisabled(deployment?.status?.text)
  );

  useEffect(() => {
    setDisabled(isDisabled(deployment?.status?.text));
  }, [deployment]);

  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;

  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);

  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const dataMessage = getMessage(deployment, theme);

  const isRunMovePipelineShow = environment !== 'prod';

  const newEnvironment = environment === 'test' ? 'prod' : 'test';

  const promoteValue = i18n.t(`action.move_to_${newEnvironment}`);

  const state = {
    disabled
  };

  return {
    ...state,
    confirmDialog,
    dataMessage,
    isRunMovePipelineShow,
    newEnvironment,
    promoteValue,
    showDesignSystemComponents
  };
};

export default useEnhancer;
