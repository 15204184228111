import {
  Avatar,
  Box,
  DataGrid,
  Flex,
  Icon,
  IconButton,
  Menu,
  Tag,
  Text,
  Tooltip
} from '@digibee/beehive-ui';
import React, { useContext, useMemo } from 'react';
import i18n from '~/common/helpers/i18n';
import styled from 'styled-components';
import moment from 'moment';
import { faUpFromDottedLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeploymentPlanContext } from '../../context/DeploymentPlanContext';
import { useNavigate } from 'react-router';

interface TableDeploymentPlanProps {
  loading: boolean;
  executePlan: (item: any) => void;
  deleteItem: (item: any) => void;
}

export const Footer = styled.div`
  margin: 34px 0px;
`;

const CellCustom = ({ value }: { value: string }) => {
  return (
    <Text
      as='p'
      css={{
        _truncate: '$12'
      }}
    >
      {value}
    </Text>
  );
};

const CellAvatar = ({ value }: { value: string }) => {
  return (
    <Box>
      <Tooltip
        //@ts-ignore
        position='center'
        side='left'
        content={<>{value}</>}
      >
        <a>
          <Box css={{ display: 'flex' }}>
            <Avatar text={value} />
          </Box>
        </a>
      </Tooltip>
    </Box>
  );
};

const CellData = ({ value }: { value: string }) => {
  const normalizeTimeStamp = (date: string) =>
    new Date(Number(date.padEnd(13, '0')));
  return (
    <Text
      as='p'
      css={{
        _truncate: '$12'
      }}
    >
      {moment(normalizeTimeStamp(value)).format('MM/DD/YYYY HH:mm:ss')}
    </Text>
  );
};

const CellPipelines =
  () =>
  ({ row }: any) => {
    const valueOriginal = row.original;
    return (
      <>
        <Box>
          <Tooltip
            //@ts-ignore
            position='center'
            side='left'
            content={
              <>
                {valueOriginal?.deploymentPlanItems.map((item: any) => (
                  <Text
                    as='p'
                    key={item?.pipelineMajorVersion.name}
                    color='secondary'
                  >
                    {item?.pipelineMajorVersion.name} - V
                    {item?.pipelineMajorVersion.majorVersion}
                  </Text>
                ))}
              </>
            }
          >
            <a>
              <Box css={{ display: 'flex' }}>
                {valueOriginal?.deploymentPlanItems.length}
              </Box>
            </a>
          </Tooltip>
        </Box>
      </>
    );
  };

const CellEnv = ({ value }: { value: string[] }) => {
  return (
    <Box css={{ display: 'flex' }}>
      {value.length > 0 ? (
        <>
          {value.map(env => (
            <Tag
              key={env}
              //@ts-ignore
              css={{ marginRight: '$2' }}
              variant={env === 'prod' ? 'primary' : 'info'}
            >
              {env}
            </Tag>
          ))}
        </>
      ) : (
        <Tag
          //@ts-ignore
          css={{ marginRight: '$2' }}
          variant='neutral'
        >
          {i18n.t('label.no_envrm')}
        </Tag>
      )}
    </Box>
  );
};

const CellStatus =
  () =>
  ({ row }: any) => {
    const valueOriginal = row.original;
    const isDeployed = valueOriginal.currentEnvironments.length > 0;
    return (
      <Box css={{ display: 'flex' }}>
        <Tag variant={isDeployed ? 'success' : 'neutral'}>
          {i18n.t(isDeployed ? 'label.deployed' : 'label.not_deployed')}
        </Tag>
      </Box>
    );
  };

const CellActions =
  ({ deleteItem, executePlan, selectPlanDetail }: any) =>
  ({ row }: any) => {
    const valueOriginal = row.original;

    const isPipelineDeployed = valueOriginal.currentEnvironments.length > 0;

    return (
      <Flex direction='row'>
        <IconButton
          onClick={() => selectPlanDetail(valueOriginal)}
          size='medium'
          data-testid='run-button-action-show-pipeline'
        >
          <Icon icon='eye' size='md' variant='primary' />
        </IconButton>

        <Menu.Root>
          {/* @ts-ignore */}
          <Menu.Content
            componentTrigger={
              <IconButton size='medium'>
                <Icon icon='ellipsis-v' size='md' variant='primary' />
              </IconButton>
            }
            css={{ zIndex: 50 }}
          >
            <Menu.Item
              size='large'
              data-testid='run-card-action-detail'
              onClick={() => executePlan(valueOriginal, 'test')}
              css={{ fontSize: '$1' }}
            >
              <div style={{ marginRight: '10px' }}>
                <Icon icon='project-diagram' size='md' />
              </div>

              {i18n.t('action.deploy')}
            </Menu.Item>
            {isPipelineDeployed && (
              <Menu.Item
                size='large'
                data-testid='run-card-action-promote'
                onClick={() => executePlan(valueOriginal, 'prod')}
                css={{ fontSize: '$1' }}
              >
                <div style={{ marginRight: '10px', fontSize: 16 }}>
                  <FontAwesomeIcon icon={faUpFromDottedLine} />
                </div>
                {i18n.t('action.promote_to_environment')}
              </Menu.Item>
            )}

            <Menu.Item
              size='large'
              data-testid='run-card-action-delete'
              css={{ fontSize: '$1', paddingRight: '30px' }}
              onClick={() => deleteItem(valueOriginal.id, valueOriginal.name)}
            >
              <div style={{ marginRight: '10px' }}>
                <Icon icon='trash-alt' size='md' />
              </div>
              {i18n.t('action.delete_deployment_plan')}
            </Menu.Item>
          </Menu.Content>
        </Menu.Root>
      </Flex>
    );
  };

const TableDeploymentPlan: React.FC<TableDeploymentPlanProps> = ({
  loading,
  executePlan,
  deleteItem
}) => {
  const { deploymentsPlan, selectPlan, changePage } = useContext(
    DeploymentPlanContext
  );
  const navigate = useNavigate();

  function selectPlanDetail(plan: any) {
    selectPlan(plan);
    navigate('detail');
  }

  const columns = [
    {
      id: 'name',
      Header: i18n.t('label.deployment_plan_name'),
      accessor: 'name',
      Cell: CellCustom
    },
    {
      id: 'createdAt',
      Header: i18n.t('label.creation_date'),
      accessor: 'createdAt',
      Cell: CellData
    },
    {
      id: 'createdBy',
      Header: i18n.t('label.created_by'),
      accessor: 'createdBy.email',
      Cell: CellAvatar
    },
    {
      id: 'totalPipe',
      Header: i18n.t('label.number_of_pipes-1'),
      accessor: 'totalPipe',
      Cell: CellPipelines()
    },
    {
      id: 'currentEnvironments',
      Header: i18n.t('label.current_envrm'),
      accessor: 'currentEnvironments',
      Cell: CellEnv
    },
    {
      id: 'status',
      Header: i18n.t('label.general_status'),
      accessor: 'status',
      Cell: CellStatus()
    },
    {
      id: 'action',
      Header: i18n.t('label.actions'),
      accessor: 'action',
      Cell: CellActions({
        deleteItem,
        executePlan,
        selectPlanDetail
      })
    }
  ];

  return (
    <Box>
      <DataGrid
        data-testid='deploymentPlan'
        loading={loading}
        //@ts-ignore
        columns={columns}
        data={deploymentsPlan?.content || []}
        totalElements={deploymentsPlan?.totalElements || 0}
        //@ts-ignore
        fetchData={(e: Page) => {
          if (deploymentsPlan?.number !== e.pageIndex) {
            changePage(e.pageIndex);
          }
          return;
        }}
        pageCount={deploymentsPlan?.totalPages}
        page={0}
        rowsPerPageOptions={[20]}
        isPagination
        manualPagination
        initialState={{
          pageIndex: 0,
          pageSize: 10
        }}
        showRowsPerPage={false}
      />
    </Box>
  );
};

export default TableDeploymentPlan;
