/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import spacing from './spacing';

const aliasResolverMap = new Map([
  ['marginX', ['marginLeft', 'marginRight']],
  ['marginY', ['marginTop', 'marginBottom']],
  ['paddingX', ['paddingLeft', 'paddingRight']],
  ['paddingY', ['paddingTop', 'paddingBottom']]
]);

export const stylePropTypes = {};

stylePropTypes.display = {
  display: PropTypes.oneOf([
    'inline',
    'block',
    'contents',
    'flex',
    'grid',
    'inline-block',
    'inline-flex',
    'inline-grid',
    'inline-table',
    'list-item',
    'run-in',
    'table',
    'table-caption',
    'table-column-group',
    'table-header-group',
    'table-footer-group',
    'table-row-group',
    'table-cell',
    'table-column',
    'table-row',
    'none',
    'initial',
    'inherit'
  ])
};

stylePropTypes.boxSizing = {
  boxSizing: PropTypes.oneOf([
    'content-box',
    'border-box',
    'inherit',
    'initial',
    'unset'
  ])
};

stylePropTypes.flex = {
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  flexDirection: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse'
  ]),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around'
  ])
};

stylePropTypes.grid = {
  columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grid: PropTypes.string,
  gridArea: PropTypes.string,
  gridAutoColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridAutoFlow: PropTypes.string,
  gridAutoRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplate: PropTypes.string,
  gridTemplateAreas: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

stylePropTypes.dimensions = {
  width: PropTypes.number,
  minWidth: PropTypes.number,
  height: PropTypes.number,
  minHeight: PropTypes.number
};

stylePropTypes.background = {
  background: PropTypes.string,
  backgroundBlendMode: PropTypes.string,
  backgroundClip: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundOrigin: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  backgroundSize: PropTypes.string
};

stylePropTypes.layout = {
  ...stylePropTypes.boxSizing,
  ...stylePropTypes.display,
  ...stylePropTypes.dimensions,
  ...spacing.propTypes,
  ...stylePropTypes.grid,
  ...stylePropTypes.flex,
  ...stylePropTypes.background
};

/**
|--------------------------------------------------
| Box
|--------------------------------------------------
*/

function styleResolver(params) {
  const styleKeys = Object.keys(params);
  const style = {};

  // Use a fast loop.
  // eslint-disable-next-line
  for (let i = 0; i < styleKeys.length; i++) {
    const key = styleKeys[i];
    const value = params[key];
    if (aliasResolverMap.has(key)) {
      aliasResolverMap.get(key).forEach(aliasKey => {
        style[aliasKey] = value;
      });
    } else {
      style[key] = value;
    }
  }

  return style;
}

function pickAndOmit(obj, keysToPick) {
  const picked = {};
  const omitted = {};

  // eslint-disable-next-line
  for (const key of Object.keys(obj)) {
    // eslint-disable-next-line
    if (keysToPick.hasOwnProperty(key)) {
      picked[key] = obj[key];
    } else {
      omitted[key] = obj[key];
    }
  }

  return [picked, omitted];
}

// eslint-disable-next-line
export function splitStyleFromProps(
  props,
  styleKeysToResolve = stylePropTypes.layout
) {
  const [pickedStyleParams, omittedProps] = pickAndOmit(
    props,
    styleKeysToResolve
  );

  return {
    ...omittedProps,
    // We are overriding style so we don't have to explicitly remove it from omittedProps.
    style: styleResolver({ ...pickedStyleParams, ...omittedProps.style })
  };
}

function Box(props) {
  const {
    is: Component,
    innerRef,
    children,
    style,
    ...restOfProps
  } = splitStyleFromProps(props);

  return (
    <Component ref={innerRef} style={style} {...restOfProps}>
      {children}
    </Component>
  );
}

Box.stylePropTypes = {
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  children: PropTypes.node,
  ...stylePropTypes.layout,
  ...spacing.propTypes
};

Box.defaultProps = {
  is: 'div',
  boxSizing: 'border-box'
};

export default React.forwardRef((props, ref) => (
  <Box innerRef={ref} {...props} />
));
