import {
  Empty as DSEmpty,
  Skeleton as DSSkeleton,
  Text as DSText
} from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { CardRuntime } from '../components/CardRuntime';
import CardsLoading from '../components/CardsLoading';
import DetailsDeployment from '../components/DetailsDeployment';
import DetailsPromote from '../components/DetailsPromote';
import Header from '../components/Header';
import ModalRollback from '../components/ModalRollback';
import SideBar from '../components/SideBar';
import SideBarLoading from '../components/SideBarLoading';
import SideSheetDeployPipeline from '../components/SideSheetDeployPipeline';
import * as Elements from '../Runtime.elements';

import i18n from '~/common/helpers/i18n';
import theme from '~/common/styled/theme';
import Box from '~/components/Box';
import InfiniteScroll from '~/components/InfiniteScroll';
import SmallSelect from '~/components/SmallSelect';

const handleClickWithoutBubbling =
  (cb = () => {}) =>
  ev => {
    ev.stopPropagation();
    cb();
  };

const Runtime = ({
  environment,
  remove,
  activeRealm,
  showBetaCreateDeploy,
  changeObjSearch,
  loading,
  deploymentDetails,
  onShowModalDeployment,
  environments,
  loadingCreate,
  redeploy,
  goToPipeline,
  changeEnvironment,
  changePulling,
  projectById,
  projectAllIds,
  current,
  fetchProjectLoading,
  triggers,
  editPipelinePath,
  logsPath,
  containScopes,
  license,
  canvasVersion,
  deploymentSelected,
  openDetailPromote,
  isOpenDetailPromote,
  closeDetailPromotion,
  create,
  openModalRollbackDeploy,
  closeModalRollback,
  isOpenRollback,
  isDeleteRollback,
  rollbackDeploy,
  loadingRollback,
  newDeploymentsPage,
  onInfinityScroll,
  setDefaultCurrentPage,
  changeUrl
}) => {
  const [searchParams] = useSearchParams();

  const createObjectDeployment = () => deploymentDetails?.deployment;

  const canCreate = !containScopes([
    'DEPLOYMENT:CREATE',
    `DEPLOYMENT:CREATE{ENV=${environment?.toUpperCase()}}`
  ]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const deployments = newDeploymentsPage?.content?.map(deployment => {
    const isStatusError = deployment.status === 'SERVICE_ERROR';

    const statusFormated =
      deployment.status.substring(0, 1) +
      deployment.status.substring(1).toLowerCase();
    return {
      ...deployment,
      status: {
        text: isStatusError ? 'Error' : statusFormated
      }
    };
  });

  const groupsProjects = deployments?.reduce((grupos, item) => {
    const { project } = item;
    const novoGrupos = grupos;
    if (!novoGrupos[project]) {
      novoGrupos[project] = [];
    }
    novoGrupos[project].push(item);
    return novoGrupos;
  }, {});

  const gruposItem = Object.fromEntries(Object.entries(groupsProjects || []));

  return (
    <>
      <SideSheetDeployPipeline
        realm={activeRealm}
        environment={environment}
        triggers={triggers}
        loading={loadingCreate}
        onCancel={() => showBetaCreateDeploy(false)}
        editPipelinePath={editPipelinePath}
        find={changeObjSearch}
      />

      <DetailsDeployment
        logsPath={logsPath}
        autoHeight
        redeploy={redeploy}
        triggers={triggers}
        title={`${i18n.t('scenes.runtime.labels.details_deployment')} -
          ${capitalizeFirstLetter(environment)}`}
        visible={deploymentDetails.visible}
        onCancel={() => onShowModalDeployment({ modalVisible: false, id: '' })}
        deployment={createObjectDeployment(deploymentDetails?.id)}
        editPipelinePath={editPipelinePath}
        canvasVersion={canvasVersion}
      />
      {isOpenDetailPromote && (
        <DetailsPromote
          close={closeDetailPromotion}
          enviroments={environments.allNames}
          deploymentSelected={deploymentSelected}
          realm={activeRealm}
          triggers={triggers}
          editPipelinePath={editPipelinePath}
          find={changeObjSearch}
          create={create}
          changeEnvironment={changeEnvironment}
          remove={remove}
        />
      )}
      {isOpenRollback && (
        <ModalRollback
          closeModalRollback={closeModalRollback}
          deployment={deploymentSelected}
          isDeleteRollback={isDeleteRollback}
          rollbackDeploy={rollbackDeploy}
          loadingRollback={loadingRollback}
        />
      )}
      <Elements.Content>
        <Elements.Container>
          <Elements.Top>
            {iff(environments?.allNames?.length > 0, () => (
              <SmallSelect
                options={environments?.allNames?.map(env => ({
                  label: env,
                  value: env
                }))}
                data-testid='run-environments'
                value={environment}
                onChange={env => changeEnvironment(env)}
                align='left'
              />
            ))}
          </Elements.Top>
          <Elements.ContentSideBar>
            {iff(fetchProjectLoading, () => (
              <SideBarLoading items={15} />
            ))}
            {iff(!fetchProjectLoading, () => (
              <SideBar
                current={current}
                byId={projectById}
                allIds={['ALL', ...projectAllIds]}
                onClick={projectId => {
                  changeUrl({
                    projectId
                  });
                  setDefaultCurrentPage();
                }}
                getAll={changeObjSearch}
              />
            ))}
            <Elements.Body id='scrollable-content'>
              <Header
                status={[]}
                onChange={variant => {
                  changeUrl({
                    pipelineName: variant.search.name,
                    status: variant.search.status
                  });
                }}
              />
              {iff(fetchProjectLoading, () => (
                <div style={{ maxWidth: '30%' }}>
                  <DSSkeleton
                    variant='rectangular'
                    sizes='medium'
                    style={{ width: '100%' }}
                  />
                </div>
              ))}
              {iff(!fetchProjectLoading, () => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box>
                    <DSText data-testid='run-project-title'>
                      {projectById[current]?.name}
                    </DSText>
                  </Box>
                  {searchParams.get('refresh') !== 'OFF' && (
                    <Box display='flex'>
                      <Box width='8px'>
                        {loading && <Elements.Loading icon={faLoader} />}
                      </Box>

                      <DSText
                        data-testid='run-time-text-pulling'
                        css={{ fontSize: '$1', marginLeft: '$3' }}
                      >
                        {i18n.t('label.refreshing_interval_chip', {
                          numberHere: searchParams.get('refresh')
                        })}
                      </DSText>
                    </Box>
                  )}

                  <Box>
                    <SmallSelect
                      iconName='SyncAlt'
                      data-testid='run-time-pulling'
                      zIndexValue={10}
                      options={[
                        {
                          label: `5 ${i18n.t('label.seconds_lowcase')}`,
                          value: 5
                        },
                        {
                          label: `10  ${i18n.t('label.seconds_lowcase')}`,
                          value: 10
                        },
                        {
                          label: `30  ${i18n.t('label.seconds_lowcase')}`,
                          value: 30
                        },
                        {
                          label: 'OFF',
                          value: 'OFF'
                        }
                      ]}
                      align='left'
                      value={searchParams.get('refresh')}
                      placeholder={i18n.t('label.auto_refresh_interval')}
                      onChange={value => {
                        changePulling(value);
                      }}
                    />
                  </Box>
                </Box>
              ))}
              {choose(
                when(fetchProjectLoading, () => <CardsLoading items={15} />),
                when(newDeploymentsPage?.totalElements === 0, () => (
                  <DSEmpty
                    illustration={theme.images.emptyStateList}
                    title={i18n.t('scenes.runtime.guidance.empty_list_prompt')}
                    subTitle={
                      canCreate &&
                      i18n.t('scenes.runtime.guidance.empty_list_msg')
                    }
                    buttonText={
                      canCreate &&
                      i18n.t('scenes.runtime.actions.create_new_deploy')
                    }
                    buttonClick={() => showBetaCreateDeploy(true)}
                  />
                )),

                otherwise(() => (
                  <InfiniteScroll
                    scrollableTarget='scrollable-content'
                    dataLength={newDeploymentsPage?.size}
                    pageStart={0}
                    style={{ overflow: 'hidden' }}
                    hasMore={!newDeploymentsPage?.last}
                    next={onInfinityScroll}
                    data-testid='run-pipeline-list'
                  >
                    {Object.keys(gruposItem).map(project => (
                      <Elements.ContainerProjectName key={project}>
                        {current === null && (
                          <DSText
                            css={{
                              fontSize: '$2',
                              fontWeight: '$bold'
                            }}
                          >
                            {project}
                          </DSText>
                        )}

                        <ul>
                          <Elements.Grid items={gruposItem[project].length}>
                            {gruposItem[project].map(deployment => (
                              <Elements.GridCard key={deployment.id}>
                                <CardRuntime
                                  onClick={() =>
                                    handleClickWithoutBubbling(
                                      onShowModalDeployment({
                                        visible: true,
                                        id: deployment.id,
                                        deployment
                                      })
                                    )
                                  }
                                  triggers={triggers}
                                  goToPipeline={goToPipeline}
                                  remove={remove}
                                  environment={environment}
                                  realm={activeRealm}
                                  deployment={deployment}
                                  licenseModel={license}
                                  redeploy={redeploy}
                                  openDetailPromote={openDetailPromote}
                                  currentProject={current}
                                  openModalRollbackDeploy={
                                    openModalRollbackDeploy
                                  }
                                />
                              </Elements.GridCard>
                            ))}
                          </Elements.Grid>
                        </ul>
                      </Elements.ContainerProjectName>
                    ))}
                  </InfiniteScroll>
                ))
              )}
            </Elements.Body>
          </Elements.ContentSideBar>
        </Elements.Container>
      </Elements.Content>
    </>
  );
};

Runtime.defaultProps = {
  showBetaCreateDeploy: () => {},
  onShowModalDeployment: () => {},
  goToPipeline: () => {},
  loadingCreate: false,
  environment: '',
  activeRealm: null,
  environments: null,
  deploymentDetails: null,
  projectById: null,
  projectAllIds: [],
  current: null,
  fetchProjectLoading: false,
  editPipelinePath: '',
  triggers: null,
  logsPath: ''
};

Runtime.propTypes = {
  loadingCreate: PropTypes.bool,
  environment: PropTypes.string,
  activeRealm: PropTypes.objectOf({}),
  environments: PropTypes.objectOf({}),
  loading: PropTypes.bool.isRequired,
  changeObjSearch: PropTypes.func.isRequired,
  changePulling: PropTypes.func.isRequired,
  redeploy: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onShowModalDeployment: PropTypes.func,
  deploymentDetails: PropTypes.objectOf({}),
  changeEnvironment: PropTypes.func.isRequired,
  goToPipeline: PropTypes.string,
  projectById: PropTypes.objectOf({}),
  projectAllIds: PropTypes.arrayOf([PropTypes.string]),
  current: PropTypes.string,
  fetchProjectLoading: PropTypes.bool,
  editPipelinePath: PropTypes.string,
  triggers: PropTypes.arrayOf([]),
  logsPath: PropTypes.string,
  showBetaCreateDeploy: PropTypes.func,
  containScopes: PropTypes.func.isRequired,
  license: PropTypes.objectOf({}).isRequired,
  canvasVersion: PropTypes.number.isRequired,
  deploymentSelected: PropTypes.objectOf({}).isRequired,
  newDeploymentsPage: PropTypes.objectOf({}).isRequired,
  openDetailPromote: PropTypes.func.isRequired,
  isOpenDetailPromote: PropTypes.bool.isRequired,
  closeDetailPromotion: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  openModalRollbackDeploy: PropTypes.func.isRequired,
  closeModalRollback: PropTypes.func.isRequired,
  isOpenRollback: PropTypes.bool.isRequired,
  isDeleteRollback: PropTypes.objectOf({}).isRequired,
  rollbackDeploy: PropTypes.func.isRequired,
  loadingRollback: PropTypes.bool.isRequired,
  setDefaultCurrentPage: PropTypes.func.isRequired,
  onInfinityScroll: PropTypes.func.isRequired,
  changeUrl: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf([PropTypes.string])
};

export default Runtime;
