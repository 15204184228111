import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

type UseIsAutoscalingProps = {
  triggerType?: string;
  shouldUseTriggerType?: boolean;
};

export const useIsAutoscaling = ({
  triggerType,
  shouldUseTriggerType = true
}: UseIsAutoscalingProps) => {
  const { RUN_PIPELINE_AUTOSCALING_BY_TRIGGER } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_PIPELINE_AUTOSCALING_BY_TRIGGER]);

  const isAutoScalingExperienceFlagEnabled =
    treatments[RUN_PIPELINE_AUTOSCALING_BY_TRIGGER].treatment === 'on';

  if (!shouldUseTriggerType) {
    return isAutoScalingExperienceFlagEnabled;
  }

  const isTriggerListedAsInactive = JSON.parse(
    treatments[RUN_PIPELINE_AUTOSCALING_BY_TRIGGER].config || '{}'
  )?.inactiveTriggers?.includes(triggerType);

  return isAutoScalingExperienceFlagEnabled && !isTriggerListedAsInactive;
};
