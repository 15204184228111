/* eslint-disable react/jsx-no-undef */
import { IconButton, Icon } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import PropTypes from 'prop-types';
import React from 'react';

import * as Elements from './Actions.elements';

import i18n from '~/common/helpers/i18n';
import Account from '~/components/Account';
import Badge from '~/components/Badge';
import Text from '~/components/Text';
import Tooltip from '~/components/Tooltip';

const Actions = ({
  unreadCount,
  onToggle,
  setModal,
  eula,
  openEula,
  showEnableSupportAccess,
  openSupportAccess,
  disabledSupportAccess,
}) => (
  <Elements.Container>
    <Elements.HelpMenu
      left='-175px'
      width={200}
      component={({ toggle }) => (
        <Elements.Wrapper>
          {choose(
            when(eula && !eula?.acceptanceDate, () => (
              <Tooltip
                position='bottom'
                align='left'
                content={i18n.t('scenes.eula.labels.license_term_alert')}
              >
                <IconButton
                  onClick={toggle}
                  size='medium'
                  data-testid='button-actions'
                >
                  {iff(
                    unreadCount > 0 || (eula && !eula?.acceptanceDate),
                    () => (
                      <Elements.Notification />
                    )
                  )}
                  <Icon size='md' variant='secondary' icon='question-circle' />
                </IconButton>
              </Tooltip>
            )),
            otherwise(() => (
              <IconButton
                onClick={toggle}
                size='medium'
                data-testid='button-actions'
              >
                {iff(
                  unreadCount > 0 || (eula && !eula?.acceptanceDate),
                  () => (
                    <Elements.Notification />
                  )
                )}
                <Icon size='md' variant='secondary' icon='question-circle' />
              </IconButton>
            ))
          )}
        </Elements.Wrapper>
      )}
    >
      <Elements.List inset noHairlines>
        <Elements.Item
          data-testid='help-menu-link-intercom-chat'
          onClick={() => onToggle()}
        >
          <Elements.Flex>
            <Elements.Box>
              <Elements.IconCustom name='CommentAltLines' faType='solid' />
              <Text>{i18n.t('scenes.beta.actions.open_chat')}</Text>
            </Elements.Box>
            <Elements.Box>
              {iff(unreadCount > 0, () => (
                <Badge type='info'>
                  {unreadCount}{' '}
                  {unreadCount === 1
                    ? i18n.t('label.message')
                    : i18n.t('label.messages')}
                </Badge>
              ))}
            </Elements.Box>
          </Elements.Flex>
        </Elements.Item>
        <Elements.Link
          data-testid='help-menu-link-intercom-help'
          href={i18n.t('scenes.beta.actions.help_center_url')}
          target='_blank'
        >
          <Elements.Item>
            <Elements.IconCustom name='QuestionCircle' faType='solid' />
            <Text align='left'>
              {i18n.t('scenes.beta.actions.open_help_center')}
            </Text>
          </Elements.Item>
        </Elements.Link>

        <Elements.Link
          data-testid='help-menu-link-intercom-academy'
          href={i18n.t('action.digibee_academy_link_url')}
          target='_blank'
        >
          <Elements.Item>
            <Elements.IconCustom name='GraduationCap' faType='solid' />
            <Text align='left'>{i18n.t('label.digibee_academy_link')}</Text>
          </Elements.Item>
        </Elements.Link>

        <Elements.Link
          href={i18n.t('action.documentation_url')}
          data-testid='help-menu-link-intercom-documentation'
          target='_blank'
        >
          <Elements.Item>
            <Elements.IconCustom name='Book' faType='solid' />
            <Text align='left'>{i18n.t('action.documentation')}</Text>
          </Elements.Item>
        </Elements.Link>

        <Elements.Link
          data-testid='help-menu-link-ai-assistant-academy'
          href={i18n.t('action.ai_assistant_url')}
          target='_blank'
        >
          <Elements.Item>
            <Elements.IconCustom name='MicrochipAi' faType='solid' />
            <Text align='left'>{i18n.t('action.ai_assistant')}</Text>
            <Badge style={{ marginLeft: '10px' }}>Beta</Badge>
          </Elements.Item>
        </Elements.Link>

        <Elements.Link
          data-testid='help-menu-link-intercom-release-notes'
          href={i18n.t('scenes.beta.actions.news_url')}
          target='_blank'
        >
          <Elements.Item>
            <Elements.IconCustom name='Gift' faType='solid' />
            <Text>{i18n.t('scenes.beta.actions.open_news')}</Text>
          </Elements.Item>
        </Elements.Link>
        <Elements.Item
          data-testid='help-menu-link-intercom-open-send-feedback'
          onClick={() => setModal({ path: 'sendFeedback', value: true })}
        >
          <Elements.IconCustom name='CommentAltEdit' faType='solid' />
          <Text>{i18n.t('scenes.beta.actions.send_feedback')}</Text>
        </Elements.Item>
        {showEnableSupportAccess &&
          <Elements.Item
            data-testid='help-menu-link-open-support-access'
            onClick={() => openSupportAccess()}
          >
            <Elements.IconCustom name='Headset' faType='solid' />
            <Text>{!disabledSupportAccess ?
              i18n.t('action.support_access_on') :
              i18n.t('action.support_access_off')}
            </Text>
          </Elements.Item>
        }
        {iff(eula, () => (
          <Elements.Item
            data-testid='help-menu-link-intercom-open-eula1'
            onClick={openEula}
          >
            <Elements.Flex>
              <Elements.Box>
                <Elements.IconCustom name='FileSignature' faType='solid' />
                <Text>{i18n.t('scenes.eula.labels.license_term')}</Text>
              </Elements.Box>
              <Elements.Box>
                {iff(
                  !eula?.acceptanceDate && eula?.lastPostponementDate,
                  () => (
                    <Badge type='danger'>{eula?.countDown}</Badge>
                  )
                )}
              </Elements.Box>
            </Elements.Flex>
          </Elements.Item>
        ))}
      </Elements.List>
    </Elements.HelpMenu>
    <Account />
  </Elements.Container>
);

Actions.propTypes = {
  unreadCount: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  eula: PropTypes.objectOf({}).isRequired,
  openEula: PropTypes.func.isRequired,
  showEnableSupportAccess: PropTypes.func.isRequired,
  openSupportAccess: PropTypes.func.isRequired,
  disabledSupportAccess: PropTypes.bool.isRequired,
};

export default Actions;
