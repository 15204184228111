import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';
import { useContext, useEffect } from 'react';
import { SplitAttributesContext } from '~/scenes/Others/scenes/App/components/SplitProvider';
import WebAnalytics from '~/common/helpers/webAnalytics';

const useFeatureFlag = (
  names: string[],
  attributes?: SplitIO.Attributes | undefined,
  key?: SplitIO.SplitKey | undefined,
  eventDisabled: boolean = false
) => {
  const splitContext = useContext(SplitContext);
  const splitAttributesContext = useContext(SplitAttributesContext);
  const splitKey = key || splitAttributesContext.key.toString();

  const treatments = useTreatments(
    names,
    {
      ...splitAttributesContext,
      ...attributes
    },
    splitKey
  );
  const sendToFullStory = (name: string) => {
    const treatment = treatments[name];

    if (treatment) {
      return WebAnalytics.sendEvent('[Global] (Feature Flag) Evaluation', {
        feature: name,
        keyName: splitKey,
        treatment: treatment.treatment,
        time: new Date().getTime(),
        treatmentConfig: treatment.config,
        attributes: {
          ...splitAttributesContext,
          ...attributes
        }
      });
    }
  };

  useEffect(() => {
    if (!eventDisabled && splitContext.isReady) {
      names.forEach(sendToFullStory);
    }
  }, [eventDisabled, splitContext.isReady]);

  return {
    ...splitContext,
    treatments
  };
};

export default useFeatureFlag;
