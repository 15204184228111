import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import withHooks from '~/common/hoc/withHooks';
import withIntercom from '~/common/hoc/withIntercom';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

const enhancer = compose(
  withIntercom,
  withHooks(props => {
    const { acceptanceTerm, authentication, application, intercom } =
      useSelector(state => state);
    const dispatch = useDispatch();
    const { GOVERNANCE_ENABLE_SUPPORT_ACCESS} = featureFlagConstants;
    const { treatments } = useFeatureFlag([
      GOVERNANCE_ENABLE_SUPPORT_ACCESS
    ]);
    const showEnableSupportAccess = treatments[GOVERNANCE_ENABLE_SUPPORT_ACCESS].treatment === 'on';

    const state = {
      activeRealm: application?.realm,
      email: authentication.userData ? authentication.userData.email : null,
      user_id: authentication.userData ? authentication.userData.email : null,
      user_hash: intercom.key,
      hide_default_launcher: true,
      namer: authentication.userData
        ? `${authentication.userData.realm} - ${authentication.userData.email}`
        : null,
      realm: authentication.userData ? authentication.userData.realm : null,
      eula: acceptanceTerm?.result,
      showEnableSupportAccess,
      disabledSupportAccess: application.disabledSupportAccess?.status
    };
    const actions = {
      setModal: dispatch.feedback.setModal,
      setModalTerm: dispatch.acceptanceTerm.setModal,
      goToDefaultLayout: () => {
        dispatch.router.navigate({
          to: `/${state.activeRealm.realm}/pipelines`
        });
      },
      openSupportAccess: () => {
        dispatch.application.handleIsOpenSuportAccess()
      },
      openFallBackChat: () => {
        dispatch.application.handleIsOpenFallBackChat()
      },
      openEula: () => {
        dispatch.eula.setModal(true);
      }
    };

    return {
      ...props,
      ...state,
      ...actions,
      data: acceptanceTerm?.result
    };
  })
);

export default enhancer;
