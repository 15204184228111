import { head } from 'lodash';
import { FC } from 'react';

import * as Elements from './Header.elements';
import Select from '../../../components/SelectRealm';
import Action from '../../../Operation/components/Header/components/Action';

import i18n from '~/common/helpers/i18n';
import useContainPaths from '~/common/hooks/useContainPaths';
import paths from '~/common/jsons/paths.json';
import { useHeaderContext } from '~/components/HeaderProvider';
import Role from '~/components/Role';
import { SubHeader, SubHeaderLink } from '~/components/SubHeader';
import Text from '~/components/Text';
import Actions from '~/scenes/Others/scenes/App/components/Actions';

interface HeaderProps {
  shadow?: boolean;
  setModalTwoFactor: () => void;
  setModalNewPassword: () => void;
  onSignOut: () => void;
  activeRealm: any;
  allIds: string[];
  byId: any;
  user: { email: string };
  hasCustomerIdentityProvider: string;
  license: any;
}

const Header: FC<HeaderProps> = ({
  shadow,
  setModalTwoFactor,
  setModalNewPassword,
  onSignOut,
  user,
  activeRealm,
  allIds,
  byId,
  hasCustomerIdentityProvider,
  license
}) => {
  const { configActionButton } = useHeaderContext();
  const containPaths = useContainPaths();
  const button = head(configActionButton);

  return (
    <Elements.Container>
      <Elements.Top>
        <Elements.Left>
          <Select activeRealm={activeRealm.realm} allIds={allIds} byId={byId} />
        </Elements.Left>
        <Elements.Center>
          {/* @ts-ignore */}
          <Elements.Heading weight='regular' size='xs'>
            {/* @ts-ignore */}
            {button?.text || button?.renderText?.()}
          </Elements.Heading>
        </Elements.Center>
        <Elements.Right>
          <Actions
            email={user?.email}
            setModalTwoFactor={setModalTwoFactor}
            setModalNewPassword={setModalNewPassword}
            onSignOut={onSignOut}
          />
        </Elements.Right>
      </Elements.Top>
      {/* @ts-ignore */}
      <SubHeader shadow={shadow} isNotBorder>
        {containPaths([`${activeRealm.realm}/settings/groups`]) && (
          <>
            {/* @ts-ignore */}
            <SubHeaderLink to={`/${activeRealm.realm}/settings/groups/list`}>
              <Text as='span'>{i18n.t('label.groups_list')}</Text>
            </SubHeaderLink>
            {hasCustomerIdentityProvider === 'true' && (
              <Role name='SAML-GROUP-MAPPING:READ'>
                {/* @ts-ignore */}
                <Elements.SubHeaderLinkWithBetaTag
                  to={`/${activeRealm.realm}/settings/groups/integrations`}
                  active={containPaths([
                    `${activeRealm.realm}/settings/groups/integrations`
                  ])}
                >
                  <Text as='span'>{i18n.t('label.groups_integration')}</Text>
                </Elements.SubHeaderLinkWithBetaTag>
              </Role>
            )}
          </>
        )}
        {containPaths([`${activeRealm.realm}/settings/identity-provider`]) && (
          <>
            <Role name='SSO-CONFIGURATION:READ'>
              {/* @ts-ignore */}
              <Elements.SubHeaderLinkWithBetaTag
                to={`/${activeRealm.realm}/settings/identity-provider/idp-settings`}
                active={containPaths([
                  `${activeRealm.realm}/settings/identity-provider/idp-settings`
                ])}
              >
                <Text as='span'>{i18n.t('action.idp_settings')}</Text>
              </Elements.SubHeaderLinkWithBetaTag>
            </Role>
            <Role name='SSO-CONFIGURATION:READ'>
              {/* @ts-ignore */}
              <Elements.SubHeaderLinkWithBetaTag
                to={`/${activeRealm.realm}/settings/identity-provider/authentication-rules`}
                active={containPaths([
                  `${activeRealm.realm}/settings/identity-provider/authentication-rules`
                ])}
              >
                <Text as='span'>{i18n.t('action.authentication_rules')}</Text>
              </Elements.SubHeaderLinkWithBetaTag>
            </Role>
          </>
        )}
        {containPaths([`${activeRealm.realm}/settings/capsules/headers`]) && (
          // @ts-ignore
          <SubHeaderLink
            disabled={containPaths(['PIPELINE:READ'])}
            to={`/${activeRealm.realm}/settings/capsules/headers`}
            active={containPaths([
              `${activeRealm.realm}/settings/capsules/headers`
            ])}
          >
            <Text as='span'>
              {i18n.t('scenes.beta.labels.upper_and_capitalize.capitalized', {
                item: `${i18n.t('noun.header_list_title')}`
              })}
            </Text>
          </SubHeaderLink>
        )}
        {containPaths([`${activeRealm.realm}/settings/ctl/token`]) && (
          // @ts-ignore
          <SubHeaderLink
            to='ctl/token'
            active={containPaths([`${activeRealm.realm}/settings/ctl/token`])}
          >
            <Text as='span'>Token</Text>
          </SubHeaderLink>
        )}
        {containPaths([
          `${activeRealm.realm}/settings/accounts`,
          `${activeRealm.realm}/settings/oauthProviders`
        ]) && (
          <>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='accounts'
              active={containPaths([`${activeRealm.realm}/settings/accounts`])}
            >
              <Text as='span'>{i18n.t('label.account_list')}</Text>
            </SubHeaderLink>
            {/* @ts-ignore */}
            <Elements.SubHeaderLinkWithBetaTag
              to='oauthProviders'
              active={containPaths([
                `${activeRealm.realm}/settings/oauthProviders`
              ])}
            >
              <Text as='span'>{i18n.t('label.oauth_providers')}</Text>
            </Elements.SubHeaderLinkWithBetaTag>
          </>
        )}
        {containPaths([`${activeRealm.realm}/settings/licensing`]) && (
          // @ts-ignore
          <SubHeaderLink
            to='licensing'
            active={containPaths([`${activeRealm.realm}/settings/licensing`])}
          >
            <Text
              as='span'
              data-testid='licensing-subheader-settings-plan-name'
            >
              {i18n.t(
                `label.${license?.planName?.toLowerCase().replace(/ /g, '_')}`
              )}
            </Text>
          </SubHeaderLink>
        )}
        {containPaths([`${activeRealm.realm}${paths.policies}`]) && (
          <>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='policies/security'
              active={containPaths([
                `${activeRealm.realm}${paths.policies}/security`
              ])}
            >
              <Text
                as='span'
                data-testid='governance-subheader-settings-policies-security'
              >
                {i18n.t('action.security')}
              </Text>
            </SubHeaderLink>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='policies/transformation'
              active={containPaths([
                `${activeRealm.realm}${paths.policies}/transformation`
              ])}
            >
              <Text
                as='span'
                data-testid='governance-subheader-settings-policies-transformation'
              >
                {i18n.t('action.transformation')}
              </Text>
            </SubHeaderLink>
          </>
        )}
        {containPaths([`${activeRealm.realm}/settings/limits`]) && (
          <>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='limits/global'
              active={containPaths([
                `${activeRealm.realm}/settings/limits/global`
              ])}
            >
              <Text
                active={containPaths([
                  `${activeRealm.realm}/settings/limits/global`
                ])}
                as='span'
                data-testid='licensing-subheader-settings-limits-global'
              >
                {i18n.t('label.global_capacity')}
              </Text>
            </SubHeaderLink>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='limits/local'
              active={containPaths([
                `${activeRealm.realm}/settings/limits/local`
              ])}
            >
              <Text
                active={containPaths([
                  `${activeRealm.realm}/settings/limits/local`
                ])}
                as='span'
                data-testid='licensing-subheader-settings-limits-local'
              >
                {i18n.t('label.local_capacity')}
              </Text>
            </SubHeaderLink>
          </>
        )}
        {/* @ts-ignore */}
        <Role name={button?.role}>
          {/* @ts-ignore */}
          {button?.action && (
            <Elements.Action>
              {/* @ts-ignore */}
              <Elements.Fab
                onClick={() =>
                  // @ts-ignore
                  button?.action({ visible: true })
                }
                emphasis='contained'
                size='lg'
                type='primary'
                icon='Plus'
                rounded
                extended
                // @ts-ignore
                data-testid={button?.['data-testid']}
              >
                {i18n.t('scenes.beta.actions.create')}
              </Elements.Fab>
            </Elements.Action>
          )}
        </Role>
        {containPaths([`${activeRealm.realm}/settings/alert`]) && (
          <>
            {/* @ts-ignore */}
            <SubHeaderLink
              to='alert'
              active={containPaths([`${activeRealm.realm}/settings/alert`])}
            >
              <Text as='span'>{i18n.t('label.alerts_settings')}</Text>
            </SubHeaderLink>
            {/* @ts-ignore */}
            <Role name={button?.role}>
              {/* @ts-ignores */}
              {button?.action && (
                <Elements.Action>
                  <Action configActionButton={configActionButton} />
                </Elements.Action>
              )}
            </Role>
          </>
        )}
        {containPaths([`${activeRealm.realm}/settings/credentials`]) && (
          // @ts-ignore
          <Role name={button?.role}>
            {/* @ts-ignore */}
            {button?.action && (
              <Elements.Action>
                {/* @ts-ignore */}
                <Elements.Fab
                  onClick={() =>
                    // @ts-ignore
                    button?.action({ visible: true })
                  }
                  emphasis='contained'
                  size='lg'
                  type='primary'
                  icon='Plus'
                  rounded
                  extended
                  // @ts-ignore
                  data-testid={button?.['data-testid']}
                >
                  {i18n.t('scenes.beta.actions.create')}
                </Elements.Fab>
              </Elements.Action>
            )}
          </Role>
        )}
      </SubHeader>
    </Elements.Container>
  );
};

export default Header;
