import React from 'react';
import styled from 'styled-components';
import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';

// Locals
import Nav from '~/components/SideNav';
import Skeleton from '~/components/Skeleton';

import { Skeleton as DSSkeleton } from '@digibee/beehive-ui';

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.typography.fontFamily};
  padding-left: 8px;
  margin-top: 32px;
  z-index: 1;
  height: calc(100vh - 200px);
  overflow: auto;
  width: 208px;
`;

const Project = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin: 8px 0px;
  cursor: pointer;
`;

const NavStyled = styled(Nav)`
  padding-top: 52px;
`;

type Props = {
  items?: number[];
};

const SideBarLoading: React.FC<Props> = ({ items: itemsProps }) => {
  const { RUN_LIST_DESIGNSYSTEM } = featureFlagConstants;
  const { treatments } = useFeatureFlag([RUN_LIST_DESIGNSYSTEM]);
  const showDesignSystemComponents =
    treatments[RUN_LIST_DESIGNSYSTEM].treatment === 'on';

  const items = Array(itemsProps)
    .fill(undefined)
    .map((val, idx) => idx);

  const propsSkeleton: any = {
    variant: 'rect',
    height: '32px',
    width: '100%'
  };

  return (
    <NavStyled>
      <Container>
        {showDesignSystemComponents ? (
          <>
            {items.map(item => (
              <Project key={item}>
                <DSSkeleton
                  variant='rectangular'
                  sizes='medium'
                  style={{ width: '100%' }}
                />
              </Project>
            ))}
          </>
        ) : (
          <>
            {items.map(item => (
              <Project key={item}>
                <Skeleton {...propsSkeleton} />
              </Project>
            ))}
          </>
        )}
      </Container>
    </NavStyled>
  );
};

export default SideBarLoading;
